<template>
  <!-- Bootstrap, though loaded first, was overriding some unknown style element, hard to solve out, which was sending the login page to top right. Rather than waste a long time trying to find out which, I am only applying Bootstrap to pages which use it -->
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css" integrity="sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH" crossorigin="anonymous">
  <!-- <div
    class="modal-overlay-canscroll"
    style="position: absolute; top: 10px; width: 100%"
  > -->
  <!-- not sure why but the height: 100% allows modal to center in the page otherwise it goes to top -->
  <div
    class="container text-center tugradient1"
    style="padding-top: 5vh; padding-bottom: 5vh"
  >
    <table
      width="100%"
      border="0"
      style="padding-left: 2.5vh; padding-right: 2.5vh"
    >
      <tr>
        <td>
          <table width="100%" border="0" s>
            <tr>
              <td width="30%">
                <span
                  class="primary-general-text divl"
                  style="display: block; line-height: 15px"
                  >&nbsp;</span
                >
              </td>
              <td width="40%">
                <img
                  class="tulogo"
                  style="height: 6vh; padding-bottom: 1vh"
                  alt="ThankU logo"
                  src="../assets/tutxtlogomd.webp"
                />
              </td>
              <td width="30%">
                <div class="divr">
                  <button @click="doGoBack" class="text-only-button cancel">
                    <img
                      class="tulogo"
                      style="height: 4vh"
                      alt="close"
                      src="../assets/greycross.png"
                    />
                  </button>
                </div>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
    <!-- <div class="row">
      <div class="float-center">Contact the ThankU Team</div>
      <div class="float-center"></div>
    </div> -->
    <!-- <div class="row">
      <span class="col-4 offset-3 turegularfontsize text-start">Customer experience</span>
      <span class="col-4 turegularfontsize text-start">
        <a href="mailto:hello@thanku.app">hello@thanku.app</a>
      </span>
    </div>
    <div class="row">
      <span class="col-4 offset-3 turegularfontsize text-start">Support</span>
      <span class="col-4 turegularfontsize text-start">
        <a href="mailto:hello@thanku.app">support@thanku.app</a>
      </span>
    </div> -->
    <div class="row">
      <div class="float-center">
        <table class="float-center" border="0" style="margin: auto">
          <tr>
            <td style="padding-top: 2vh; padding-bottom: 2vh">
              <span class="lt-blue" style="font-size: 14px">
                <p
                  class="MsoNormal"
                  style="margin-bottom: 0.0001pt; line-height: normal"
                >
                  <b
                    ><span
                      style="
                        font-size: 13.5pt;
                        font-family: 'Arial', sans-serif;
                        color: rgb(94, 144, 227);
                      "
                      >How to contact the ThankU team:</span
                    ></b
                  >
                </p>
                <br />

                <p
                  class="MsoNormal"
                  style="margin-bottom: 0.0001pt; line-height: normal"
                >
                  <b
                    ><span
                      style="
                        font-size: 12pt;
                        font-family: 'Arial', sans-serif;
                        color: rgb(94, 144, 227);
                      "
                    ></span
                  ></b>
                </p>

                <table
                  class="MsoTableGrid"
                  width="100%"
                  style="border: medium none; border-collapse: collapse"
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                >
                  <tbody>
                    <tr style="">
                      <td
                        class="float-right"
                        style="padding: 0.2vw; width: 134.45pt"
                        valign="top"
                        width="179"
                      >
                        <p
                          class="MsoNormal"
                          style="margin-bottom: 0.0001pt; line-height: normal"
                        >
                          <span
                            style="
                              font-size: 10pt;
                              font-family: 'Tahoma', sans-serif;
                              color: rgb(11, 83, 148);
                            "
                            >Customer experience</span
                          >
                        </p>
                      </td>
                      <td
                        class="float-left"
                        style="padding: 0.2vw; width: 177.15pt"
                        valign="top"
                        width="236"
                      >
                        <p
                          class="MsoNormal"
                          style="margin-bottom: 0.0001pt; line-height: normal"
                        >
                          <span
                            style="
                              font-size: 10pt;
                              font-family: 'Tahoma', sans-serif;
                              color: rgb(11, 83, 148);
                            "
                            ><a href="mailto:hello@thanku.app"
                              >hello@thanku.app</a
                            >
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr style="">
                      <td
                        style="padding: 0.2vw; width: 134.45pt"
                        valign="top"
                        width="179"
                      >
                        <p
                          class="MsoNormal"
                          style="margin-bottom: 0.0001pt; line-height: normal"
                        >
                          <span
                            style="
                              font-size: 10pt;
                              font-family: 'Tahoma', sans-serif;
                              color: rgb(11, 83, 148);
                            "
                            >Customer Support</span
                          >
                        </p>
                      </td>
                      <td
                        style="padding: 0.2vw; width: 177.15pt"
                        valign="top"
                        width="236"
                      >
                        <p
                          class="MsoNormal"
                          style="margin-bottom: 0.0001pt; line-height: normal"
                        >
                          <span
                            style="
                              font-size: 10pt;
                              font-family: 'Tahoma', sans-serif;
                              color: rgb(11, 83, 148);
                            "
                            ><a href="mailto:support@thanku.app"
                              >support@thanku.app</a
                            >
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr style="">
                      <td
                        style="padding: 0.2vw; width: 134.45pt"
                        valign="top"
                        width="179"
                      >
                        <p
                          class="MsoNormal"
                          style="margin-bottom: 0.0001pt; line-height: normal"
                        >
                          <span
                            style="
                              font-size: 10pt;
                              font-family: 'Tahoma', sans-serif;
                              color: rgb(11, 83, 148);
                            "
                            >Technical support</span
                          >
                        </p>
                      </td>
                      <td
                        style="padding: 0.2vw; width: 177.15pt"
                        valign="top"
                        width="236"
                      >
                        <p
                          class="MsoNormal"
                          style="margin-bottom: 0.0001pt; line-height: normal"
                        >
                          <span
                            style="
                              font-size: 10pt;
                              font-family: 'Tahoma', sans-serif;
                              color: rgb(11, 83, 148);
                            "
                            ><a href="mailto:tech@thanku.app"
                              >tech@thanku.app</a
                            ></span
                          >
                        </p>
                      </td>
                    </tr>
                    <tr style="">
                      <td
                        style="padding: 0.2vw; width: 134.45pt"
                        valign="top"
                        width="179"
                      >
                        <p
                          class="MsoNormal"
                          style="margin-bottom: 0.0001pt; line-height: normal"
                        >
                          <span
                            style="
                              font-size: 10pt;
                              font-family: 'Tahoma', sans-serif;
                              color: rgb(11, 83, 148);
                            "
                            >Payments</span
                          >
                        </p>
                      </td>
                      <td
                        style="padding: 0.2vw; width: 177.15pt"
                        valign="top"
                        width="236"
                      >
                        <p
                          class="MsoNormal"
                          style="margin-bottom: 0.0001pt; line-height: normal"
                        >
                          <span
                            style="
                              font-size: 10pt;
                              font-family: 'Tahoma', sans-serif;
                              color: rgb(11, 83, 148);
                            "
                            ><a href="mailto:payments@thanku.app"
                              >payments@thanku.app</a
                            ></span
                          >
                        </p>
                      </td>
                    </tr>
                    <tr style="">
                      <td
                        style="padding: 0.2vw; width: 134.45pt"
                        valign="top"
                        width="179"
                      >
                        <p
                          class="MsoNormal"
                          style="margin-bottom: 0.0001pt; line-height: normal"
                        >
                          <span
                            style="
                              font-size: 10pt;
                              font-family: 'Tahoma', sans-serif;
                              color: rgb(11, 83, 148);
                            "
                            >Press</span
                          >
                        </p>
                      </td>
                      <td
                        style="padding: 0.2vw; width: 177.15pt"
                        valign="top"
                        width="236"
                      >
                        <p
                          class="MsoNormal"
                          style="margin-bottom: 0.0001pt; line-height: normal"
                        >
                          <span
                            style="
                              font-size: 10pt;
                              font-family: 'Tahoma', sans-serif;
                              color: rgb(11, 83, 148);
                            "
                            ><a href="mailto:press@thanku.app"
                              >press@thanku.app</a
                            >
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr style="">
                      <td
                        style="padding: 0.2vw; width: 134.45pt"
                        valign="top"
                        width="179"
                      >
                        <p
                          class="MsoNormal"
                          style="margin-bottom: 0.0001pt; line-height: normal"
                        >
                          <span
                            style="
                              font-size: 10pt;
                              font-family: 'Tahoma', sans-serif;
                              color: rgb(11, 83, 148);
                            "
                            >Telephone</span
                          >
                        </p>
                      </td>
                      <td
                        style="padding: 0.2vw; width: 177.15pt"
                        valign="top"
                        width="236"
                      >
                        <p
                          class="MsoNormal"
                          style="margin-bottom: 0.0001pt; line-height: normal"
                        >
                          <span
                            style="
                              font-size: 10pt;
                              font-family: 'Tahoma', sans-serif;
                              color: rgb(11, 83, 148);
                            "
                            >+442078560378</span
                          >
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <b>Please don't hesitate to call us!</b>
                <br />
                <br />
                <div style="padding-right: 1vw">
                  <button
                    class="tuhomepagesignupbutton"
                    style="width: 50%"
                    @click="this.doShowGetSetUp"
                  >
                    Get set up for free!
                  </button>
                </div>
                <br />
                <p
                  style="
                    margin: 0.2vw 0.2vw 0.0001pt;
                    text-align: center;
                    line-height: 15pt;
                    -moz-background-clip: -moz-initial;
                    -moz-background-origin: -moz-initial;
                    -moz-background-inline-policy: -moz-initial;
                    vertical-align: baseline;
                  "
                  align="center"
                >
                  <span class="unsubscribe--sendercity"
                    ><span
                      style="
                        border: 1pt none windowtext;
                        padding: 0.2vw;
                        font-size: 9pt;
                        font-family: Poppins;
                        color: black;
                      "
                      >thankU<sup>®</sup> by Appsanely Ltd.</span
                    ></span
                  ><span
                    ><span
                      style="
                        border: 1pt none windowtext;
                        padding: 0.2vw;
                        font-size: 9pt;
                        font-family: Poppins;
                      "
                    ></span
                  ></span>
                </p>

                <p
                  style="
                    margin: 0.2vw 0.2vw 0.0001pt;
                    text-align: center;
                    line-height: 15pt;
                    -moz-background-clip: -moz-initial;
                    -moz-background-origin: -moz-initial;
                    -moz-background-inline-policy: -moz-initial;
                    vertical-align: baseline;
                  "
                  align="center"
                >
                  <span
                    ><span
                      style="
                        border: 1pt none windowtext;
                        padding: 0.2vw;
                        font-size: 9pt;
                        font-family: Poppins;
                        color: rgb(68, 68, 68);
                      "
                      ><a href="http://www.thanku.app/">www.thanku.app</a>
                    </span></span
                  ><span
                    style="
                      font-size: 9pt;
                      font-family: Poppins;
                      color: rgb(68, 68, 68);
                    "
                    >&nbsp;&nbsp;+442078560378</span
                  >
                </p>
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <div class="actionbtn-div" style="padding-top: 1vh">
                <button
                  class="tuhomepagebutton"
                  style="width: 20%; height: 4vh"
                  @click="this.doGoBack()"
                >
                  Ok
                </button>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import shared from "../shared.js";
export default {
  data () {
    return {
      fromScreen: undefined,
    };
  },
  props: {
    showHomePageFromGoogleAd: Boolean,
  },
  methods: {
    doGoBack(){
      console.log("this.fromScreen: " + this.fromScreen);
      if(this.fromScreen !== undefined) {
        let returnJSON = { [this.fromScreen]: true };
        console.log(JSON.stringify(returnJSON, null, 2));
        this.$emit("return-json", returnJSON);
      } else  {
        let returnJSON = { showHomeNew: true };
        this.$emit("return-json", returnJSON);
      }
    },
    // doShowHome() {
    //   // /console.log("we're off!");

    //   let returnJSON = { showHomeNew: true };
    //   this.$emit("return-json", returnJSON);
    // },
    doShowGetSetUp() {
      console.log("doShowGetSetUp");
      let returnJSON = { showGetSetUp: true };
      this.$emit("return-json", returnJSON);
    },
    doSetMetas() {
      document.title =
        "Contact ThankU Cashless Tipping - based in the Royal Borough of Kensington and Chelsea, London";
      document
        .getElementsByTagName("meta")
        .namedItem("description")
        .setAttribute(
          "content",
          "Contact ThankU Cashless Tipping - based in the Royal Borough of Kensington and Chelsea, we are essentially open all waking hours in the UK timezone. If you are wondering what the quality of our support is like, just ask and we will refer you to one of our top London clients for a reference."
        );
    },
  },
  created() {
    document.body.style.maxWidth = "100vw"; // for Google mobile crawler had to limit homepage width on body tag, this resets it
    this.doSetMetas();
    shared.saveToUserPath(
      this.devEnv,
      "Show contact info " + new Date().toISOString(),
      this.globalPushForwardInterval
    );
    // console.log("showHomePageFromGoogleAd ON CONTACT::: " + this.showHomePageFromGoogleAd);
  },
  mounted(){
    this.fromScreen = (this.$route.params !== undefined && this.$route.params.fromScreen !== undefined) ? this.$route.params.fromScreen : undefined; // so showHomePageFromGoogleAd is not passed as a prop, it is passed through this.$route.params AND NOTE: though this.showHomePageFromGoogleAd is passed as a boolean from Homecontent passing it through the router converts the value to a string
  },
};
</script>

