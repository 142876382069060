<template>
  <!-- See: `getFullVh()` -->
  <div style="overflow: hidden; height: 0">
      <div id="measure-vh" style="position: fixed; height: 100vh"></div>
  </div>
  <!-- showPopUpOk --><transition name="fade">
    <div v-show="showPopUpOk" class="modal-overlayfrombottomblur" style="z-index: 999;">
      <div class="modal" style="padding-top: 3vh; padding-bottom: 3vh; padding-top: 3vh; padding-bottom: 3vh; min-height: 40vh; width: 80%" > 
        <!-- put in table for dev speed need to learn more about flex -->
        <table border=0 width=80%> 
          <tr>
            <td>
              <div class="lt-blue txtc" :style="{ 'font-size': this.fontSizeTitle + 'vh', 'line-height': this.fontSizeTitleLineHeight + 'vh', }">                 
              {{ this.popUpMsgTitle }}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="lt-blue txtc" :style="{ 'font-size': this.fontSizePopUpMsg + 'vh', 'line-height': this.fontSizePopUpMsgLineHeight + 'vh', 'padding-top': '1vh', }">                 
              {{ this.popUpMsgBody }}
              </div>
            </td>
          </tr>
          <tr v-show="this.showButton1===true">
            <td style="height: 10vh">
              <div><button class="widebtn" style="width: 80%; height: 6vh; " @click="this.doPopUpOption1()"> {{ this.button1Text }} </button></div>
            </td>
          </tr>
          <tr v-show="this.showButton2===true">
            <td>
              <div><button class="widebtn" style="width: 80%; height: 6vh; " @click="this.doPopUpOption2()">{{ this.button2Text }}</button></div>
            </td>
          </tr>
          <tr><!-- spacer --> &nbsp;<td><div></div></td>
          </tr>
        </table>       
      </div>
    </div>
  </transition>
   <!-- showPopUpFiveOptions --><transition name="fade">
    <div  v-show="this.showPopUpFiveOptions===true" class="modal-overlayfrombottomblur" style="z-index: 997;">
      <div class="modal" style="padding-top: 2vh; padding-bottom: 2vh; width: 90%"> 
        <!-- put in table for dev speed need to learn more about flex -->
        <table border=0 width=85%> 
          <tr>
            <td>
              <table width="100%">
                <td width="25%">
                  &nbsp;
                </td>
                <td width="50%">
                  <div class="lt-blue txtc" :style="{ 'font-size': fontSizeNormalInPXToUse * 1.1 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">                 
                  {{ this.PopUpFiveOptionsTitle }}
                  </div>
                </td>
                <td width="25%">
                  <div class=divr>
                      <button @click="showPopUpFiveOptions = false;" class="text-only-button cancel"> <img class="tulogo" style="height: 4vh;" alt="close" src="../assets/greycross.png"></button>
                  </div>
                </td>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <div class="lt-blue txtc" style="padding-top: 1vh" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.9 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">                 
              {{ this.PopUpFiveOptionsMessage }}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div style="padding-top: 2vh; padding-bottom: 2vh;"><button class="widebtn" style="width: 80%; height: 5vh; " :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }" @click="this.showPopUpFiveOptions = false; this.doOption1();">{{ this.option1ButtonText }}</button></div>
            </td>
          </tr>
          <tr>
            <td>
              <div style="padding-bottom: 2vh;"><button class="widebtn" style="width: 80%; height: 5vh; " :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }" @click="this.doOption2();">{{ this.option2ButtonText }}</button></div>
            </td>
          </tr>
          <tr>
            <td>
              <div style="padding-bottom: 2vh;"><button class="widebtn" style="width: 80%; height: 5vh; " :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }" @click="this.doOption3();">{{ this.option3ButtonText }}</button></div>
            </td>
          </tr>
          <tr>
            <td>
              <div style="padding-bottom: 2vh;"><button class="widebtn" style="width: 80%; height: 5vh; " :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }" @click="this.doOption4();">{{ this.option4ButtonText }}</button></div>
            </td>
          </tr>
          <tr>
            <td>
              <div style="padding-bottom: 2vh;"><button class="widebtn" style="width: 80%; height: 5vh; " :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }" @click="this.doOption5();">{{ this.option5ButtonText }}</button></div>
            </td>
          </tr>
          <tr> <!-- spacer --> <td><div></div></td>
          </tr>
        </table>       
      </div>
    </div>
    </transition>
    <!-- showMakePayout -->
    <div id="topdiv" class="modal-overlay" style="width: 100%; ">
        <div class="modal" style="outline:0.0em solid blue; display: table-cell; padding-top: 0vh; padding-bottom: 0vh; " :style="{ 'height': 98 * this.vhMult + 'vh'}"> 

        <table width=100% border=0 style="padding-left: 2.5vh; padding-right: 2.5vh; ">
            <tr>
                <td>
                    <table width="100%" border=0 style="height: 6vh">
                        <tr>
                            <td width="30%">
                                <div class=divl><button class="text-only-button cancel" @click="this.goBack()" style="display: block;" :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }"><img class="tulogo" valign=middle style="height: 1.8vh; padding-bottom: 0.3vh" alt="back" src="../assets/chevron.png"> Back</button></div>
                            </td>
                            <td width="40%" ><span style="display: block;" :style="{ 'font-size': fontSizeNormalInPXToUse * 1.2 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse * 1.2 + 'px', }">Make Payout</span>
                            </td>
                            <td width="30%">
                                <div class=divr v-if="this.showExportButton === true">
                                
                                    <button @click="this.doShowExport()" class="text-only-button cancel divr"  :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">Export</button>
                                </div>
                            </td>

                        </tr>
                    </table>
                    
                </td>
            </tr>
            <!-- <div class="modal" style="outline:0.0em solid blue; display: table-cell; padding-top: 0vh; padding-bottom: 0vh; background-color:#EDF2F7; box-shadow: 0 0px 0px rgba(0, 0, 0, 0.0);" :style="{ 'height': 98 * this.vhMult + 'vh'}">  -->
            
            <tr>
              <td>
                  <div v-if="this.userData.stripeBalancesObject.availableNowBalanceNumber === 0" style="height: 5vh">&nbsp;</div>
                  <div class="wrapper-todiv" style="display: flex;">      
                    <h1 class="primarytextmid" style="margin: auto; display: block" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.9 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse * 0.9 + 'px', }">You have <span :style="{ 'font-size': fontSizeNormalInPXToUse * 1 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse * 1 + 'px', }"><b>{{ this.userData.stripeBalancesObject.availableNowBalance }}</b></span> available <span v-if="this.userData.stripeBalancesObject.availableNowBalanceNumber !== 0">- how much do you want to pay out to your bank account?</span></h1>
                  </div> 
                  <div v-if="this.userData.stripeBalancesObject.availableNowBalanceNumber === 0" style="height: 5vh">&nbsp;</div>
                  <div v-if="this.userData.stripeBalancesObject.availableNowBalanceNumber !== 0" class="wrapper-todiv" style="padding-top: 2px; padding-bottom: 4px; outline:0.0em solid black">
                    <!-- outline:0.1em solid black -->
                      <span class="currencySymbolOtherAmount" style="">{{ this.buttonCurrSymbol }}</span><input ref="payout-amount-input" v-on:keyup="this.updateMakePayoutButton" class="lt-blue other-amount-input" type="text" value=0 :style="{ 'width': this.inputAmountWidth + 'px' }">
                  </div>
                  <div v-if="this.userData.stripeBalancesObject.availableNowBalanceNumber !== 0" class="wrapper-buttonsdiv">
                    <div class="tipwidebuttonframe">
                        <table style="height:100%;" border=0 width=97%>
                          <tbody>
                              <tr>
                                <td>
                                  <button class="widebtn" ref="button5" @click="this.doConfirmPayout('5', $event);">Pay out <span class="currencySymbol">{{ this.buttonCurrSymbol }}</span>{{ this.makePayoutAmount }}
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                        </table>
                    </div>
                  </div>
                
              </td>
            </tr> 
             
            <div v-if="this.userData.stripeBalancesObject.allPendingPaymentsNotAvailableBalanceNumber !== 0" class="wrapper-todiv" style="display: flex;">      
                    <h1 class="primarytextmid" style="margin: auto; display: block;" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.75 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse * 0.75 + 'px', }">You will have a further <span :style="{ 'font-size': fontSizeNormalInPXToUse * 0.85 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse * 0.85 + 'px', }"><b>{{ this.showProgress }}{{ this.userData.stripeBalancesObject.mostRecentPaymentsNotYetInTransit }}</b></span> available soon - for further details please see below</h1>
                  </div> 
            <tr>
                <td >
                    <div style="overflow:auto;" :style="{ 'height': 45 * this.vhMult + 'vh'}">
                        <div :key="payment.objectId" v-for="payment in paymentsArray" >
                            <BalanceRecord :paymentObjectId=payment.objectId  :paymentOwner=payment.paymentOwner :paymentOperator=payment.paymentOperator :paymentAmountReceived=payment.paymentAmountReceived :paymentDate=payment.date :paymentClearingDate=payment.clearingDate :paymentBalance=payment.balance :paymentBalanceType=payment.balanceType :paymentCurrencySymbol=payment.currencySymbol :paymentInitialisationDate=payment.initialisationDate :paymentPayoutBasis=payment.payoutBasis @paymentSelected="paymentSelected"/>
                        </div>   
                    </div>              
                </td> 
            </tr>
        </table>       
      </div>
    </div>
</template>

<script>
  
const Parse = require('parse/node');

import BalanceRecord from './BalanceRecord.vue'
import shared from '../shared.js';

export default {
    name: 'MakePayoutScreen',
    inject: ['globalPushForwardInterval', 'devEnv', 'appId', 'appJSKey'] ,
    props:  {
        userData: shared.userDataStruct(), 
    },
    components: {
        BalanceRecord,   
    },
    data () { 
        return {
            fontSizeNormal: 2.5, // vh 
            fontSizeNormalInPXToUse: "",
            fontSizeNormalLineHeight: 3.3,// vh 
            fontSizeNormalInPXLineHeightToUse: "",
            userObjectId: undefined,
            paymentsArray: [
              { 
                objectId: "1",
                paymentOwner: undefined,
              },
              {
                objectId: "2", 
                paymentOwner: undefined,
              },
              {
                objectId: "3",
                paymentOwner: undefined,
              },
              {
                objectId: "4",
                paymentOwner: undefined,
              },
              {
                objectId: "5",
                paymentOwner: undefined,
              },
               {
                objectId: "6",
                paymentOwner: undefined,
              },
               {
                objectId: "7",
                paymentOwner: undefined,
              },
               {
                objectId: "8",
                paymentOwner: undefined,
              },
               {
                objectId: "9",
                paymentOwner: undefined,
              },
               {
                objectId: "10",
                paymentOwner: undefined,
              },
               {
                objectId: "11",
                paymentOwner: undefined,
              },
               {
                objectId: "12",
                paymentOwner: undefined,
              },
               {
                objectId: "13",
                paymentOwner: undefined,
              },
               {
                objectId: "14",
                paymentOwner: undefined,
              },
               {
                objectId: "15",
                paymentOwner: undefined,
              },
               {
                objectId: "16",
                paymentOwner: undefined,
              },
               {
                objectId: "17",
                paymentOwner: undefined,
              },
            ],
            showPopUpFiveOptions: false,
            PopUpFiveOptionsTitle: "Reports",
            PopUpFiveOptionsMessage: "Select a report for ThankU to email you from the choices below",  
            option1ButtonText: "Export current month",
            option2ButtonText: "Export last month",
            option3ButtonText: "Export all time",
            option4ButtonText: "Export this tax year",
            option5ButtonText: "Export last tax year",
            showPopUpOk: false,
            popUpMsgTitle: "",
            popUpMsgBody: "",
            button1Text: "ok",
            button2Text: "",
            showButton1: true,
            showButton2: false,
            showExportButton: false,
            fontSizeTitle: 2.7, // vh
            fontSizeNormal: 2.4, // vh, not currently used
            fontSizeTitleLineHeight: 5, //vh
            fontSizePopUpMsg: 2.3, // vh
            fontSizePopUpMsgLineHeight: 3.4,// vh
            tipperOrRecipientReport: "tipper",
            vhMult: 1,
            buttonCurrSymbol: "£", 
            inputAmountWidth: 24,
            lastInputAmountValue: 0,
            makePayoutAmount: 0,
            enteredSomethingValidToOtherAmount: false,
            localeDecimalPlacesAllowed: 2,
            
        }
    },
    methods: {
        updateMakePayoutAmountButtonString(){
          console.log("this.$refs['payout-amount-input'].value " + this.$refs['payout-amount-input'].value);
          let boxValue = this.$refs['payout-amount-input'].value;
          console.log("this.$refs['payout-amount-input'].value " + this.$refs['payout-amount-input'].value);
          let numChars = boxValue.length;
          console.log("numChars: " + numChars);
          this.inputAmountWidth=(24 * numChars); // 24 is estimated width of a character
          this.makePayoutAmount = (+boxValue).toFixed(this.localeDecimalPlacesAllowed);
          // /console.log("setting this.makePayoutAmount" + this.makePayoutAmount);

        },
        doConfirmPayout(){

          if (this.makePayoutAmount < 1){        
            this.popUpMsgTitle = "Sorry";
            this.popUpMsgBody = "The minimum payout amount you can choose is " + this.buttonCurrSymbol + "1.";
            this.button1Text = "Ok";
            this.showPopUpFiveOptions = false;
            this.showButton2 = false;
            this.showPopUpOk = true;
          } else {

            let currentHour = new Date().getHours();

            let payoutDays = "2";

            if (currentHour >= 18){
                payoutDays = "3"
            } 



            this.popUpMsgTitle = "Confirm payout";
            this.popUpMsgBody = "Confirm ThankU should send " + this.buttonCurrSymbol + this.makePayoutAmount + " to your bank account ending " + this.userData.receiveLast4 + "? This payment will arrive at your bank in " + payoutDays + " business days after today";
            this.button1Text = "Confirm";
            this.button2Text = "Cancel";
            this.showPopUpFiveOptions = false;
            this.showButton2 = true;
            this.showPopUpOk = true;
          }
        },
        async doPayout(){

          try {
            let payoutObject = await Parse.Cloud.run("doPayout", {
                userObjectId: this.userData.userObjectId, 
                amountInCents: this.$refs['payout-amount-input'].value * 100,
            });

            // console.log("payoutObject returned: " + JSON.stringify(payoutObject, null, 2));

            let arrivalDate = new Date(payoutObject.arrival_date * 1000);
            let options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
            this.deviceFullObject = shared.getDeviceFullObject();
            let locale = this.deviceFullObject.locale;
        
            // alert("arrivalDate: " + arrivalDate.toLocaleDateString(locale, options));

            let payoutAmountReturned = (payoutObject.amount / 100).toFixed(2);

            this.popUpMsgTitle = "Payout confirmed!";
            this.popUpMsgBody = "Your payout of " + this.buttonCurrSymbol + payoutAmountReturned + " will arrive in your bank account ending " + this.userData.receiveLast4 + " on " + arrivalDate.toLocaleDateString(locale, options);
            this.button1Text = "Ok";
            this.showPopUpFiveOptions = false;
            this.showButton2 = false;
            this.showPopUpOk = true;

            let oldAvailableNowBalanceNumber = this.userData.stripeBalancesObject.availableNowBalanceNumber;
            this.userData.stripeBalancesObject.availableNowBalanceNumber -= (payoutObject.amount) / 100;
            this.userData.stripeBalancesObject.availableNowBalance = this.userData.stripeBalancesObject.availableNowBalance.replace("" + oldAvailableNowBalanceNumber, "" + this.userData.stripeBalancesObject.availableNowBalanceNumber);
 
            this.initBalanceLedger();

            const paramsToSend = {
                userObjectId: this.userData.userObjectId,
                balance: this.buttonCurrSymbol + payoutAmountReturned,
                payoutDate: arrivalDate.toLocaleDateString(locale, options),
                manual: true,
              };



            Parse.Cloud.run("sendEmail", paramsToSend);

            

    
          } catch (e) {
            this.popUpMsgTitle = "Sorry";    
            
            if (e.code === 141){
              this.popUpMsgBody = "We were unable to create your payout as the amount you requested exceeds your available balance.";
            } else {
              this.popUpMsgBody = "We were unable to create your payout, reason:  " + e.code;
            }
            this.button1Text = "Ok";
            this.showButton2 = false;
            this.showPopUpOk = true;
            this.$refs['payout-amount-input'].blur();
          }

        },
        updateMakePayoutButton(event){

          // console.log("updateMakePayoutButton event happened");

          // console.log("this.$refs['payout-amount-input'].value original: " + this.$refs['payout-amount-input'].value);
          try {
            if (
              (((!isFinite(event.key) && event.key !== '.' ) ||  
              (this.$refs['payout-amount-input'].value.split(".").length - 1) > 1) && // there must only be one
              // event.key !== '.' && actually you know what, noone ever tried to tip 50p, fuck it 
              event.keyCode !== 8 && //Backspace
              event.keyCode !== 46) // Delete
              || event.key === ' ' 
              // || event.key === '.' 
              || event.key.toLowerCase() === 'e' // I truly don't know why 'e' is let through, maybe exponential'
              ) {

                console.log("key error: " + event.key);

              this.$refs['payout-amount-input'].value = ''; // ok so weird thing: if you input a decimal point, it will remain even though it isn't in the character count and doesn't register as part of the value. This line clears it and then setting the old value from the pay button before it's updated works fine
              if (this.makePayoutAmount === ''){
                this.makePayoutAmount = 0;
              }
              this.$refs['payout-amount-input'].value = this.makePayoutAmount; // i.e. it's existing value

              if (this.enteredSomethingInvalidToOtherAmount === false) {
                shared.saveToUserPath(this.devEnv,  "Recipient on make payout " + this.userData.userObjectId + " keyed INVALID input into make payout amount: " + event.keyCode + " " + new Date().toISOString(), this.globalPushForwardInterval);
                this.enteredSomethingInvalidToOtherAmount = true;
              }
              
            } else {
              // ok it's valid input

              let payoutAmountInput = this.$refs['payout-amount-input'].value;

              if (payoutAmountInput.indexOf(".") > -1 && payoutAmountInput.substr(payoutAmountInput.length -1) !== "."){
                // console.log("payoutAmountInput.substr(payoutAmountInput.length -1 - this.localeDecimalPlacesAllowed, 1):: " + payoutAmountInput.substr(payoutAmountInput.length -1 - this.localeDecimalPlacesAllowed, 1))

                if (event.keyCode === 8 || event.keyCode === 46){ //Backspace // Delete)
                  // let it through
                  // console.log("letting thru...");
                } else if (payoutAmountInput.length > (payoutAmountInput.indexOf(".") + this.localeDecimalPlacesAllowed + 1)){
                  // console.log("smashing back");
                  payoutAmountInput = (+this.makePayoutAmount).toFixed(this.localeDecimalPlacesAllowed); // i.e. it's existing value
                }
                
              }

              this.$refs['payout-amount-input'].value = payoutAmountInput;

              // console.log("it's valid 1");

                if (this.enteredSomethingValidToOtherAmount === false) {
                  // console.log("it's valid 2");
                  shared.saveToUserPath(this.devEnv,  "Tipper " + this.userData.userObjectId + " keyed valid input into other amount: " + event.keyCode + " " + new Date().toISOString(), this.globalPushForwardInterval);
                  this.enteredSomethingValidToOtherAmount = true;
                }
            }

            if (this.$refs['payout-amount-input'].value === '') {
              // we always need to have some value or the input box disappears
              this.$refs['payout-amount-input'].value = 0;
            } else if(
              this.$refs['payout-amount-input'].value.substr(0,1) === '0') {
              // if we had an artifical 0 we need to get rid of it when the user enters a correct value
              console.log("this is the problem");
              this.$refs['payout-amount-input'].value = this.$refs['payout-amount-input'].value.substr(1);
            // } else if (+(this.$refs['payout-amount-input'].value) > 200){ //TODO this needs to be the maximum balance available {{this.balanceAvailable}}
            //   this.$refs['payout-amount-input'].value = this.makePayoutAmount; // i.e. the amount before it was chnaged   
            //   this.popUpMsgTitle = "Sorry";         
            //   this.popUpMsgBody = "the maximum amount you can current pay out to your bank is your Balance Available of " + this.buttonCurrSymbol + this.balanceAvailable;
            //   this.showPopUpOk = true;
            //   this.$refs['payout-amount-input'].blur();
            //   // this.$refs['show-popup-ok'].focus();
            }
  
            this.updateMakePayoutAmountButtonString();
          } catch (e) {
            console.log("Error is: " + e.message);
          }
        },
 
        doFontSizeComputed () {
            this.fontSizeNormalInPXToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormal, 16);
            this.fontSizeNormalInPXLineHeightToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormalLineHeight, 20);
        },
        paymentSelected(paymentObjectId, paymentOwner, payoutDate, payoutBasis){ 

          if (paymentOwner === "Payout"){
            // console.log("payout object ID selected: " + paymentObjectId);
            // console.log("Payout basis: " + payoutBasis);
            
            if (payoutBasis === "manual"){
                this.popUpMsgTitle = "Info";
                this.popUpMsgBody = "As you manually paid this out, it doesn't correspond to a specific set of tips.";
                this.button1Text = "Ok";
                this.showPopUpFiveOptions = false;
                this.showButton2 = false;
                this.showPopUpOk = true;
                return;
                // do nothing can't break down
            } else {
                // console.log("Payout basis: " + payoutBasis);
            }
            this.userData.fromScreen = "showMakePayout";
            this.userData.selectedPayoutId = paymentObjectId;
            this.userData.nextScreen = "showPayoutBreakdown";
      
            this.$emit("next-action", this.userData);
          } else {
            // console.log("object ID selected: " + paymentObjectId);
            this.userData.fromScreen = "showMakePayout";
            let tipperOrRecipient;
            if (this.userData.isRecipient === true){
                this.userData.nextScreen = "showTipInfoRecipient";
                tipperOrRecipient = "Recipient";
            } else {
                this.userData.nextScreen = "showTipInfoTipper";
                tipperOrRecipient = "Tipper" 
            }
            this.userData.selectedPaymentObjectId = paymentObjectId;
            // Parse.Cloud.run("sendEmail", {
            //     toEmail: "appalert@thanku.app", 
            //     subject: "ALERT! " + tipperOrRecipient + " " + this.userData.displayname + " viewed tip info from " + this.userData.fromScreen,
            //     body: "",
            // });
            this.$emit("next-action", this.userData);
          }
        },
        goBack(){ 

            // console.log("this.userData.fromScreen: " + this.userData.fromScreen)
           
              if (this.userData.isRecipient === true){
                // console.log("DID THAT");
                if (this.userData.fromScreen !== "showMakePayout") {
                  this.userData.nextScreen = this.userData.fromScreen;
                } else {
                  this.userData.nextScreen = "showAccountScreen";
                }
              } else {
                // console.log("DID THE OTHER");
                  this.userData.nextScreen = "showAccountScreen";
              }
            this.$emit("next-action", this.userData);
        },
        async doShowExport(){
          
          try {

            this.popUpMsgTitle = "";  
            this.showButton1 = false;       
            this.popUpMsgBody = "preparing your report...";
            this.showPopUpFiveOptions = false;
            this.showPopUpOk = true;

            const payments = await Parse.Cloud.run("emailBalanceLedger", { userObjectId: this.userData.userObjectId, deviceSecondsFromGMT: shared.getDeviceFullObject().deviceSecondsFromGMT,});
            
            this.popUpMsgTitle = "Done!";         
            this.popUpMsgBody = "Your transactions balances report has been emailed to you.";
            this.showButton1 = true;


          }  catch (e) {
            this.popUpMsgTitle = "Sorry!";         
            this.popUpMsgBody = "There was an error trying to send your transactions balances report to you. Please email tech@thanku.app with the following message: " + e.message;
            this.showPopUpFiveOptions = false;
            this.showPopUpOk = true;
            
          }

        },  
            
        async initBalanceLedger() {
            try { 
                
                const payments = await Parse.Cloud.run("initBalanceLedger", { userObjectId: this.userData.userObjectId, deviceSecondsFromGMT: shared.getDeviceFullObject().deviceSecondsFromGMT,}); 
                for (const payment of this.paymentsArray) {
                  payment.paymentOwner = "stopping"; // this passes the new values to the showProgress routine
                }  
 
                // console.log("we stopped the progress indicator");
                
                this.$nextTick(() => {  
                  // the key is we CANNOT update the array before the above for has re-rendered otherwise we get into an endless loop
                  this.paymentsArray = payments; 

                  // this.userData.balanceAvailable = 0;
  
                  for (const payment of this.paymentsArray){
                    // console.log("balance is: " + payment.balance);

                    // payment.clearingDate = payment.dateInMillis + "";
                    // console.log("payment on balance ledger: " + JSON.stringify(payment, null, 2));
                    if (this.userData.balanceAvailable === "....." && payment.balanceType !== "pending"){
                      this.userData.balanceAvailable = payment.balance;
                      break;
                    }
                  }
  
                  this.showExportButton = true;
    
                });

                return;
            } catch (e) {
                console.error(e);
                throw e;
            } 
        },
        doPopUpOption1(){
          this.showPopUpOk = false;
          if (this.button1Text === "Confirm"){
            
            // console.log("doing payout");
            this.doPayout();
          
          } else {
            // console.log("no action");
            // exiting do
          }


        },
        doPopUpOption2(){
          this.showPopUpOk = false;
        },
        doOption1()
        {
            this.doOrderReport(1);
        },
        doOption2()
        {
            this.doOrderReport(2); 
        },
        doOption3()
        {
            this.doOrderReport(3); 
        },
        doOption4()
        {
            this.doOrderReport(4);
        },
        doOption5()
        {
            this.doOrderReport(5);
        },
        async doOrderReport(reportNum){
            
            // console.log("userObjectId: " + this.userData.userObjectId);

            try {
                // const result = await - don't it takes too long; we have an alert if nec on the backend 
                Parse.Cloud.run("generateTransactionReport", { userObjectId: this.userData.userObjectId, reportNum: reportNum, tipperOrRecipientReport: this.tipperOrRecipientReport});  
                
                this.popUpMsgTitle = "Done!";
                this.popUpMsgBody = "your report has been emailed to you";
                this.button1Text = "ok";
                this.button2Text = "";
                this.showButton2 = false;
                this.showPopUpOk = true;  
                return;
            } catch (e) {
                console.error(e);
                throw e;
            } 
            
        }, 
    },
    async created(){ 
        Parse.serverURL = 'https://parseapi.back4app.com/'; Parse.initialize(this.appId, this.appJSKey); (this.appId, this.appJSKey);
        this.userData.balanceAvailable = ".....";
        await this.initBalanceLedger();
        console.log("this.userData.stripeBalancesObject.availableNowBalanceNumber: " + this.userData.stripeBalancesObject.availableNowBalanceNumber);
        this.$refs['payout-amount-input'].value = this.userData.stripeBalancesObject.availableNowBalanceNumber.toFixed(this.localeDecimalPlacesAllowed);
        console.log("payout-amount-input: " + this.$refs['payout-amount-input'].value);
        this.updateMakePayoutAmountButtonString();
         
    },
    mounted(){
        this.doFontSizeComputed();
        this.vhMult = shared.setScreenHeightMultiplier();
        shared.saveToUserPath(this.devEnv, "Tip history " + new Date().toISOString(), this.globalPushForwardInterval);
        
        this.userData.futureBalanceAvailable = "NEED futureBalanceAvailable";

        // this.$refs['payout-amount-input'].value = this.userData.stripeBalancesObject.availableNowBalanceNumber;
 
        // let arrivalDate = new Date("1693785600" * 1000);
        // let options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
        // this.deviceFullObject = shared.getDeviceFullObject();
        // let locale = this.deviceFullObject.locale;
        
        // alert("arrivalDate: " + arrivalDate.toLocaleDateString(locale, options));

    }
}
</script>

<style scoped>


</style>