<template>
    <router-view @return-json="doReturnJSON" v-slot="{ Component }">
      <transition v-bind:name="chosenTransition">
          <component :is="Component" />
      </transition>
    </router-view>
</template>

<script>

// import shared from '../shared.js';

export default {
    inject: ['globalPushForwardInterval', 'devEnv', 'appId', 'appJSKey'] ,
    data(){
        return {
            chosenTransition: "",
            // redirect: "",
        }
    },
    props: {
        tuRedirect: String,
        redirectParam1: String,
        fromScreen: String,
        showHomePageFromGoogleAd: Boolean,
    },
    methods: {
        doReturnJSON(returnJSON){

            console.log("HomeContent JSON::: " + JSON.stringify(returnJSON));
 

            if (returnJSON.showContactNew){
                this.$router.push({name:'ContactNew', params: { fromScreen: returnJSON.fromScreen, }}); 
            } else if (returnJSON.showAboutNew){
                this.$router.push({name:'AboutNew', params: { fromScreen: returnJSON.fromScreen, }}); 
            } else if (returnJSON.showPrivacyNew){
                this.$router.push({name:'PrivacyNew', params: { fromScreen: returnJSON.fromScreen, }}); 
            } else if (returnJSON.showTermsNew){
                this.$router.push({name:'TermsNew', params: { fromScreen: returnJSON.fromScreen, }}); 
            } else if (returnJSON.showGetSetUp){
                this.$router.push({name:'Home', params: { doGetSetup: true, doGetSetupOrigin: "CONTACT", }}); 
            } else if (returnJSON.showMoreGiveInfoNew){
                this.doShowMoreGiveInfoNew(returnJSON.fromScreen);
            } else if (returnJSON.showMoreGetInfoNew){
                console.log("returnJSON.showMoreGetInfoNew: " + returnJSON.showMoreGetInfoNew);
                if(returnJSON.feesShow){
                    this.doShowMoreGetInfoFeesNew(returnJSON.fromScreen);
                } else {
                    this.doShowMoreGetInfoNew(returnJSON.fromScreen);
                }
            } else if (returnJSON.getTipsStartNew){
                this.doShowGetTipsStartNew(returnJSON.fromScreen);
            } else if (returnJSON.showHomeNew){  
                this.chosenTransition = "";       
                this.$router.push('/'); 
            } else {
                // organised like this because we don't want to change existing setup whilst we get new set up right in terms of scrolling and the scrollbar
                this.chosenTransition = "screenslideup";
                document.querySelector('body').style.overflowY = "hidden"; // this stops the vertical scrollbar whilst sliding up
                
                if (returnJSON.showHome !== undefined){  
                    this.chosenTransition = "";       
                    this.$router.push('/'); 
                } else if (returnJSON.selectedTUID){
                    this.doGoHomeWithTUID(returnJSON);
                } else if (returnJSON.nextScreen){
                    if (returnJSON.nextScreen === "showAccountScreen"){
                        this.doShowAccount(true);
                    }
                } else if (returnJSON.showAccount){
                    this.doShowAccount(undefined);
                } else if (returnJSON.showSalons){
                    this.doShowSalons();
                } else if (returnJSON.showGive){
                    this.doShowGive();
                } else if (returnJSON.showTipWithoutScanning){
                    // console.log("returnJSON.fromScreen:::::::::: " + returnJSON.fromScreen);
                    this.doShowTipWithoutScanning(returnJSON.fromScreen);
                } else if (returnJSON.showMoreGiveInfo){
                    this.doShowMoreGiveInfo();
                } else if (returnJSON.showGet){
                    this.doShowGet();
                } else if (returnJSON.showMoreGetInfo){
                    if(returnJSON.feesShow){
                        this.doShowMoreGetInfoFees();
                    } else {
                        this.doShowMoreGetInfo();
                    }
                    
                
                } else if (returnJSON.showGetTipsStart){
                    this.doShowGetTipsStart(returnJSON.fromScreen);
                } else if (returnJSON.showContact){
                    this.$router.push('contact'); 
                } else if (returnJSON.showAbout){
                    this.$router.push('about'); 
                } else if (returnJSON.showTerms){
                    this.$router.push('terms'); 
                } else if (returnJSON.showPrivacy){
                    this.$router.push('privacy'); 
                } else if (returnJSON.showTestimonials){
                    this.$router.push('testimonials'); 
                } else if (returnJSON.showUnsubscribe){
                    this.$router.push('unsubscribe'); 
                } 


                window.setTimeout(this.resetScroll, 500);  
                }

        },
        doGoHomeWithTUID(returnJSON){
            
            this.$router.push('/'); 

            let returnParentJSON = {
                selectedTUID: returnJSON.selectedTUID,
                showHomeScreen: false
            };

            this.$emit('return-parent-json', returnParentJSON);
        },
        doShowAccount(bypassDashboard){
            
            this.$router.push('/'); 

            let returnParentJSON = {
                showAccount: true,
                bypassDashboard: bypassDashboard,
                showHomeScreen: false
            };

            this.$emit('return-parent-json', returnParentJSON);
        },
        doShowSalons(){
            // console.log("let's go to a salon");
            this.$router.push('salons'); 
        },
        doShowGive(){
            this.$router.push('givetips'); 
        },
        doShowTipWithoutScanning(fromScreen){
            // console.log("Tipwithoutscanning fromScreen: " + fromScreen);

            this.$router.push({name:'TipWithoutScanning', params: { fromScreen: fromScreen }}); 
            // this.$router.push('tipwithoutscanning'); 
        },
        doShowMoreGiveInfoNew(fromScreen){
            console.log("doShowMoreGiveInfoNew: " + fromScreen);
            this.$router.push({name:'GiveTipsMoreInfoNew', params: { fromScreen: fromScreen }}); 
        },
        doShowMoreGiveInfo(){
            this.$router.push('givetipsmoreinfo'); 
        },
        doShowGet(){
            this.$router.push('gettips'); 
        },
        doNHBF(){
            window.localStorage.setItem("isnhbfmember", true); // couldnt be bothered to faff getting it through the router
            // /console.log("doing nhbf");
            // this.$router.push({name:'GetTipsStart', params: { fromScreen: "NHBF" }}); 
            this.$router.push({name:'GetTipsStartNew', params: { fromScreen: "NHBF" }}); 
        },
        doShowGetTipsStartNew(fromScreen){
            // console.log("fromScreen: " + fromScreen);

            this.$router.push({name:'GetTipsStartNew', params: { fromScreen: fromScreen }}); 
        },
        doShowGetTipsStart(fromScreen){
            // console.log("fromScreen: " + fromScreen);

            this.$router.push({name:'GetTipsStart', params: { fromScreen: fromScreen }}); 
        },
        doShowMoreGetInfoNew(fromScreen){
            console.log("pushing gettipsmoreinfonew");
            this.$router.push({name:'GetTipsMoreInfoNew', params: { fromScreen: fromScreen}}); 
        },
        doShowMoreGetInfo(){
            this.$router.push('gettipsmoreinfo'); 
        },
        doShowMoreGetInfoFeesNew(fromScreen){
            console.log("doing doShowMoreGetInfoFeesNew");
            this.$router.push({name:'GetTipsMoreInfoNew', params: { fromScreen: fromScreen, feesShow: true }}); 
        },
        doShowMoreGetInfoFees(fromScreen){
            this.$router.push({name:'GetTipsMoreInfo', params: { fromScreen: fromScreen, feesShow: true }}); 
        },
        doShowTestimonials(){
            this.$router.push('testimonials'); 
        },
        doShowUnsubscribe(){
            this.$router.push('unsubscribe'); 
        },
        resetScroll(){
            document.querySelector('body').style.overflowY = "auto";
        },
        doRedirects(){
            // console.log("TU REDIRECT::: " + this.tuRedirect);
            if (this.tuRedirect === "nhbf") {
                this.doNHBF();
            } else if (this.tuRedirect === "contact") {
                this.$router.push('contact'); 
            } else if (this.tuRedirect === "about") {
                this.$router.push('about'); 
            } else if (this.tuRedirect === "terms") {
                this.$router.push('terms'); 
            } else if (this.tuRedirect === "privacy") {
                this.$router.push('privacy'); 
            } else if (this.tuRedirect === "givetips") {
                this.$router.push('givetips'); 
            } else if (this.tuRedirect === "givetipsmoreinfo") {
                this.$router.push('givetipsmoreinfo'); 
            } else if (this.tuRedirect === "tipwithoutscanning") {
                if (this.redirectParam1 !== undefined){
                    this.$router.push({name:'TipWithoutScanning', params: { groupName: this.redirectParam1 }}); 
                } else {
                    this.$router.push('tipwithoutscanning'); 
                }

            } else if (this.tuRedirect === "gettips") {
                this.$router.push('gettips'); 
            } else if (this.tuRedirect === "gettipsstartnew") {
                // console.log("ON HOMECONTENT fromScreen: " + this.fromScreen);
                this.$router.push({name:'GetTipsStartNew', params: { fromScreen: this.fromScreen }}); 
            } else if (this.tuRedirect === "gettipsstart") {
                // console.log("ON HOMECONTENT fromScreen: " + this.fromScreen);
                this.$router.push({name:'GetTipsStart', params: { fromScreen: this.fromScreen }}); 
            } else if (this.tuRedirect === "gettipsmoreinfo") {
                this.$router.push('gettipsmoreinfo'); 
            } else if (this.tuRedirect === "testimonials") {
                this.$router.push('testimonials'); 
            } else if (this.tuRedirect === "salons") {
                this.$router.push('salons'); 
            } else if (this.tuRedirect === "unsubscribe") {
                this.$router.push('unsubscribe'); 
            } 
        },
        doLoadBootstrap(){
            let bootstrapScript = document.createElement('script')
            bootstrapScript.setAttribute('src', 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.bundle.min.js');
            bootstrapScript.setAttribute('integrity', 'sha384-YvpcrYf0tY3lHB60NNkmXc5s9fDVZLESaAA55NDzOxhy9GkcIdslK1eN7N6jIeHz');
            bootstrapScript.setAttribute('crossorigin', 'anonymous');
            document.head.appendChild(bootstrapScript)
        },
    },
    created(){
        // console.log("created HOMECONTENT");
        // console.log("showHomePageFromGoogleAd::: " + this.showHomePageFromGoogleAd);
        this.$router.push({name: 'Home', params: { showHomePageFromGoogleAd: this.showHomePageFromGoogleAd }});  //NOTE: though this.showHomePageFromGoogleAd is a boolean here passing it through the router converts the value to a string
    },
    mounted(){
        clearTimeout(window.localStorage.getItem("networkMsgBoxTimeoutId"));
        document.getElementById('networkMsgBox').style.display = 'none';
        console.log("networkMsgBox display cancelled");
        this.doLoadBootstrap();
        this.doRedirects();

    }
}
</script>