<template>
    <!-- showGetTipsMoreInfo -->
    <div id="topdiv" class="modal-overlay-canscroll" style="position: absolute; top: 10px; width: 100%; outline:0.0em solid pink; ">
        <div class="modal" style="outline:0.0em solid red; padding-top: 2vh; padding-bottom: 2vh; "> 

        <table width=100% border=0 style="outline:0.0em solid blue; padding-left: 2.5vh; padding-right: 2.5vh; ">
            <tr>
                <td>
                    <table width="100%" border=0 cellspacing=0 cellpadding=0>
                        <tr>
                            <td width="70%" style="padding-left: 1vh; padding-right: 1vh">
                                <div class="notesInfoText" style="" :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">
                                <b>Vicky Williams owns Head Office Creative Hair Studios in Wrexham. </b>
                                </div>
                            </td>
                            
                            <td width="30%">
                            <div class=divr>
                             <img style="height: 10vh; cursor:pointer" alt="ThankU QR badge" src="../assets/bluecirclesm.png">
                            </div>
                            </td>

                        </tr>
                    </table>
                    
                </td>
            </tr>
            <tr>
                <td style="padding-top: 0.0vh; padding-bottom: 0.0vh">              
                    <div class="notesInfoText" style="" :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">
                       Vicky was always struggling with clients wanting to add a tip for the stylist to their bill when paying by card and this was a real headache for her as a salon owner, with tips being classed as ‘takings’ and  subject to VAT and so on… 
                    </div>
                </td>
            </tr>
            <tr>
                <td style="padding-top: 0.0vh; padding-bottom: 0.0vh">              
                    <div class="notesInfoText" style="" :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">
                       For Vicky to differentiate each stylist's tip from her salon's takings and passing the cash onto the stylist was complicated - even her company's accountant didn’t know what the solution was to this problem. 
                    </div>
                </td>
            </tr>
           
            <tr>
                <td style="padding-top: 0.0vh; padding-bottom: 0.0vh">              
                    <div class="notesInfoText" style="" :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">
                        It was taking lots of Vicky's time to work it out each week and her time is already limited as a salon owner… 
                    </div>
                </td>
            </tr>
            <tr>
                <td style="padding-top: 0.0vh; padding-bottom: 0.0vh">              
                    <div class="notesInfoText" style="" :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">
                        Plus - passing tips onto stylists is classed as wages which then is subject to the relevant taxes which Vicky would need to deduct from her staff's wages, another headache...
                    </div>
                </td>
            </tr>
            
             
            <tr>
                <td style="padding-top: 0.0vh; padding-bottom: 0.0vh">              
                    <div class="notesInfoText" style="" :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">
                        …so to find a solution where the money completely bypassed her as a salon owner was such a relief!
                    </div>
                </td>
            </tr>
             <tr>
                <td style="padding-top: 0.0vh; padding-bottom: 1.2vh">              
                    <div class="notesInfoText" style="" :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">
                        Now with ThankU Cashless Tipping, Vicky’s staff receiving the tips are responsible for their own tips that go straight into their own bank account and it’s for the cliehts who love to tip to express their satisfaction with the service they’ve received but don’t always carry cash.
                    </div>
                </td>
            </tr>
            <tr>
                <td> 
                    <table width=100% cellpadding=0 cellspacing=0 border=0 style="outline:0.02em solid lightgray; background: #FFF8E5">
                        <tr>
                            <td style="padding-top: 2vh">
                                <b>GET THANKU INFO</b>
                            </td>
                        </tr>
                         <tr>
                            <td style="padding-top: 0vh">
                                <div class="notesInfoText" style="width: 85%; margin: auto" :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">
                                Please provide your email below to autpomatically receive our intro to ThankU Cashless Tipping pdf, giving you all the info you need.  
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            <div style="height: 7vh; display: flex;">
                                <input type="email" class="standard-textbox input" ref="email-login" placeholder="email">
                            </div>
                            </td>
                        </tr>
                         <tr>
                            <td style="padding-top: 0vh">
                                <div class="notesInfoText" style="padding-top: 0vh; padding-bottom: 1vh; width: 85%; margin: auto" :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">
                               <b>Act now!</b> - for a limited time, we are sending salons the ThankU QR badge media they need <b>at no cost.</b>  
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style="height: 9vh:">
                            <div><button class="widebtn" style="width: 97%; height: 9vh;" @click="this.doLoginDB();">Send me ThankU intro NOW</button></div>
                            </td>
                        </tr>
                        <tr>
                            <td style="height: 1vh:">
                            &nbsp;
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
           
        </table>       
      </div>
    </div>
</template>

<script>

import shared from '../shared.js';


export default {
    inject: ['globalPushForwardInterval', 'devEnv', 'appId', 'appJSKey'] ,
    data() {
        return {

            showWhatDetailsInfo: false,
            showSecureInfo: false,
            showGetBadges: false,
            showFees: false,
            showWhenPaid: false,
            showGetReceipt: false,
            showGetTipHistory: false,
            toggleButtonText: "Expand All",

            fontSizeNormal: 2.5, // vh
            fontSizeNormalInPXToUse: "",
            fontSizeNormalLineHeight: 3.3,// vh
            fontSizeNormalInPXLineHeightToUse: "",
            nhbfmembers: false,

            whatDetails: "What details do I need to provide thankU?",
            isGettingTipsSecure: "Is getting tips with ThankU secure?",
            howDoIGetHoldOfBadges: "How do I get hold of ThankU QR badges?",
            whatAreTheFees: "What are the fees for using thankU?",
            whenDoIGetPaid: "When do I get paid?",
            doIGetNotification: "Do I get a notification from ThankU when I receive a tip?",
            canIGetHistory: "Can I get a history of the tips I've received?",

        }
    },
    methods: {
        doFontSizeComputed () {
        
        this.fontSizeNormalInPXToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormal, 16);
        this.fontSizeNormalInPXLineHeightToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormalLineHeight, 20);
        
        },
        toggleAll(){

            // /console.log("toggling...");

            var status = false;

            if (this.toggleButtonText === "Expand All") {
                this.toggleButtonText = "Collapse All";
                status = true;
            } else {
                this.toggleButtonText = "Expand All";
                status = false;
            }

            this.showWhatDetailsInfo = status;
            this.showSecureInfo = status;
            this.showGetBadges = status;
            this.showFees = status;
            this.showWhenPaid = status;
            this.showGetReceipt = status;
            this.showGetTipHistory = status;
        },
        goBack(){
            // /console.log("we're off!");
            if (window.localStorage.getItem("nextScreen") !== null ){
                let returnJSON = { nextScreen: window.localStorage.getItem("nextScreen")};
                this.$emit('return-json', returnJSON);    
                window.localStorage.removeItem("nextScreen");
            } else {
                let returnJSON = { showHome: true }; 
                this.$emit('return-json', returnJSON);
            }
        },
        doSetMetas(){

            document.title = "All your questions answered on getting tips with ThankU Cashless Tipping, used at top salons such as Larry King and Josh Wood Colour. ";
            document.getElementsByTagName('meta').namedItem('description').setAttribute('content',
            'All your questions answered on getting tips with ThankU Cashless Tipping, used at top salons such as Larry King and Josh Wood Colour. Questions like: ' + this.whatDetails + " " + this.isGettingTipsSecure + " " + this.howDoIGetHoldOfBadges + " " + this.whatAreTheFees + " " + this.whenDoIGetPaid + " " + this.doIGetNotification + " " + this.canIGetHistory + " ") ;
            
        }
    },
    created(){
        this.doSetMetas();
        shared.saveToUserPath(this.devEnv, "Get tips more info " + new Date().toISOString(), this.globalPushForwardInterval);
        this.doFontSizeComputed();
        //window.addEventListener('resize', this.doFontSizeComputed); didn't want to do in this instance as the heigh appears to change as soon as you start scolling, the initial setting is good enough for now
        clearTimeout(window.localStorage.getItem("networkMsgBoxTimeoutId"));
        document.getElementById('networkMsgBox').style.display = 'none';
        console.log("networkMsgBox display cancelled");
    },
}
</script>