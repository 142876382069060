<template>
    <tr>
        <td colspan="3" style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: 0vh">
            <a v-bind:href="countersquare"><img height=157 width=224 style="cursor:pointer" alt="ThankU Cashless Tipping App"  ref="countersquareImg" v-bind:src="countersquaresm"></a>
        </td>
    </tr>

</template>

<script>

import countersquaresm from "../assets/countersquaresm2.webp";
import countersquare from "../assets/countersquare.webp";


export default {
    data() {
        return {
        countersquaresm: countersquaresm,
        countersquare: countersquare,
        }
    },
    props: {
        VW: Number,
    },
    methods: {
        resizeImg() {
        // the reason we have done it this way is because 1) we want to have img width and height attributes for Google pagespeed ranking and 2) the approach with using clamp() for the css didn't work even in proportion to img dims because the clamp could let the img expand to different points within the clamp, it was aspect locked - this makes sure we are always aspect locked
            let countersquareImg = this.$refs.countersquareImg;
            countersquareImg.width = this.VW * 0.8;
            countersquareImg.height = this.VW * 0.8 / 448 * 314; // the dims of the image 
            // console.log("countersquareImg image height is now " + countersquareImg.height);
            // this.$forceUpdate(); 
        },
    },
    created(){
        // console.log("this.VW: " + this.VW);
        // console.log("for lo! I LIVE!!!");
    },
    mounted(){
        this.resizeImg();
    },
}
</script>