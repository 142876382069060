<template>
    <div class="modal-overlay-canscroll" style="position: absolute; top: 10px; width: 100%; ">  <!-- not sure why but the height: 100% allows modal to center in the page otherwise it goes to top -->
      <div class="modal" style="padding-top: 5vh; padding-bottom: 5vh;"> 

        <table width=100% border=0 style="padding-left: 2.5vh; padding-right: 2.5vh">
          <tr>
            <td>
              <table width="100%" border=0 s>
                <tr>
                  <td width="30%">
                        <span class="primary-general-text divl" style="display: block; line-height: 15px;">&nbsp;</span> 
                  </td>
                  <td width="40%">
                    <img class="tulogo" style="height: 6vh; padding-bottom: 1vh" alt="ThankU logo" src="../assets/tutxtlogo.png">
                  </td>
                  <td width="30%">
                    <div class=divr>
                 
                      <button @click="doShowHome" class="text-only-button cancel"> <img class="tulogo" style="height: 4vh;" alt="close" src="../assets/greycross.png"></button>
                    </div>
                  </td>

                </tr>
              </table>
              
            </td>
          </tr>
         
           <tr>
            <td style="padding-top: 1vh; padding-bottom: 1vh">              
              <span class="lt-blue" style="font-size: 14px; ">

<p class="MsoNormal" style="margin-bottom: 0.0001pt; line-height: normal;"><b><span style="font-size: 13.5pt; font-family: &quot;Arial&quot;,sans-serif; color: rgb(94, 144, 227);">the
ThankU Team</span></b></p>
<br>

<p class="MsoNormal" style="margin-bottom: 0.0001pt; line-height: normal;"><b><span style="font-size: 12pt; font-family: &quot;Arial&quot;,sans-serif; color: rgb(94, 144, 227);">Contact:
</span></b></p>
<p class="MsoNormal" style="margin-bottom: 0.0001pt; line-height: normal;"><b><span style="font-size: 12pt; font-family: &quot;Arial&quot;,sans-serif; color: rgb(94, 144, 227);"></span></b></p>

<table class="MsoTableGrid" style="border: medium none ; border-collapse: collapse;" border="0" cellpadding="0" cellspacing="0">
<tbody>
<tr style="">
<td style="padding: 0cm; width: 134.45pt;" valign="top" width="179">
<p class="MsoNormal" style="margin-bottom: 0.0001pt; line-height: normal;"><span style="font-size: 10pt; font-family: &quot;Tahoma&quot;,sans-serif; color: rgb(11, 83, 148);">Customer
experience</span></p>
</td>
<td style="padding: 0cm; width: 177.15pt;" valign="top" width="236">
<p class="MsoNormal" style="margin-bottom: 0.0001pt; line-height: normal;"><span style="font-size: 10pt; font-family: &quot;Tahoma&quot;,sans-serif; color: rgb(11, 83, 148);"><a href="mailto:hello@thanku.app">hello@thanku.app</a> </span></p>
</td>
</tr>
<tr style="">
<td style="padding: 0cm; width: 134.45pt;" valign="top" width="179">
<p class="MsoNormal" style="margin-bottom: 0.0001pt; line-height: normal;"><span style="font-size: 10pt; font-family: &quot;Tahoma&quot;,sans-serif; color: rgb(11, 83, 148);">Support</span></p>
</td>
<td style="padding: 0cm; width: 177.15pt;" valign="top" width="236">
<p class="MsoNormal" style="margin-bottom: 0.0001pt; line-height: normal;"><span style="font-size: 10pt; font-family: &quot;Tahoma&quot;,sans-serif; color: rgb(11, 83, 148);"><a href="mailto:support@thanku.app">support@thanku.app</a> </span></p>
</td>
</tr>
<tr style="">
<td style="padding: 0cm; width: 134.45pt;" valign="top" width="179">
<p class="MsoNormal" style="margin-bottom: 0.0001pt; line-height: normal;"><span style="font-size: 10pt; font-family: &quot;Tahoma&quot;,sans-serif; color: rgb(11, 83, 148);">Technical
support</span></p>
</td>
<td style="padding: 0cm; width: 177.15pt;" valign="top" width="236">
<p class="MsoNormal" style="margin-bottom: 0.0001pt; line-height: normal;"><span style="font-size: 10pt; font-family: &quot;Tahoma&quot;,sans-serif; color: rgb(11, 83, 148);"><a href="mailto:tech@thanku.app">tech@thanku.app</a></span></p>
</td>
</tr>
<tr style="">
<td style="padding: 0cm; width: 134.45pt;" valign="top" width="179">
<p class="MsoNormal" style="margin-bottom: 0.0001pt; line-height: normal;"><span style="font-size: 10pt; font-family: &quot;Tahoma&quot;,sans-serif; color: rgb(11, 83, 148);">Payments</span></p>
</td>
<td style="padding: 0cm; width: 177.15pt;" valign="top" width="236">
<p class="MsoNormal" style="margin-bottom: 0.0001pt; line-height: normal;"><span style="font-size: 10pt; font-family: &quot;Tahoma&quot;,sans-serif; color: rgb(11, 83, 148);"><a href="mailto:payments@thanku.app">payments@thanku.app</a></span></p>
</td>
</tr>
<tr style="">
<td style="padding: 0cm; width: 134.45pt;" valign="top" width="179">
<p class="MsoNormal" style="margin-bottom: 0.0001pt; line-height: normal;"><span style="font-size: 10pt; font-family: &quot;Tahoma&quot;,sans-serif; color: rgb(11, 83, 148);">Press</span></p>
</td>
<td style="padding: 0cm; width: 177.15pt;" valign="top" width="236">
<p class="MsoNormal" style="margin-bottom: 0.0001pt; line-height: normal;"><span style="font-size: 10pt; font-family: &quot;Tahoma&quot;,sans-serif; color: rgb(11, 83, 148);"><a href="mailto:press@thanku.app">press@thanku.app</a> </span></p>
</td>
</tr>
<tr style="">
<td style="padding: 0cm; width: 134.45pt;" valign="top" width="179">
<p class="MsoNormal" style="margin-bottom: 0.0001pt; line-height: normal;"><span style="font-size: 10pt; font-family: &quot;Tahoma&quot;,sans-serif; color: rgb(11, 83, 148);">Tel</span></p>
</td>
<td style="padding: 0cm; width: 177.15pt;" valign="top" width="236">
<p class="MsoNormal" style="margin-bottom: 0.0001pt; line-height: normal;"><span style="font-size: 10pt; font-family: &quot;Tahoma&quot;,sans-serif; color: rgb(11, 83, 148);">+442078560378</span></p>
</td>
</tr>
</tbody>
</table>
<br><p class="unsubscribe--sendername" style="margin: 0cm 0cm 0.0001pt; background: white none repeat scroll 0%; text-align: center; line-height: 15pt; -moz-background-clip: -moz-initial; -moz-background-origin: -moz-initial; -moz-background-inline-policy: -moz-initial; vertical-align: baseline;" align="center"><span class="unsubscribe--sendercity"><span style="border: 1pt none windowtext; padding: 0cm; font-size: 9pt; font-family: Poppins; color: black;">thankU<sup>®</sup> by Appsanely
Ltd.<br>15 Bolton Gardens London SW5 0AL, UK</span></span><span class="unsubscribe--sendercity"><span style="border: 1pt none windowtext; padding: 0cm; font-size: 9pt; font-family: Poppins;"></span></span></p>

<p style="margin: 0cm 0cm 0.0001pt; background: white none repeat scroll 0%; text-align: center; line-height: 15pt; -moz-background-clip: -moz-initial; -moz-background-origin: -moz-initial; -moz-background-inline-policy: -moz-initial; vertical-align: baseline;" align="center"><span class="unsubscribe--senderaddress"><span style="border: 1pt none windowtext; padding: 0cm; font-size: 9pt; font-family: Poppins; color: rgb(68, 68, 68);"><a href="http://www.thanku.app/">www.thanku.app</a> </span></span><span style="font-size: 9pt; font-family: Poppins; color: rgb(68, 68, 68);">+442078560378</span></p>

<p style="margin: 0cm 0cm 0.0001pt; background: white none repeat scroll 0%; text-align: center; line-height: 15pt; -moz-background-clip: -moz-initial; -moz-background-origin: -moz-initial; -moz-background-inline-policy: -moz-initial; vertical-align: baseline;" align="center"><span style="font-size: 9pt; font-family: Poppins; color: rgb(68, 68, 68);"></span></p>

                  
              </span> 
           </td>
          </tr>
          <tr>
            <td>
              <div class="actionbtn-div" style="padding-top: 1vh">
                <button class="widebtn" style="width: 20%; height: 6vh;"  @click="this.doShowHome()" >Ok</button>
              </div>
            </td>
          </tr>
         
        </table>       
      </div>
    </div>
</template>

<script>
import shared from '../shared.js';
export default {
  props:{
    showHomePageFromGoogleAd: Boolean,
  },
  methods: {
    
    doShowHome(){
      // /console.log("we're off!");

      let returnJSON = { showHome: true }; 
      this.$emit('return-json', returnJSON);

    },
    doSetMetas(){

      document.title = "Contact ThankU Cashless Tipping - based in the Royal Borough of Kensington and Chelsea, London";
      document.getElementsByTagName('meta').namedItem('description').setAttribute('content',
      "Contact ThankU Cashless Tipping - based in the Royal Borough of Kensington and Chelsea, we are essentially open all waking hours in the UK timezone. If you are wondering what the quality of our support is like, just ask and we will refer you to one of our top London clients for a reference.") ;
    
    }
    
    
  }, 
  created(){
    document.body.style.maxWidth = "100vw"; // for Google mobile crawler had to limit homepage width on body tag, this resets it
    this.doSetMetas();
    shared.saveToUserPath(this.devEnv, "Show contact info " + new Date().toISOString(), this.globalPushForwardInterval);
    // console.log("showHomePageFromGoogleAd ON CONTACT::: " + this.showHomePageFromGoogleAd);
  }
}
</script>

