<template>

</template>

<script>

import sharedhome from '../sharedhome.js';
import LogRocket from 'logrocket';

export default {
    inject: ['globalPushForwardInterval', 'devEnv', 'appId', 'appJSKey'],
    props: {
        functionName: String,
        paramsObject: Object,
        logRocketOrganisationSlug: String,
        tipperObjectId: String,
        tipperDisplayname: String,
    },
    data() {
        return {
        
        }
    },
    methods: {

        deployLogRocket(logRocketOrganisationSlug){ // IMPORTANT!!!! If we find this can deploy as aync await then be VERY CAREFUL as turing the functions it appears in into async functions decouples the return so you must add await in whatever functions call them and so forth up the function chain

            this.loggingError = true; // from here on the saved user path will now in fact be emailed

            if (this.tipperObjectId === "4g278vWUb1" || this.tipperObjectId === "2fBkimFcIw" ){
                // return; // don't record myself
            }

            var LRDisplayname = "LR Name not given";
            if (this.tipperDisplayname !== undefined && this.tipperDisplayname !== "") {
                LRDisplayname = this.tipperDisplayname;
            }
      
            try {


                if (logRocketOrganisationSlug !== undefined && logRocketOrganisationSlug !== ""){
                LogRocket.init(logRocketOrganisationSlug);
                } else {
                    LogRocket.init('thanku-alt-5/thanku-alt-5'); //LogRocket.init('thanku-alt-4/thanku-alt-4'); //LogRocket.init('thanku-alt-3/thanku-alt-3');//LogRocket.init('thanku-alt-2/thanku-alt-2');//LogRocket.init('thanku-alt/tualt'); //LogRocket.init('fpi3vr/thanku');  //LogRocket.init('thanku-alt-10/thanku-alt-10'); //LogRocket.init('thanku-alt-9/thanku-alt-9'); //LogRocket.init('thanku-alt-8/thanku-alt-8');//LogRocket.init("thanku-alt-7/thanku-alt-7"); //LogRocket.init('thanku-alt-6/thanku-alt-6'); //
                // console.log("REGULAR LOGGING");
                }
                
                LogRocket.identify(this.tipperObjectId, {
                name: LRDisplayname + ": tipping",
                email: undefined,
                // Add your own custom user variables here, ie:

                });
                console.log("LOGROCKET LOADED! on LoadLogRocketFromHome: " + this.tipperObjectId + " : " + LRDisplayname + " slug: " + logRocketOrganisationSlug);
            } catch (e) {
                console.log("error starting LogRocket");
                // We don't have Parse here
                // const params = {
                //   toEmail: "appalert@thanku.app",
                //   subject: "ALERT! LogRocket initialisation error: " + e.message,
                //   body: "ALERT! LogRocket initialisation error: " + e.message,
                // };

                // Parse.Cloud.run("sendEmail", params);
                // fail silently do nothing
            }
        },
    },
    created(){
        console.log("Behold! I am a log rocket ready to BLAST OFF!!");
        // console.log("functionName on LoadLogRocketFromHome is: " + this.functionName);
        
    },
    async mounted(){

        // about this component - in order to maximise Google PageSpeed needed to reduce the size of initially loading vendorchunks.js, meaning I needed not to load LogRocket if it wasn't required. So now when it is required, loadLogRocket is toggled on App.vue which means the LogRocket module is loaded. It is triggered on mounting and emits to return a JSON object if required. Note the use of remoting out to sharedhome.doLogRocket. This was as a result of a failed prior attempt to loadLogRocket conditionally, and I couldnt be bothered to incorporate it into this component, so it is an unnecessary extra leg.

        // const selectUserData = await sharedhome.doParseServer('validateUserLinkDetails', params, this.appId, this.appJSKey);

        // console.log("I shall now BLAST THE FUCK OFF!!");

        this.deployLogRocket(this.logRocketOrganisationSlug);

        // let returnParams = await sharedhome.doParseServer(this.functionName, this.paramsObject, this.appId, this.appJSKey); // actually let's do it all from LoadParseFromHome
        // let returnParams = await sharedhome.doLoadLogRocket(this.functionName, this.paramsObject, this.appId, this.appJSKey);  this was the old way, accessing the function directly - since we are having soem email triggers for info, we assume that the LR trigger is always through an email send - if we want to stop those sends in due course as unneeded then we need to suppress them on the server

        // console.log("did Load LogRocket with this result:: " + JSON.stringify(returnParams, null, 2));

    },
}
</script>