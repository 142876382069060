<template>
  <!-- Bootstrap, though loaded first, was overriding some unknown style element, hard to solve out, which was sending the login page to top right. Rather than waste a long time trying to find out which, I am only applying Bootstrap to pages which use it -->
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css" integrity="sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH" crossorigin="anonymous">
  <div>
    <!-- not sure why but the height: 100% allows modal to center in the page otherwise it goes to top -->
    <div class="container col-12 offset-0 col-md-6 offset-md-3 tugradient1" style="padding-top: 5vh; padding-bottom: 5vh">
      <table
        class="container" 
        border="0"
        style="padding-left: 2.5vh; padding-right: 2.5vh"
      >
        <tr>
          <td>
            <table width="100%" border="0" s>
              <tr>
                <td width="30%">
                  <span
                    class="primary-general-text divl"
                    style="display: block; line-height: 15px"
                    >&nbsp;</span
                  >
                </td>
                <td width="40%">
                  <img
                    class="tulogo"
                    style="height: 6vh; padding-bottom: 1vh"
                    alt="ThankU logo"
                    src="../assets/tutxtlogomd.webp"
                  />
                </td>
                <td width="30%">
                  <div class="divr">
                    <button @click="doShowHome" class="text-only-button cancel">
                      <img
                        class="tulogo"
                        style="height: 4vh"
                        alt="close"
                        src="../assets/greycross.png"
                      />
                    </button>
                  </div>
                </td>
              </tr>
            </table>
          </td>
        </tr>

        <tr>
          <td class="tuparachunkpadding" style="padding-top: 1vh; padding-bottom: 1vh">
            <span class="tusmallerfontsize tufont">
              ThankU End-User License Agreement
              <p></p>
              The following is the End-User License Agreement (“Agreement”) for
              the ThankU website and ThankU mobile phone application. Please
              read this document carefully before you use the ThankU website or
              ThankU mobile phone application (the 'ThankU App' or the ‘App’).
              <p></p>
              <p></p>
              By using the ThankU website or ThankU App you accept this
              Agreement. If you do not accept the Agreement you must not use the
              ThankU website or ThankU mobile phone application.
              <p></p>
              <p></p>
              1. PARTIES
              <p></p>
              This Agreement is made between you the customer ("Customer") and
              Appsanely Ltd trading as ThankU, at 15 Bolton Gardens, London SW5
              0AL, UK ("thankU"); email: hello@thanku.app;
              <p></p>
              <p></p>
              2. INTERPRETATION
              <p></p>
              In this Agreement, unless the context otherwise requires:
              <p></p>
              "Us", "We" or "thankU" or “thankU” is the provider of a mobile
              phone application that lets you (the Customer) tip other
              registered ThankU users or to receive tips from other registered
              ThankU users less a transaction processing fee paid to thankU;
              <p></p>
              "Internet" means the global data network comprising interconnected
              networks using the TCP/IP ("Transmission Control Protocol/Internet
              Protocol");
              <p></p>
              "Law" means any law, statute or regulation, guideline or code of
              conduct (whether or not having the force of law) in any
              jurisdiction to which ThankU or the Customer is from time to time
              subject;
              <p></p>
              "Service" means the service provided by ThankU via the ThankU App
              technology platform whereby the Customer may either tip other
              registered ThankU users or receive tips from other registered
              ThankU users less a transaction processing fee paid to thankU;
              <p></p>
              "Software" means any proprietary software utilised by ThankU to
              enable the Customer to use the Service;
              <p></p>
              "ThankU Transaction Processing Fees" means the fees which thankU
              adds to the tip amount selected by the person giving a tip, the
              aggregate of which is charged to that person through their
              selected payment method;
              <p></p>
              "Recipient Transaction Processing Fees" means the fees which
              ThankU deducts from the amount selected by the person giving a tip
              before remitting the net amount to the tip recipient. Recipient
              Transaction Processing Fees are deducted exclusively in order to
              compensate ThankU for amounts charged by its payment processor
              Stripe Inc in relation to the payment services provided of which
              the tip recipient is the exclusive beneficiary;
              <p></p>
              “Licensed Application” means the use of the Service under a
              non-exclusive non-transferable licence to use the Software solely
              in executable form and only to the extent necessary for use of the
              Service and for no other purpose.
              <p></p>
              <p></p>
              ThankU reserves the right to amend this Agreement at any time. All
              amendments to the Agreement will be posted on-line. If you are
              using the App you will be notified to changes in the Agreement
              after downloading a new version of the application.
              <p></p>
              Your continued use of the Service or the ThankU website or App
              means that you have accepted any change in the Agreement.
              <p></p>
              <p></p>
              3. SERVICES
              <p></p>
              ThankU is the provider of the Service. A tip constitutes a gift,
              freely given, and ThankU acts as agent of the tip recipient in the
              remittance of the tip gift from the person tipping to the tip
              recipient.
              <p></p>
              <p></p>
              4. FEES
              <p></p>
              ThankU charges ThankU Transaction Processing Fees to the person
              giving the tip, and Recipient Transaction Processing Fees to the
              person receiving the tip (together the 'Fees'). Recipient
              Transaction Processing Fees are deducted exclusively in order to
              compensate ThankU for amounts charged by its payment processor
              Stripe Inc in relation to the payment services provided of which
              the tip recipient is the exclusive beneficiary (the 'Stripe
              Recipient Fees'). If the Stripe Recipient Fees exceed the
              Recipient Transaction Processing Fees, ThankU pays the excess
              amount which is not then re-charged to the tip recipient. The
              level of the Fees is as published in the ThankU App and associated
              email communications and as published on the ThankU website on the
              FAQ page. The level of the ThankU Transaction Processing Fees are
              advised to the person tipping prior to payment being taken.
              <p></p>
              <p></p>
              5. CUSTOMER'S OBLIGATIONS
              <p></p>
              The Customer shall not do anything that affects the integrity or
              security of the website or App or causes or may cause harm, damage
              or unreasonable inconvenience to other users of this website or
              mobile phone application.
              <p></p>
              <p></p>
              The Customer must use the ThankU website and App for legitimate
              legal use only. Any other use shall constitute a breach of this
              End User License Agreement.
              <p></p>
              <p></p>
              The Customer acknowledges that the Customer will be required to
              fully and accurately complete a registration process, which will
              include amongst other things the creation of a password, in order
              to have access to the ThankU mobile phone application.
              <p></p>
              <p></p>
              The Customer acknowledges that the purpose of the ThankU Service
              is to connect members of the public who wish to tip other
              registered ThankU users or to receive tips from other registered
              ThankU users less a transaction processing fee paid to ThankU . It
              is a condition of use of this Service that the Customer provides
              ThankU with correct Customer contact details, including an email
              address that is valid and accessible to the Customer.
              <p></p>
              <p></p>
              It is the Customer’s responsibility to ensure that they are using
              the latest version of the ThankU App and/or web browsers in order
              to avail themselves of the latest security standards and
              functionality enhancements.
              <p></p>
              <p></p>
              The Customer warrants that all information provided on
              registration and during the course of this Agreement is true,
              complete and accurate and that the Customer shall promptly inform
              ThankU of any changes to such information by logging into the App
              or website and providing the relevant updates.
              <p></p>
              <p></p>
              Any password allocated to or created by the Customer to enable the
              Customer to use the Services shall be kept confidential by the
              Customer and the Customer shall immediately change their password
              if they have reason to believe that an unauthorised third party
              has become aware of that password.
              <p></p>
              <p></p>
              The Customer shall take reasonable steps to prevent unauthorised
              access to their account via any computer or mobile device the
              Customer uses to access their account.
              <p></p>
              <p></p>
              The Customer warrants that (i) he/she is not located in a country
              that is subject to a U.S. Government embargo, or that has been
              designated by the U.S. Government as a “terrorist supporting”
              country; and (ii) he/she is not listed on any U.S. Government list
              of prohibited or restricted parties.
              <p></p>
              <p></p>
              The Customer acknowledges and agree that Apple and Google, and
              Apple and Google’s subsidiaries respectively, are third party
              beneficiaries of this End User License Agreement (EULA), and that,
              upon the Customer’s acceptance of the terms and conditions of the
              End User License Agreement Apple and/or Google will have the right
              (and will be deemed to have accepted the right) to enforce the
              EULA against the Customer as a third party beneficiary thereof.
              <p></p>
              <p></p>
              The Customer acknowledges that the EULA is concluded between
              ThankU and the Customer only, and not with Apple and/or Google,
              and that ThankU , not Apple or Google, are solely responsible for
              the ThankU Licensed Application and the content thereof.
              <p></p>
              <p></p>
              <p></p>
              6. THANKU RESPONSIBILITIES
              <p></p>
              ThankU is solely responsible for the maintenance and support
              services with respect to the website and mobile phone application.
              <p></p>
              <p></p>
              ThankU will use reasonable endeavours to provide the Service.
              ThankU is entitled to rely on technology infrastructure provided
              to ThankU Apple, Google, and others in providing the Service.
              <p></p>
              <p></p>
              ThankU will use reasonable endeavours to notify Customers of
              relevant security and functionality enhancements to the thankU
              mobile phone application.
              <p></p>
              <p></p>
              ThankU is solely responsible for any product warranties to the
              extent not effectively disclaimed under Section 7. Should the
              mobile application not conform to any applicable warranty you may
              notify Apple or Google, and Apple or Google respectively will
              refund the purchase price, if any, for the ThankU App to you. To
              the maximum extent permitted by applicable law, neither Apple nor
              Google will have any other warranty obligation whatsoever with
              respect to the ThankU application, and any other claims, losses,
              liabilities, damages, costs or expenses attributable to any
              failure to conform to any warranty will be ThankU's sole
              responsibility.
              <p></p>
              <p></p>
              ThankU is solely responsible for the investigation, settlement and
              discharge of any third party intellectual property infringement
              claim.
              <p></p>
              <p></p>
              ThankU acknowledges and agree that Apple and Google, and Apple and
              Google’s subsidiaries respectively, are third party beneficiaries
              of this EULA, and that Apple and/or Google will have the right
              (and will be deemed to have accepted the right) to enforce the
              EULA against the Customer as a third party beneficiary thereof.
              <p></p>
              <p></p>
              ThankU acknowledges that the EULA is concluded between ThankU and
              the Customer only, and not with Apple or Google, and that ThankU ,
              not Apple or Google, are solely responsible for the thankU
              Licensed Application and the content thereof.
              <p></p>
              <p></p>
              7. CANCELLATIONS, REFUNDS & COMPLAINTS
              <p></p>
              <p></p>
              Cancellation & Refunds
              <p></p>
              You may cancel at any time a tip until payment has been confirmed
              through the payment method selected on your account (e.g. Apple
              Pay, PayPal or credit card). If a payment has been made:
              <p></p>
              <p></p>
              A payment transaction through the App is de facto completed at the
              time of payment since the giving of a tip through the App are
              voluntary monetary offerings for a service or a physical item or
              to show appreciation.
              <p></p>
              <p></p>
              A user may cancel a transaction within 5 minutes of its taking
              place, which is when you authorise payment. To cancel a
              transaction, go to your user Account page in the ThankU app,
              select 'Transaction Details', select your most recent transaction
              from the list and on the Transaction Detail screen which appears,
              click 'Cancel Transaction'.
              <p></p>
              <p></p>
              Upon cancellation, we will process your refund within 7 working
              days.
              <p></p>
              <p></p>
              Please be aware that your cancellation of the transaction will
              immediately be notified to the recipient.
              <p></p>
              <p></p>
              If more than 5 minutes has elapsed since the transaction was
              completed, you may still dispute the transaction if you believe
              you have a legitimate reason for doing so. To dispute a
              transaction, simply click 'Dispute' which will have replaced
              'Cancel Transaction' in the Transaction Detail screen as referred
              above.
              <p></p>
              <p></p>
              Please be aware that your location activity is logged by the app
              in and around the time of the transaction, and we use this data to
              assess and may apply an algorithm to automate the assessment of
              the authenticity or otherwise of any disputed transaction or claim
              for refund, although a cancellation will be refunded in any event
              so long as it is applied for within 5 minutes, as referred above.
              <p></p>
              <p></p>
              Any users who continually cancel transactions, without good
              reason, and/or who undertake or engage in antisocial or apparently
              dishonest behavior in and/or through the App may be banned from
              further usage of the App, on a temporary or permanent basis, in
              our sole discretion.
              <p></p>
              <p></p>
              Complaints Procedure - what to do if you have a complaint against
              thankU?
              <p></p>
              ThankU aims to provide a high level service to all our customers
              but occasionally things can go wrong. When this happens we will do
              what we can to put things right and assist you.
              <p></p>
              <p></p>
              If you have a complaint about our service, please contact us
              through the ‘Contact Us’ sections on the website and/or through
              the App.
              <p></p>
              <p></p>
              Please include your name and address, a contact telephone number,
              your email address and details of why you are unhappy. This will
              help us to respond to you as quickly as possible. If we do not
              have enough information to investigate your complaint we will try
              and contact you to ask for further details.
              <p></p>
              <p></p>
              If we are unable to resolve your complaint by the close of
              business the next working day, we will send a written
              acknowledgement of your complaint to you within 5 working days of
              its receipt. In our acknowledgement we will advise you of the name
              and job title of the person who will be dealing with your
              complaint.
              <p></p>
              <p></p>
              If after contacting us you are not satisfied please write to our
              Customer Relations Manager at the address shown below.
              <p></p>
              Please address your letter to:
              <p></p>
              Customer Relations Manager
              <p></p>
              thankU
              <p></p>
              15 Bolton Gardens
              <p></p>
              London, SW5 0AL
              <p></p>
              For the purposes of handling complaints our working day is 9am to
              5pm Monday to Friday, excluding Bank Holidays.
              <p></p>
              <p></p>
              By the end of eight weeks after receipt of your original complaint
              letter we will send you a final response or a letter explaining
              why we are still not in a position to issue a final response and
              advising you of when we expect to be able to do so.
              <p></p>
              <p></p>
              8. INTELLECTUAL PROPERTY RIGHTS
              <p></p>
              All intellectual property rights (including all copyright,
              patents, trademarks, service marks, trade names, designs
              (including the "look and feel" and other visual or non-literal
              elements) whether registered or unregistered) in the thankU
              website or mobile phone application, information and content on
              the ThankU website or mobile phone application, any database
              operated by ThankU and Software shall remain the property of
              ThankU and the Customer shall not obtain, and shall not attempt to
              obtain any title to any such intellectual property rights.
              <p></p>
              <p></p>
              No part of the Service may be reproduced in any form or used in
              any other way except in accordance with these terms and
              conditions.
              <p></p>
              <p></p>
              ThankU and the ThankU logo are trademarks of Appsanely Ltd. You
              must not use these or any other registered or unregistered trade
              marks on the Service without the prior written permission of
              ThankU.
              <p></p>
              <p></p>
              ThankU grants the Customer a non-exclusive non-transferable
              licence to use the Software solely in executable form and only to
              the extent necessary for use of the Service and for no other
              purpose.
              <p></p>
              <p></p>
              9. TERMINATION
              <p></p>
              ThankU shall be entitled to suspend the Services and/or terminate
              this Agreement if:
              <p></p>
              (i) the Customer commits a breach which in ThankU's reasonable
              opinion is serious enough to merit immediate termination; or
              <p></p>
              (ii) the Customer commits any breach of these terms and in the
              case of a breach which is capable of remedy, fails to remedy the
              same within 7 days after receipt of a notice giving particulars of
              the breach requiring it to be remedied; or
              <p></p>
              (iii) ThankU suspects on reasonable grounds that the Customer may
              have committed or be committing any fraud against ThankU or any
              Supplier.
              <p></p>
              The right to terminate this Agreement shall not prejudice any
              other right or remedy of ThankU in respect of any breach or any
              rights, obligations or liabilities accrued prior to termination.
              <p></p>
              <p></p>
              10. CUSTOMER BREACH
              <p></p>
              The Customer shall compensate ThankU in full for each loss,
              liability or cost incurred by ThankU arising out of:
              <p></p>
              (i) any claims or legal proceedings arising from the Customer's
              use of the Services or use of the Services through the Customer's
              password which are brought or threatened against ThankU by any
              other person; or
              <p></p>
              (ii) any breach of this Agreement by the Customer.
              <p></p>
              These terms shall not prejudice any other right or remedy of
              ThankU in respect of any breach or any rights, obligations or
              liabilities accrued prior to termination. ThankU reserves the
              right to take action as appropriate, including denying Customer
              access to this website or mobile phone application, bringing legal
              proceedings against Customer and disclosing such information to
              appropriate legal and/or regulatory bodies.
              <p></p>
              <p></p>
              11. LIMITATION OF LIABILITY
              <p></p>
              Nothing in these terms and conditions limits or excludes the
              liability of ThankU for death or personal injury caused by
              ThankU's negligence or any other liability which may not be
              limited or excluded under applicable law.
              <p></p>
              <p></p>
              ThankU will use its reasonable endeavours to ensure that
              information provided to it by other users of the Service is
              accurate but ThankU does not guarantee the accuracy, completeness,
              legality or suitability for the Customer's needs of any
              information which is provided to the Customer by any other users
              of the Service and/or is accessed or obtained by the Customer via
              the ThankU website or mobile phone application, the ThankU call
              centre or otherwise through using the Service.
              <p></p>
              <p></p>
              You agree that ThankU will not be liable for any loss, claim or
              damage (whether direct or indirect) which arises out of or is in
              any way connected with:
              <p></p>
              (i) any use of the Service, the ThankU website or its contents;
              <p></p>
              (ii) any failure or delay in any component of the ThankU website,
              the mobile phone application, the provision of the Service or the
              provision by any Supplier of its goods, products or services to
              the Customer;
              <p></p>
              (iii) any use of or reliance upon any information, material,
              software, products or services obtained through the thankU
              website, the ThankU call centre or otherwise through using the
              Service (including, without limitation, any information, material,
              software, products or services of any Suppliers); or
              <p></p>
              (iv) any transaction or agreement between the Customer and any
              Supplier or any attempt to enter into a transaction or an
              agreement with any Supplier, in all cases even if ThankU has been
              forewarned of the possibility of such loss or damage.
              <p></p>
              <p></p>
              12. DATA PROTECTION
              <p></p>
              ThankU shall comply with all applicable data protection laws in
              the UK. For a description of how ThankU uses personal data the
              Customer should refer to ThankU's Privacy Policy which can be
              found on the website and in the mobile application.
              <p></p>
              <p></p>
              13. NO PARTNERSHIP / AGENCY
              <p></p>
              Nothing in this Agreement shall be construed to create a joint
              venture, partnership or agency relationship between the Customer
              and ThankU and neither party shall have the right or authority to
              incur any liability debt or cost or enter into any contracts or
              other arrangements in the name of or on behalf of the other.
              <p></p>
              <p></p>
              14. ASSIGNMENT
              <p></p>
              The Customer shall not assign or delegate or otherwise deal with
              all or any of its rights or obligations under this Agreement.
              ThankU shall have the right to assign or otherwise delegate all or
              any of its rights or obligations under this Agreement to any
              person or entity.
              <p></p>
              <p></p>
              15. FORCE MAJEURE
              <p></p>
              ThankU shall not be liable for any breach of its obligations under
              this Agreement where it is hindered or prevented from carrying out
              its obligations by any cause outside its reasonable control,
              including without limitation by lightning, fire, flood, extremely
              severe weather, strike, lock-out, labour dispute, act of God, war,
              riot, civil commotion, malicious damage, failure of any
              telecommunications or computer system, compliance with any law,
              accident (or by any damage caused by any of such events).
              <p></p>
              <p></p>
              16. ENTIRE AGREEMENT AND NO WAIVER
              <p></p>
              This Agreement represents the entire understanding between the
              parties in relation to the subject matter herein and supersedes
              all other agreements or representations by either party, whether
              oral or written.
              <p></p>
              <p></p>
              No waiver by ThankU of any future defaults, whether of a like or
              different character, no granting of time or other forbearance or
              indulgence by ThankU to the Customer shall in any way release,
              discharge or otherwise affect the liability of the Customer under
              this Agreement.
              <p></p>
              <p></p>
              17. GOVERNING LAW
              <p></p>
              This Agreement shall be governed by and construed in accordance
              with the laws of England and Wales and both parties hereby submit
              to the non-exclusive jurisdiction of the courts of England and
              Wales.
              <p></p>
              <p></p>
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <div class="actionbtn-div" style="padding-top: 1vh">
              <button
                class="widebtn"
                style="width: 20%; height: 6vh"
                @click="this.doShowHome()"
              >
                Ok
              </button>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import shared from "../shared.js";
export default {
  methods: {
    doShowHome() {
      // /console.log("we're off!");

      let returnJSON = { showHomeNew: true };
      this.$emit("return-json", returnJSON);
    },
  },
  created() {
    document.body.style.maxWidth = "100vw"; // for Google mobile crawler had to limit homepage width on body tag, this resets it
    shared.saveToUserPath(
      this.devEnv,
      "Show terms " + new Date().toISOString(),
      this.globalPushForwardInterval
    );
    
  },
};
</script>
<style>
  /* this makes the scroll time instantaneous */
  html {
    scroll-behavior: auto !important; 
  }
</style>