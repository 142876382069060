<template>
  <nav
    @click="this.doClicked"
    class="navbar fixed-top container text-center navbar-expand-lg bg-body-tertiary"
    style="outline: 0em dashed blue; z-index: 997"
  >
    <!-- z-index is to keep it below showPopUp on app.vue -->
    <div class="container-fluid tubackcolour">
      <a class="navbar-brand">
        <a class="navbar-brand" style="outline: 0em dashed blue"
          ><img
            @click="$emit('do-show-home')"
            style="cursor: pointer"
            class="tunavbarlogoheight"
            alt="ThankU logo"
            src="../assets/tutxtlogomd.webp" /></a
      ></a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav">
          <li
            class="nav-item "
            @click="this.collapseMe"
            style="cursor: pointer; outline: 0em dashed orange"
          >
            <a class="nav-link active " aria-current="page">Home</a>
          </li>

          <li
            class="nav-item mediaSelectPhone"
            @click="$emit('do-show-account')"
            style="
              cursor: pointer;
              display: flex;
              outline: 0em dashed orange;
            "
          >
            <a
              class="nav-link"
              style="margin: auto; "
              ><span v-if="this.loggedInUserDisplayname === ''">Login</span>
              <span
                v-if="this.loggedInUserDisplayname !== ''"
                style="outline: 0em dashed blue"
                >Account
                <img
                  style="
                    border-radius: 50%;
                    height: 25px;
                    width: auto;
                    outline: 0.01em solid lightgrey;
                  "
                  alt="Account settings"
                  :src="this.userImageDataComputed" /></span
            ></a>
          </li>

          <li
            class="nav-item"
            @click="$emit('do-show-gettipsstart')"
            style="cursor: pointer; outline: 0em dashed orange"
          >
            <a class="nav-link">Get thankU</a>
          </li>
          <li
            class="nav-item"
            @click="$emit('do-show-givetips')"
            style="cursor: pointer"
          >
            <a class="nav-link">Tippers</a>
          </li>
          <li
            class="nav-item"
            @click="$emit('do-show-gettips')"
            style="cursor: pointer"
          >
            <a class="nav-link">Recipients</a>
          </li>
           <li
            class="nav-item"
            @click="$emit('do-show-tipwithoutscanning')"
            style="cursor: pointer"
          >
            <a class="nav-link">Tip now!</a>
          </li>
          <li
            class="nav-item"
            @click="$emit('do-download-brochure')"
            style="cursor: pointer"
          >
            <a class="nav-link">Brochure</a>
          </li>
          <li
            class="nav-item"
            @click="$emit('do-show-contact')"
            style="cursor: pointer"
          >
            <a class="nav-link">Contact</a>
          </li>
          <li class="nav-item dropdown" style="display: none">
            <a
              class="nav-link dropdown-toggle"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Dropdown link
            </a>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item">Action</a></li>
              <li><a class="dropdown-item">Another action</a></li>
              <li><a class="dropdown-item">Something else here</a></li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="nav-item tuhideonsize">
        <ul class="navbar-nav">
          <li class="nav-item" style="cursor: pointer">
            <div style="padding-right: 1vw">
              <button
                class="tuhomepagesignupbutton"
                style="width: 100%"
                @click="$emit('do-show-gettipsstart')"
              >
                Get set up for free!
              </button>
            </div>
          </li>
        </ul>
      </div>
      <div
        v-if="this.loggedInUserDisplayname === ''"
        class="nav-item tuhideonsize"
      >
        <ul class="navbar-nav">
          <li
            class="nav-item"
            @click="$emit('do-show-account')"
            style="cursor: pointer"
          >
            <a class="nav-link"><b>Login</b></a>
          </li>
        </ul>
      </div>
      <div class="nav-item tuhideonsize">
        <ul class="navbar-nav">
          <li
            class="nav-item"
            @click="$emit('do-show-account')"
            style="cursor: pointer"
          >
            <img
              style="width: 45%; border-radius: 50%; height: 60px; width: auto"
              alt="Account settings"
              :src="this.userImageDataComputed"
            /><span
              class="notesInfoText"
              style="
                font-size: clamp(2px, 1.5vh, 12px);
                overflow-wrap: break-word;
              "
              ><br />{{ this.loggedInUserDisplayname }}</span
            >
          </li>
        </ul>
      </div>

      <!-- v-if="showImages === true"  -->
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavigationBar",
  data() {
    return {
      loggedInUserDisplayname: "",
    };
  },
  computed: {
    userImageDataComputed() {
      if (window.localStorage.getItem("userPhotoFileData") !== null) {
        // so the is we want to ensure we have the correct photo, and if the user switches devices (edge case) that won't be the case. We could easily sort this to check if the filename in the user's record was the same as in the localStorage but that is an expensive call. So compromise is to assume that the user is going to do something - either go to account or make a tip and we will refresh local storage at that point
        // ////console.log("userPhotoFileData::: " + window.localStorage.getItem("userPhotoFileData") );
        // /alert("COMPUTED from localStorage userPhotoFileData: " + window.localStorage.getItem("userPhotoFileData"));
        return window.localStorage.getItem("userPhotoFileData");
      } else {
        // /alert("simply returned accountperson.png");
        return require("@/assets/accountperson.png");
      }
    },
  },
  methods: {
    doClicked() {
      console.log("clicked navbar");
    },
    setDis() {
      if (window.localStorage.getItem("tudis") !== null) {
        // for some reason it's null not undefined
        // /////console.log("setDis tudis::: " + window.localStorage.getItem("tudis"));
        this.loggedInUserDisplayname = window.localStorage.getItem("tudis");
      }
    },
    collapseMe(){
      let element = document.querySelector('#navbarNavDropdown');
      bootstrap.Collapse.getOrCreateInstance(element).toggle();
      this.$emit('do-show-home');
    }
  },
  mounted() {
    this.setDis();
  },
};
</script> 

<style scoped>
@media (max-width: 575.98px) {
  .tuhideonsize {
    display: none !important;
  }
}

/* // Small devices (landscape phones, 576px and up) THIS DOES MY IPHONE 12 IN LANDSCAPE, AND DESKTOP*/
/*  * @media (min-width: 576px) and (max-width: 767.98px) {  */
@media (min-width: 576px) {
  .tuhideonsize {
    display: normal !important;
  }
}
</style>