<template>
    <tr>
        <td colspan="3" style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: 0vh">
            <a v-bind:href="sticker"><img height=110 width=160 style="cursor:pointer" alt="ThankU Cashless Tipping App"  ref="stickerImg" v-bind:src="stickersm"></a>
        </td>
    </tr>

</template>

<script>

import stickersm from "../assets/staticclingsm.webp";
import sticker from "../assets/staticcling.png";


export default {
    data() {
        return {
        stickersm: stickersm,
        sticker: sticker,
        }
    },
    props: {
        VW: Number,
    },
    methods: {
        resizeImg() {
        // the reason we have done it this way is because 1) we want to have img width and height attributes for Google pagespeed ranking and 2) the approach with using clamp() for the css didn't work even in proportion to img dims because the clamp could let the img expand to different points within the clamp, it was aspect locked - this makes sure we are always aspect locked
            let stickerImg = this.$refs.stickerImg;
            stickerImg.width = this.VW * 0.8;
            stickerImg.height = this.VW * 0.8 / 800 * 550; // the dims of the image 
            // console.log("stickerImg image height is now " + stickerImg.height);
            // this.$forceUpdate(); 
        },
    },
    created(){
        // console.log("this.VW: " + this.VW);
        // console.log("for lo! STICKERS ARE COMING!!!");
    },
    mounted(){
        this.resizeImg();
    },
}
</script>