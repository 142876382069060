<template>
    <!-- showGetTipsMoreInfo -->
    <div id="topdiv" class="modal-overlay-canscroll" style="position: absolute; top: 10px; width: 100%; outline:0.0em solid pink; ">
        <div class="modal" style="outline:0.0em solid red; padding-top: 5vh; padding-bottom: 5vh; "> 

        <table width=100% border=0 style="outline:0.0em solid blue; padding-left: 2.5vh; padding-right: 2.5vh; ">
            <tr>
                <td>
                    <table width="100%" border=0>
                        <tr>
                            <td width="30%">
                                <div class=divl><button class="text-only-button cancel" @click="this.toggleAll" style="display: block; line-height: 15px;">{{ toggleButtonText }}</button></div>
                            </td>
                            <td width="40%">
                            </td>
                            <td width="30%">
                            <div class=divr>
                            
                                <button @click="this.goBack()" class="text-only-button cancel"> <img class="tulogo" style="height: 4vh;" alt="close" src="../assets/greycross.png"></button>
                            </div>
                            </td>

                        </tr>
                    </table>
                    
                </td>
            </tr>
             <tr>
                <td style="padding-top: 0.5vh; padding-bottom: 0.5vh">              
                    <div class="actionbtn-div" style="padding-top: 1vh;">
                        <button @click="this.showHowGetConnectedInfo=!this.showHowGetConnectedInfo" class="actionbtn" style="width: 100%; height: 6.5vh; font-size: 2vh; " :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }" >How do I get connected to the ThankU QR badges at my salon?
                        </button>
                    </div>
                    <div v-show="showHowGetConnectedInfo" class="actionbtn-div notesInfoText" style="" :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">
                        If you already have ThankU at the salon you work at, getting tips is simple. Just scan any of the ThankU QR badges at the salon and follow the simple instructions to choose a username and password, then connect to your bank account - if you have your bank account name, account number and sort code to hand before you start, it will take you less than 1 minute.
                    </div>
                </td>
            </tr>
            <tr>
                <td style="padding-top: 0.5vh; padding-bottom: 0.5vh">              
                    <div class="actionbtn-div" style="padding-top: 1vh;">
                        <button @click="this.showGetBadges=!this.showGetBadges" class="actionbtn" style="width: 100%; height: 6.5vh; font-size: 2vh; " :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">{{ this.howDoIGetHoldOfBadges }}
                        </button>
                    </div>
                    <div v-show="showGetBadges" class="actionbtn-div notesInfoText" style="" :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">
                        We physically mail you ThankU badges at no cost - you just need to tell us your requirements and where to mail them. Simply just email us at <a href="mailto:mailorders@thanku.app?subject=get ThankU enquiry&amp;body=Please fill out below - we will email you back to confirm your order and let you know if we need any further information before dispatching it - please respond below:%0D%0A%0D%0AWhat is your business name?%0D%0A%0D%0AAre you a salon? If not, what type of business please?                  %0D%0A%0D%0AWould you like our 'No Glue' Mirror Stickers option in addition to your Front Desk Stand? If so, how many stickers would you like? (if a salon, we suggest one for every client seat mirror)    %0D%0A%0D%0AOr - instead - would like to receive just our super discreet Tabletop Stand? and if yes, how many would you like?                  %0D%0A%0D%0AIf you are not a salon and would like different QR media (e.g. wearables), let us know and we will email you to ask a bit more about your business..                  %0D%0A%0D%0AWhich address should send we send your ThankU QR media to?                  %0D%0A%0D%0A%0D%0AThank you! best wishes, the ThankU Team." class="text-only-button cancel" :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }" >hello@thanku.app</a>. Please use the questions auto-completed in the email to tell us a little about your business so we can send you the best ThankU QR badge media for you - for example, if you own or manage a hair salon, how many stylist seats you have.. Please also supply the address we should dispatch your ThankU media to.
                   
                    </div>
                </td>
            </tr>
              <tr>
                <td style="padding-top: 0.5vh; padding-bottom: 0.5vh">              
                    <div class="actionbtn-div" style="padding-top: 1vh;">
                        <button @click="this.showHowGetConnected2SalonsInfo=!this.showHowGetConnected2SalonsInfo" class="actionbtn" style="width: 100%; height: 6.5vh; font-size: 2vh; " :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }" >I work at more than one salon, what do I do?
                        </button>
                    </div>
                    <div v-show="showHowGetConnected2SalonsInfo" class="actionbtn-div notesInfoText" style="" :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">
                        If you work at more than one salon and already are connected to ThankU at one salon, just scan the ThankU QR code at the new salon and tap the 'get tips' here button, as you did the first time, and you will immediately then be connected to both salons.
                    </div>
                </td>
            </tr>
            <tr>
                <td style="padding-top: 0.5vh; padding-bottom: 0.5vh">              
                    <div class="actionbtn-div" style="padding-top: 1vh;">
                        <button @click="this.showWhatDetailsInfo=!this.showWhatDetailsInfo" class="actionbtn" style="width: 100%; height: 6.5vh; font-size: 2vh; " :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }" >{{ this.whatDetails }}
                        </button>
                    </div>
                    <div v-show="showWhatDetailsInfo" class="actionbtn-div notesInfoText" style="" :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">
                        To receive tips through ThankU, our set up process will ask you for your email address, to choose a password, and to choose a display name. Only your display name is shown to tippers, and most people choose their first name.<p></p>In order for ThankU to be able to pay you directly, you will then need to provide your bank account name, sort code (UK) or routing number (US) and account number. These are stored securely by by ThankU and Stripe, our payment processor.
                    </div>
                </td>
            </tr>
            <tr>
                <td style="padding-top: 0.5vh; padding-bottom: 0.5vh">              
                    <div class="actionbtn-div" style="padding-top: 1vh;">
                        <button @click="this.showSecureInfo=!this.showSecureInfo" class="actionbtn" style="width: 100%; height: 6.5vh; font-size: 2vh; " :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }"> {{ this.isGettingTipsSecure }}
                        </button>
                    </div>
                    <div v-show="showSecureInfo" class="actionbtn-div notesInfoText" style="" :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">
                       Yes - ThankU uses Stripe as its payment processor. Stripe processes about 15% of all transactions on the internet, and likely rather more in payment-enabled phone apps. Stripe processes payments for millions of business including Amazon, Deliveroo, Booking.com and ASOS - <a href='https://stripe.com/en-gb/customers' target=blank>see some Stripe customers</a> <p></p><p></p>In order for ThankU to be able to pay you directly, you provide your bank account details (bank account name, sort code (UK) or routing number (US) and account number) at the beginning when you are setting up your account. These details are stored securely by ThankU and Stripe, our payment processor.
                    </div>
                </td>
            </tr>
            <tr id="feessection" ref="feessection">
                <td style="padding-top: 0.5vh; padding-bottom: 0.5vh">              
                    <div class="actionbtn-div" style="padding-top: 1vh;">
                        <button @click="this.showFees=!this.showFees" class="actionbtn" style="width: 100%; height: 6.5vh; font-size: 2vh; " :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">{{ this.whatAreTheFees }}
                        </button>
                    </div>
                    <div v-show="showFees" class="actionbtn-div notesInfoText" style="" :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">
                       Fees for using ThankU are deducted from tips unless the person tipping chooses to add them onto their tip, which they quite often do. Most salons now choose for the client to pay the transaction fees by default unless the client chooses not to, and clients pay those fees c. 90% of the time. <p></p>Every tip notification you receive provides full details of any fees deducted from the tip (if any) for full transparency.<p></p>Fees are made up of a 3% fee to ThankU for providing the service, and payment processing fees charged by Stripe Inc., thankU’s payment processor.<p></p>Additionally, there is a monthly Stripe fee, currently £2.30, deducted from the first tip of £5 or greater each month - this is always a deduction; the client does not pay it.<p></p>Detailed transaction information is provided in every tip receipt email, in your Transactions History and in your tip history email reports.<p></p>Stripe processes payments for millions of business including Amazon, Deliveroo, Booking.com and ASOS - <a href="https://stripe.com/en-gb/customers" target=blank>see some Stripe customers.</a><p></p>Stripe’s fees are published at <a href="https://stripe.com/gb/pricing" target=blank>https://stripe.com/gb/pricing</a>.<p></p>The table below sets out illustrative fee calculations for different tip amounts, together with full information on fees:
                    </div>
                    <div v-show="showFees" class="actionbtn-div notesInfoText" style="">
                        <a v-bind:href="recipientfees" target=blank><img class="tulogo" style="width: 95%; height: auto" alt="ThankU fees" v-bind:src="recipientfees"></a>
                    </div>
                    <div v-show="showFees" class="actionbtn-div notesInfoText" style="">
                        <a v-bind:href="recipientfees2" target=blank><img class="tulogo" style="width: 95%; height: auto" alt="ThankU fees" v-bind:src="recipientfees2"></a>
                    </div>
                    <div v-show="showFees" class="actionbtn-div notesInfoText" style="" :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">                    
                       ThankU reserves the right to alter the above fees upon providing you at least 7 days notice before any fee amendments come into effect, though please rest assured it is in our best interests to make the ThankU service as cost effective as it possibly can be for you.
                    </div>
                </td>
            </tr>
            <tr>
                <td style="padding-top: 0.5vh; padding-bottom: 0.5vh">              
                    <div class="actionbtn-div" style="padding-top: 1vh;">
                        <button @click="this.showWhenPaid=!this.showWhenPaid;" class="actionbtn" style="width: 100%; height: 6.5vh; font-size: 2vh; " :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">{{ this.whenDoIGetPaid}}</button>
                    </div>
                    <div v-show="showWhenPaid" class="actionbtn-div notesInfoText" style="" :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">
                        ThankU uses Stripe as it's payment processor - Stripe handles a huge propoertion of website and app payments. You will receive your payments 3 business days following the tip in the UK and after 2 business days in the USA.
                    </div>
                </td>
            </tr>
            <tr>
                <td style="padding-top: 0.5vh; padding-bottom: 0.5vh">              
                    <div class="actionbtn-div" style="padding-top: 1vh;">
                        <button @click="this.showGetReceipt=!this.showGetReceipt" class="actionbtn" style="width: 100%; height: 6.5vh; font-size: 2vh; " :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">{{ this.doIGetNotification }}
                        </button>
                    </div>
                    <div v-show="showGetReceipt" class="actionbtn-div notesInfoText" style="" :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">
                        Yes - every time you receive a tip you will receive an email with the details of who tipped you, how much, full details of any fees deducted, and when the funds will be transferred to you.<p></p>You will also receive an email every time ThankU sends funds to your bank account, making it easy for you to see the funds being paid in in your banking app for example.<p></p>Please note that if the person tipping does not supply a display name (typically their first name), then your tip will display "name not given".
                    </div>
                </td>
            </tr>
            <tr>
                <td style="padding-top: 0.5vh; padding-bottom: 0.5vh">              
                    <div class="actionbtn-div" style="padding-top: 1vh;">
                        <button @click="this.showGetTipHistory=!this.showGetTipHistory;" class="actionbtn" :style="{ 'width': '100%', 'height': '7.5vh', 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">{{ this.canIGetHistory }}
                        </button>
                    </div>
                    <div v-show="showGetTipHistory" class="actionbtn-div notesInfoText" style="" >
                        Yes you can - click on the account icon to go into your account page and select Transaction History. The next screen will list all the tips you have received, simply tap on any tip to see further details.<p></p>On the same screen you can request various transaction history reports to be emailed to you.
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <table width="100%" border=0>
                        <tr>
                            <td width="1%">
                               &nbsp;
                            </td>
                            <td width="98%" class="divc">
                                 <div class="divc" style="padding-top: 1.5vh">
                                     <a href="mailto:hello@thanku.app?subject=ThankU enquiry from giving tips screen&amp;body=How can we help?" class="text-only-button cancel" >Email us your question</a>
                                     <!-- <button class="text-only-button cancel" @click="emailThankU" >Email us your question</button> -->
                                     </div>
                            </td>
                            <td width="1%">
                                <div class=divr>
                                </div>
                            </td>

                        </tr>
                    </table>
                    
                </td>
            </tr>
        </table>       
      </div>
    </div>
</template>

<script>

import shared from '../shared.js';
import recipientfees from '../assets/recipientfees.png';
import recipientfees2 from '../assets/recipientfees2.png';


export default {
    inject: ['globalPushForwardInterval', 'devEnv', 'appId', 'appJSKey'] ,
    // components:{
    //     recipientfees,
    //     recipientfees2,
    // },
    data() {
        return {

            showHowGetConnectedInfo: false,
            showHowGetConnected2SalonsInfo: false,
            showWhatDetailsInfo: false,
            showSecureInfo: false,
            showGetBadges: false,
            showFees: false,
            showWhenPaid: false,
            showGetReceipt: false,
            showGetTipHistory: false,
            toggleButtonText: "Expand All",

            fontSizeNormal: 2.5, // vh
            fontSizeNormalInPXToUse: "",
            fontSizeNormalLineHeight: 3.3,// vh
            fontSizeNormalInPXLineHeightToUse: "",
            nhbfmembers: false,

            whatDetails: "What details do I need to provide thankU?",
            isGettingTipsSecure: "Is getting tips with ThankU secure?",
            howDoIGetHoldOfBadges: "How do I get hold of ThankU QR badges?",
            whatAreTheFees: "What are the fees for using thankU?",
            whenDoIGetPaid: "When do I get paid?",
            doIGetNotification: "Do I get a notification from ThankU when I receive a tip?",
            canIGetHistory: "Can I get a history of the tips I've received?",

            recipientfees: recipientfees,
            recipientfees2: recipientfees2,


        }
    },
    methods: {
        doFontSizeComputed () {
        
        this.fontSizeNormalInPXToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormal, 16);
        this.fontSizeNormalInPXLineHeightToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormalLineHeight, 20);
        
        },
        toggleAll(){

            // /console.log("toggling...");

            var status = false;

            if (this.toggleButtonText === "Expand All") {
                this.toggleButtonText = "Collapse All";
                status = true;
            } else {
                this.toggleButtonText = "Expand All";
                status = false;
            }

            this.showHowGetConnectedInfo = status;
            this.showHowGetConnected2SalonsInfo = status;
            this.showWhatDetailsInfo = status;
            this.showSecureInfo = status;
            this.showGetBadges = status;
            this.showFees = status;
            this.showWhenPaid = status;
            this.showGetReceipt = status;
            this.showGetTipHistory = status;
        },
        goBack(){
            // /console.log("we're off!");
            if (window.localStorage.getItem("nextScreen") !== null ){
                let returnJSON = { nextScreen: window.localStorage.getItem("nextScreen")};
                this.$emit('return-json', returnJSON);    
                window.localStorage.removeItem("nextScreen");
            } else {
                let returnJSON = { showHome: true }; 
                this.$emit('return-json', returnJSON);
            }
        },
        doSetMetas(){

            document.title = "All your questions answered on getting tips with ThankU Cashless Tipping, used at top salons such as Larry King and Josh Wood Colour. ";
            document.getElementsByTagName('meta').namedItem('description').setAttribute('content',
            'All your questions answered on getting tips with ThankU Cashless Tipping, used at top salons such as Larry King and Josh Wood Colour. Questions like: ' + this.whatDetails + " " + this.isGettingTipsSecure + " " + this.howDoIGetHoldOfBadges + " " + this.whatAreTheFees + " " + this.whenDoIGetPaid + " " + this.doIGetNotification + " " + this.canIGetHistory + " ") ;
            
        }
    },
    created(){
        document.body.style.maxWidth = "100vw"; // for Google mobile crawler had to limit homepage width on body tag, this resets it
        this.doSetMetas();
        shared.saveToUserPath(this.devEnv, "Get tips more info " + new Date().toISOString(), this.globalPushForwardInterval);
        this.doFontSizeComputed();
        //window.addEventListener('resize', this.doFontSizeComputed); didn't want to do in this instance as the heigh appears to change as soon as you start scolling, the initial setting is good enough for now
        
    },
    mounted(){
        this.showFees = this.$route.params !== undefined ? (this.$route.params.feesShow !== undefined ? true : false) : false;

        if(this.showFees === true){
            setTimeout(function() {document.querySelector("#feessection").scrollIntoView({ behavior: 'smooth', block: 'start'})}, 200); // hacky
        }

        // this.$nextTick(() => {
        //     let feesSectionEl = document.querySelector("#feessection");
        // if (showFees === true){
        //     feesSectionEl.scrollIntoView({
        //         behavior: 'smooth', // smooth scroll
        //         block: 'start' // the upper border of the element will be aligned at the top of the visible part of the window of the scrollable area.
        //     });
        //     this.showFees = showFees;
        // }
          
        // });
        
    },
}
</script>