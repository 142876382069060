<template>
    <div class="mediaSelectDesktop">
    <!-- Bootstrap, though loaded first, was overriding some unknown style element, hard to solve out, which was sending the login page to top right. Rather than waste a long time trying to find out which, I am only applying Bootstrap to pages which use it -->
    <link
      rel="stylesheet"
      href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css"
      integrity="sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH"
      crossorigin="anonymous"
    />

    <div
      class="container col-12 offset-0 col-md-6 offset-md-3 tugradient1"
      style="padding-top: 5vh; padding-bottom: 5vh: outline:0.01em dashed red"
    >
      <table
        width="100%"
        border="0"
        style="
          margin: auto;
          outline: 0em solid blue;
          padding-left: 2.5vh;
          padding-right: 2.5vh;
        "
      >
        <tr>
          <td>
            <table width="100%" border="0">
              <tr>
                <td width="30%">
                  <div class="divl">&nbsp;</div>
                </td>
                <td width="40%">
                  <a
                    class="navbar-brand"
                    style="outline: 0em dashed blue"
                    href="#"
                    ><img
                      class="tunavbarlogoheight"
                      alt="ThankU logo"
                      src="../assets/tutxtlogomd.webp"
                  /></a>
                </td>
                <td width="30%">
                  <div class="divr">
                    <button
                      @click="this.doGoBack()"
                      class="text-only-button cancel"
                    >
                      <img
                        class="tulogo"
                        style="height: 4vh"
                        alt="close"
                        src="../assets/greycross.png"
                      />
                    </button>
                  </div>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      <div>
        <MoreInfoItem
          :ListItemString1="this.howGetConnected"
          :ListItemString2="this.howGetConnectedInfo"
        />
        <MoreInfoItem
          :ListItemString1="this.howDoIGetHoldOfBadges"
          :ListItemString2="this.howDoIGetHoldOfBadgesInfo"
        />
        <MoreInfoItem
          :ListItemString1="this.howGetConnected2Salons"
          :ListItemString2="this.howGetConnected2SalonsInfo"
        />
        <MoreInfoItem
          :ListItemString1="this.whatDetails"
          :ListItemString2="this.whatDetailsInfo"
        />
        <MoreInfoItem
          :ListItemString1="this.isGettingTipsSecure"
          :ListItemString2="this.isGettingTipsSecureInfo"
        />
        <div  id="feessectiondesktop" ref="feessectiondesktop" class="row col-12 col-md-12 offset-md-0 tufont tutablepadding text-start tusmallerfontsize tublue tuwhiteboxlist" style="outline:0.0em dashed red; height: auto; float: left; margin-top: 1.5vw;">
            <span class="col-1 offset-2 col-md-4 offset-md-0 tufont tublue;" style="font-weight: 600; outline:0.00em dashed green;" >{{ this.whatAreTheFees }}</span>
            <span class="col-8 col-md-8" style="outline:0.00em dashed blue;" v-html="this.whatAreTheFeesInfo"></span>
            <span class="col-1 offset-2 col-md-4 offset-md-0 tufont tublue;" style="font-weight: 600; outline:0.00em dashed green;" >&nbsp;</span>
            <div class="col-8 col-md-8" style="outline:0.00em dashed blue;">
            <div class="actionbtn-div turegularfontsize tufont" style="">
              <a v-bind:href="recipientfees" target="blank"
                ><img
                  class="tulogo"
                  style="width: 75%; height: auto"
                  alt="ThankU fees"
                  v-bind:src="recipientfees"
              /></a>
            </div>
            <div class="actionbtn-div turegularfontsize tufont" style="">
              <a v-bind:href="recipientfees2" target="blank"
                ><img
                  class="tulogo"
                  style="width: 75%; height: auto"
                  alt="ThankU fees"
                  v-bind:src="recipientfees2"
              /></a>
            </div>
            </div>
            <p></p><p></p>
            <span class="col-1 offset-2 col-md-4 offset-md-0 tufont tublue;" style="font-weight: 600; outline:0.00em dashed green;" >&nbsp;</span>
            <div class="col-8 col-md-8" style="outline:0.00em dashed blue;" v-html="this.whatAreTheFeesInfoPt2"></div>
        </div>
    
        <MoreInfoItem
          :ListItemString1="this.whenDoIGetPaid"
          :ListItemString2="this.whenDoIGetPaidInfo"
        />
        <MoreInfoItem
          :ListItemString1="this.doIGetNotification"
          :ListItemString2="this.doIGetNotificationInfo"
        />
        <MoreInfoItem
          :ListItemString1="this.canIGetHistory"
          :ListItemString2="this.canIGetHistoryInfo"
        />
      </div>
      <table width="100%" border="0" style="padding-bottom: 7vw">
        <tr>
          <td width="100%" class="divc">
            <div class="divc" style="padding-top: 2vw; padding-bottom: 1vw">
              <a
                href="mailto:hello@thanku.app?subject=ThankU enquiry from getting tips screen&amp;body=How can we help?"
                class="text-only-button cancel"
                >Email us your question</a
              >
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="actionbtn-div" style="padding-top: 1vh">
              <button
                class="widebtn"
                style="width: 20%; height: 6vh"
                @click="this.doGoBack()"
              >
                Ok
              </button>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <!-- showGetTipsMoreInfo -->
  <div
    id="topdiv"
    class="modal-overlay-canscroll mediaSelectPhone"
    style="position: absolute; top: 10px; width: 100%; outline: 0em solid pink"
  >
    <div
      class="modalnotbootstrap mediaSelectPhone"
      style="
        outline: 0em solid red;
        padding-top: 5vh;
        padding-bottom: 5vh;
        padding-left: 5vw;
        padding-right: 5vw;
      "
    >
      <table
        width="100%"
        border="0"
        style="
          outline: 0em solid blue;
          padding-left: 2.5vh;
          padding-right: 2.5vh;
        "
      >
        <tr>
          <td>
            <table width="100%" border="0">
              <tr>
                <td width="40%">
                  <div class="divl">
                    <button
                      class="text-only-button cancel"
                      @click="this.toggleAll"
                      style="display: block; line-height: 15px"
                    >
                      {{ toggleButtonText }}
                    </button>
                  </div>
                </td>
                <td width="30%"></td>
                <td width="30%">
                  <div class="divr">
                    <button
                      @click="this.doGoBack()"
                      class="text-only-button cancel"
                    >
                      <img
                        class="tulogo"
                        style="height: 4vh"
                        alt="close"
                        src="../assets/greycross.png"
                      />
                    </button>
                  </div>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td style="padding-top: 0.5vh; padding-bottom: 0.5vh">
            <div class="actionbtn-div" style="padding-top: 1vh">
              <button
                @click="
                  this.showHowGetConnectedInfo = !this.showHowGetConnectedInfo
                "
                class="infobtn turegularfontsize tufont"
                style="width: 100%; height: 9vh"
               
              >
                {{ this.howGetConnected }}
              </button>
            </div>
            <div
              v-show="showHowGetConnectedInfo"
              class="actionbtn-div turegularfontsize tufont"
              v-html="this.howGetConnectedInfo"
              
            >
              
            </div>
          </td>
        </tr>
        <tr>
          <td style="padding-top: 0.5vh; padding-bottom: 0.5vh">
            <div class="actionbtn-div" style="padding-top: 1vh">
              <button
                @click="this.showGetBadges = !this.showGetBadges"
                class="infobtn turegularfontsize tufont"
                style="width: 100%; height: 9vh"
               
              >
                {{ this.howDoIGetHoldOfBadges }}
              </button>
            </div>
            <div
              v-show="showGetBadges"
              class="actionbtn-div turegularfontsize tufont"
              v-html="this.howDoIGetHoldOfBadgesInfo"
              
            >
              
            </div>
          </td>
        </tr>
        <tr>
          <td style="padding-top: 0.5vh; padding-bottom: 0.5vh">
            <div class="actionbtn-div" style="padding-top: 1vh">
              <button
                @click="
                  this.showHowGetConnected2SalonsInfo =
                    !this.showHowGetConnected2SalonsInfo
                "
                class="infobtn turegularfontsize tufont"
                style="width: 100%; height: 9vh"
               
              >
                {{ this.howGetConnected2Salons }}
              </button>
            </div>
            <div
              v-show="showHowGetConnected2SalonsInfo"
              class="actionbtn-div turegularfontsize tufont"
              v-html="this.howGetConnected2SalonsInfo"
              
            >
              
            </div>
          </td>
        </tr>
        <tr>
          <td style="padding-top: 0.5vh; padding-bottom: 0.5vh">
            <div class="actionbtn-div" style="padding-top: 1vh">
              <button
                @click="this.showWhatDetailsInfo = !this.showWhatDetailsInfo"
                class="infobtn turegularfontsize tufont"
                style="width: 100%; height: 9vh"
               
              >
                {{ this.whatDetails }}
              </button>
            </div>
            <div
              v-show="showWhatDetailsInfo"
              class="actionbtn-div turegularfontsize tufont"
              v-html="this.whatDetailsInfo"
              
            >
              
            </div>
          </td>
        </tr>
        <tr>
          <td style="padding-top: 0.5vh; padding-bottom: 0.5vh">
            <div class="actionbtn-div" style="padding-top: 1vh">
              <button
                @click="this.showSecureInfo = !this.showSecureInfo"
                class="infobtn turegularfontsize tufont"
                style="width: 100%; height: 9vh"
               
              >
                {{ this.isGettingTipsSecure }}
              </button>
            </div>
            <div
              v-show="showSecureInfo"
              class="actionbtn-div turegularfontsize tufont"
              v-html="this.isGettingTipsSecureInfo"
              
            >
              
            </div>
          </td>
        </tr>
        <tr id="feessectionphone" ref="feessectionphone">
          <td style="padding-top: 0.5vh; padding-bottom: 0.5vh">
            <div class="actionbtn-div" style="padding-top: 1vh">
              <button
                @click="this.showFees = !this.showFees"
                class="infobtn turegularfontsize tufont"
                style="width: 100%; height: 9vh"
               
              >
                {{ this.whatAreTheFees }}
              </button>
            </div>
            <div
              v-show="showFees"
              class="actionbtn-div turegularfontsize tufont"
              v-html="this.whatAreTheFeesInfo"
              
            >
            </div>
            <div v-show="showFees" class="actionbtn-div turegularfontsize tufont" style="">
              <a v-bind:href="recipientfees" target="blank"
                ><img
                  class="tulogo"
                  style="width: 95%; height: auto"
                  alt="ThankU fees"
                  v-bind:src="recipientfees"
              /></a>
            </div>
            <div v-show="showFees" class="actionbtn-div turegularfontsize tufont" style="">
              <a v-bind:href="recipientfees2" target="blank"
                ><img
                  class="tulogo"
                  style="width: 95%; height: auto"
                  alt="ThankU fees"
                  v-bind:src="recipientfees2"
              /></a>
            </div>
            <div
              v-show="showFees"
              class="actionbtn-div turegularfontsize tufont"
              v-html="this.whatAreTheFeesInfoPt2"
              
            >
              
            </div>
          </td>
        </tr>
        <tr>
          <td style="padding-top: 0.5vh; padding-bottom: 0.5vh">
            <div class="actionbtn-div" style="padding-top: 1vh">
              <button
                @click="this.showWhenPaid = !this.showWhenPaid"
                class="infobtn turegularfontsize tufont"
                style="width: 100%; height: 9vh"
               
              >
                {{ this.whenDoIGetPaid }}
              </button>
            </div>
            <div
              v-show="showWhenPaid"
              class="actionbtn-div turegularfontsize tufont"
              v-html="this.whenDoIGetPaidInfo"
              
            >
              
            </div>
          </td>
        </tr>
        <tr>
          <td style="padding-top: 0.5vh; padding-bottom: 0.5vh">
            <div class="actionbtn-div" style="padding-top: 1vh">
              <button
                @click="this.showGetReceipt = !this.showGetReceipt"
                class="infobtn turegularfontsize tufont"
                style="width: 100%; height: 9vh"
               
              >
                {{ this.doIGetNotification }}
              </button>
            </div>
            <div
              v-show="this.showGetReceipt"
              class="actionbtn-div turegularfontsize tufont"
              v-html="this.doIGetNotificationInfo"
              
            >
              
            </div>
          </td>
        </tr>
        <tr>
          <td style="padding-top: 0.5vh; padding-bottom: 0.5vh">
            <div class="actionbtn-div" style="padding-top: 1vh">
              <button
                @click="this.showGetTipHistory = !this.showGetTipHistory"
                class="infobtn turegularfontsize tufont"
                :style="{
                  width: '100%',
                  height: '7.5vh',
                  'font-size': fontSizeNormalInPXToUse + 'px',
                  'line-height': fontSizeNormalInPXLineHeightToUse + 'px',
                }"
              >
                {{ this.canIGetHistory }}
              </button>
            </div>
            <div
              v-show="showGetTipHistory"
              class="actionbtn-div turegularfontsize tufont"
              v-html="this.canIGetHistoryInfo"
              
            >
              
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <table width="100%" border="0">
              <tr>
                <td width="100%" class="divc">
                  <div class="divc" style="padding-top: 1.5vh">
                    <a
                      href="mailto:hello@thanku.app?subject=ThankU enquiry from getting tips screen&amp;body=How can we help?"
                      class="text-only-button cancel"
                      >Email us your question</a
                    >
                    <!-- <button class="text-only-button cancel" @click="emailThankU" >Email us your question</button> -->
                  </div>
                </td>
              
              </tr>
              <tr>
                <td>
                  <div class="actionbtn-div" style="padding-top: 2vh">
                    <button
                      class="widebtn"
                      style="width: 20%; height: 6vh"
                      @click="this.doGoBack()"
                    >
                      Ok
                    </button>
                  </div>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

import shared from "../shared.js";

const MoreInfoItem = defineAsyncComponent({
  loader: () => import("../components/MoreInfoItem.vue"),
});

import recipientfees from "../assets/recipientfees.png";
import recipientfees2 from "../assets/recipientfees2.png";

export default {
  inject: ["globalPushForwardInterval", "devEnv", "appId", "appJSKey"],
  // components:{
  //     recipientfees,
  //     recipientfees2,
  // },
  data() {
    return {
      showHowGetConnectedInfo: false,
      showHowGetConnected2SalonsInfo: false,
      showWhatDetailsInfo: false,
      showSecureInfo: false,
      showGetBadges: false,
      showFees: false,
      showWhenPaid: false,
      showGetReceipt: false,
      showGetTipHistory: false,
      toggleButtonText: "Expand All",

      fontSizeNormal: 2.5, // vh
      fontSizeNormalInPXToUse: "",
      fontSizeNormalLineHeight: 3.3, // vh
      fontSizeNormalInPXLineHeightToUse: "",
      nhbfmembers: false,
      howGetConnected: "How do I get connected to the ThankU QR badges at my salon?",
      howGetConnectedInfo: "If you already have ThankU at the salon you work at, getting tips is simple. Just scan any of the ThankU QR badges at the salon and follow the simple instructions to choose a username and password, then connect to your bank account - if you have your bank account name, account number and sort code to hand before you start, it will take you less than 1 minute.",
      howDoIGetHoldOfBadges: "How do I get hold of ThankU QR badges?",
      howDoIGetHoldOfBadgesInfo: "We physically mail you ThankU badges at no cost - you just need to tell us your requirements and where to mail them. Simply just email us at <a href='mailto:mailorders@thanku.app?subject=get ThankU enquiry&amp;body=Please fill out below - we will email you back to confirm your order and let you know if we need any further information before dispatching it - please respond below:%0D%0A%0D%0AWhat is your business name?%0D%0A%0D%0AAre you a salon? If not, what type of business please?                  %0D%0A%0D%0AWould you like our 'No Glue' Mirror Stickers option in addition to your Front Desk Stand? If so, how many stickers would you like? (if a salon, we suggest one for every client seat mirror)    %0D%0A%0D%0AOr - instead - would like to receive just our super discreet Tabletop Stand? and if yes, how many would you like?                  %0D%0A%0D%0AIf you are not a salon and would like different QR media (e.g. wearables), let us know and we will email you to ask a bit more about your business..                  %0D%0A%0D%0AWhich address should send we send your ThankU QR media to?                  %0D%0A%0D%0A%0D%0AThank you! best wishes, the ThankU Team.' class='text-only-button cancel' :style='{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }' >hello@thanku.app</a >. Please use the questions auto-completed in the email to tell us a little about your business so we can send you the best ThankU QR badge media for you - for example, if you own or manage a hair salon, how many stylist seats you have.. Please also supply the address we should dispatch your ThankU media to.",
      howGetConnected2Salons: "I work at more than one salon, what do I do?",
      howGetConnected2SalonsInfo: "If you work at more than one salon and already are connected to ThankU at one salon, just scan the ThankU QR code at the new salon and tap the 'get tips' here button, as you did the first time, and you will immediately then be connected to both salons.",
      whatDetails: "What details do I need to provide thankU?",
      whatDetailsInfo: "To receive tips through ThankU, our set up process will ask you for your email address, to choose a password, and to choose a display name. Only your display name is shown to tippers, and most people choose their first name.<p></p>In order for ThankU to be able to pay you directly, you will then need to provide your bank account name, sort code (UK) or routing number (US) and account number. These are stored securely by ThankU and Stripe, our payment processor.",
      isGettingTipsSecure: "Is getting tips with ThankU secure?",
      isGettingTipsSecureInfo: "Yes - ThankU uses Stripe as its payment processor. Stripe processes about 15% of all transactions on the internet, and likely rather more in payment-enabled phone apps. Stripe processes payments for millions of business including Amazon, Deliveroo, Booking.com and ASOS - <a href='https://stripe.com/en-gb/customers'  target='blank'>see some Stripe customers</a><p></p><p></p>In order for ThankU to be able to pay you directly, you provide your bank account details (bank account name, sort code (UK) or routing number (US) and account number) at the beginning when you are setting up your account. These details are stored securely by ThankU and Stripe, our payment processor.",
      whatAreTheFees: "What are the fees for using thankU?",
      whatAreTheFeesInfo: "Fees for using ThankU are deducted from tips unless the person tipping chooses to add them onto their tip, which they quite often do. Most salons now choose for the client to pay the transaction fees by default unless the client chooses not to, and clients pay those fees c. 90% of the time.<p></p>Every tip notification you receive provides full details of any fees deducted from the tip (if any) for full transparency.<p></p>Fees are made up of a 3% fee to ThankU for providing the service, and payment processing fees charged by Stripe Inc., thankU’s payment processor.<p></p>Additionally, there is a monthly Stripe fee, currently £2.30, deducted from the first tip of £5 or greater each month - this is always a deduction; the client does not pay it.<p></p>Detailed transaction information is provided in every tip receiptemail, in your Transactions History and in your tip history email reports.<p></p>Stripe processes payments for millions of business including Amazon, Deliveroo, Booking.com and ASOS - <a href='https://stripe.com/en-gb/customers' target='blank'>see some Stripe customers.</a><p></p>Stripe’s fees are published at <a href='https://stripe.com/gb/pricing' target='blank'>https://stripe.com/gb/pricing</a>.<p></p>The table below sets out illustrative fee calculations for different tip amounts, together with full information on fees: ",
      whatAreTheFeesInfoPt2: "ThankU reserves the right to alter the above fees upon providing you at least 7 days notice before any fee amendments come into effect, though please rest assured it is in our best interests to make the ThankU service as cost effective as it possibly can be for you.",
      whenDoIGetPaid: "When do I get paid?",
      whenDoIGetPaidInfo: "ThankU uses Stripe as it's payment processor - Stripe handles a huge propoertion of website and app payments. You will receive your payments 3 business days following the tip in the UK and after 2 business days in the USA.", 
      doIGetNotification: "Do I get a notification from ThankU when I receive a tip?",
      doIGetNotificationInfo: "Yes - every time you receive a tip you will receive an email with the details of who tipped you, how much, full details of any fees deducted, and when the funds will be transferred to you. <p></p>You will also receive an email every time ThankU sends funds to your bank account, making it easy for you to see the funds being paid in in your banking app for example. <p></p>Please note that if the person tipping does not supply a display name (typically their first name), then your tip will display 'name not given'.",
      canIGetHistory: "Can I get a history of the tips I've received?",
      canIGetHistoryInfo: "Yes you can - click on the account icon to go into your account page and select Transaction History. The next screen will list all the tips you have received, simply tap on any tip to see further details. <p></p>On the same screen you can request various transaction history reports to be emailed to you.",
      recipientfees: recipientfees,
      recipientfees2: recipientfees2,
      fromScreen: undefined,
    };
  },
  components: {
    MoreInfoItem,
  },
  methods: {
    doFontSizeComputed() {
      this.fontSizeNormalInPXToUse = shared.convertVHSizeToMaxPixelsSize(
        this.fontSizeNormal,
        16
      );
      this.fontSizeNormalInPXLineHeightToUse =
        shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormalLineHeight, 20);
    },
    toggleAll() {
      // /console.log("toggling...");

      var status = false;

      if (this.toggleButtonText === "Expand All") {
        this.toggleButtonText = "Collapse All";
        status = true;
      } else {
        this.toggleButtonText = "Expand All";
        status = false;
      }

      this.showHowGetConnectedInfo = status;
      this.showHowGetConnected2SalonsInfo = status;
      this.showWhatDetailsInfo = status;
      this.showSecureInfo = status;
      this.showGetBadges = status;
      this.showFees = status;
      this.showWhenPaid = status;
      this.showGetReceipt = status;
      this.showGetTipHistory = status;
    },
    doGoBack(){
      console.log("this.fromScreen: " + this.fromScreen);
      if(this.fromScreen !== undefined) {
        let returnJSON = { [this.fromScreen]: true };
        console.log(JSON.stringify(returnJSON, null, 2));
        this.$emit("return-json", returnJSON);
      } else  {
        let returnJSON = { showHomeNew: true };
        this.$emit("return-json", returnJSON);
      }
    },
    // goBack() {
    //   // /console.log("we're off!");
    //   if (window.localStorage.getItem("nextScreen") !== null) {
    //     console.log("off to: " + window.localStorage.getItem("nextScreen"));
    //     let returnJSON = {
    //       nextScreen: window.localStorage.getItem("nextScreen"),
    //     };
    //     this.$emit("return-json", returnJSON);
    //     window.localStorage.removeItem("nextScreen");
    //   } else {
    //     console.log("off home!");
    //     let returnJSON = { showHomeNew: true };
    //     this.$emit("return-json", returnJSON);
    //   }
    // },
    doSetMetas() {
      document.title =
        "All your questions answered on getting tips with ThankU Cashless Tipping, used at top salons such as Larry King and Josh Wood Colour. ";
      document
        .getElementsByTagName("meta")
        .namedItem("description")
        .setAttribute(
          "content",
          "All your questions answered on getting tips with ThankU Cashless Tipping, used at top salons such as Larry King and Josh Wood Colour. Questions like: " +
            this.whatDetails +
            " " +
            this.isGettingTipsSecure +
            " " +
            this.howDoIGetHoldOfBadges +
            " " +
            this.whatAreTheFees +
            " " +
            this.whenDoIGetPaid +
            " " +
            this.doIGetNotification +
            " " +
            this.canIGetHistory +
            " "
        );
    },
  },
  created() {
    document.body.style.maxWidth = "100vw"; // for Google mobile crawler had to limit homepage width on body tag, this resets it
    this.doSetMetas();
    shared.saveToUserPath(
      this.devEnv,
      "Get tips more info " + new Date().toISOString(),
      this.globalPushForwardInterval
    );
    this.doFontSizeComputed();
    //window.addEventListener('resize', this.doFontSizeComputed); didn't want to do in this instance as the heigh appears to change as soon as you start scolling, the initial setting is good enough for now
  },
  mounted() {
    this.showFees =
      this.$route.params !== undefined
        ? this.$route.params.feesShow !== undefined
          ? true
          : false
        : false;

    console.log("showFees: " + this.showFees);

    if (this.showFees === true) {
      setTimeout(function () {

        if (window.matchMedia('screen and (max-width: 768px)').matches){// doesn't match my CSS queries but seems to work ok
          document
          .querySelector("#feessectionphone")
          .scrollIntoView({ behavior: "smooth", block: "start" });
        } else {
          document
          .querySelector("#feessectiondesktop")
          .scrollIntoView({ behavior: "smooth", block: "start" });
        }
       
      }, 200); // hacky
    } else {
      window.scrollTo({ top: 0, behavior: "instant" });
    }
    console.log("HOLY FUCK I AM MOUNTED!");

    this.fromScreen = (this.$route.params !== undefined && this.$route.params.fromScreen !== undefined) ? this.$route.params.fromScreen : undefined; // so showHomePageFromGoogleAd is not passed as a prop, it is passed through this.$route.params AND NOTE: though this.showHomePageFromGoogleAd is passed as a boolean from Homecontent passing it through the router converts the value to a string

    console.log("onMount this.fromScreen: " + this.fromScreen);
  },
};
</script>