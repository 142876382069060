<template>
  <!-- Bootstrap, though loaded first, was overriding some unknown style element, hard to solve out, which was sending the login page to top right. Rather than waste a long time trying to find out which, I am only applying Bootstrap to pages which use it -->
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css" integrity="sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH" crossorigin="anonymous">
    <!-- <div class="modal-overlay-canscroll" style="position: absolute; top: 10px; width: 100%; ">  not sure why but the height: 100% allows modal to center in the page otherwise it goes to top -->
      <div class="container tugradient1" style="padding-top: 5vh; padding-bottom: 5vh;"> 

        <table width=100% border=0 style="padding-left: 2.5vh; padding-right: 2.5vh: ">
          <tr>
            <td>
              <table width="100%" border=0>
                <tr>
                  <td width="30%">
                        <span class="primary-general-text divl" style="display: block; line-height: 15px;">&nbsp;</span> 
                  </td>
                  <td width="40%">
                    <img class="tulogo" style="height: 6vh; padding-bottom: 1vh" alt="ThankU logo" src="../assets/tutxtlogo.png">
                  </td>
                  <td width="30%">
                    <div class=divr>
                 
                      <button @click="doGoBack" class="text-only-button cancel"> <img class="tulogo" style="height: 4vh;" alt="close" src="../assets/greycross.png"></button>
                    </div>
                  </td>

                </tr>
              </table>
              
            </td>
          </tr>
         
           <tr>
            <td class="tuparachunkpadding">              
              <span class="tusmallerfontsize tufont">
                  <p class="MsoNormal" style="margin-bottom: 0.0001pt; line-height: normal;"><b><span style="font-size: 13.5pt; font-family: &quot;Arial&quot;,sans-serif; color: rgb(94, 144, 227);">About thankU</span></b></p>
                  <br>
                  ThankU Cashless Tipping was originally created by Adrian Harris in London in 2018. Adrian's wife Vassi would frequently ask him for cash to tip at the salon she regularly visited. Adrian, a software developer amongst other things, asked why Vassi couldn't just pay with her card adding the tip on top of her bill at the salon. When Vassi told him the salon did not permit the tip to be simply added to the bill, it was clear to Adrian that salon clients less and less often would have cash to tip their hairdressers and the hairdressers would miss out. Adrian decided to write an app to help with the problem - and ThankU Cashless Tipping was born. <br><br> 
               ThankU  - a web app so no download required - is now widely used in hair and beauty salons in the UK, the process is incredibly simple and quick and the payment goes directly to the person you are tipping. 
                  <p></p>



                  <p></p>
    
              </span> 
           </td>
          </tr>
          <tr>
            <td>
              <div class="actionbtn-div" style="padding-top: 1vh">
                <button class="tuhomepagebutton" style="width: 20%; height: 6vh;"  @click="this.doGoBack()" >Ok</button>
              </div>
            </td>
          </tr>
         
        </table>       
      </div>
    <!-- </div> -->
</template>

<script>
import shared from '../shared.js';

export default {

  methods: {
    data() { 
      return {
        fromScreen: undefined,
      }
    },
    doGoBack(){
      console.log("this.fromScreen: " + this.fromScreen);
      if(this.fromScreen !== undefined ) {
        let returnJSON = { [this.fromScreen]: true };
        console.log(JSON.stringify(returnJSON, null, 2));
        this.$emit("return-json", returnJSON);
      } else  {
        let returnJSON = { showHomeNew: true };
        this.$emit("return-json", returnJSON);
      }
    },
    doShowHome(){
      // /console.log("we're off!");

      let returnJSON = { showHomeNew: true }; 
      this.$emit('return-json', returnJSON);

    },
    doSetMetas(){

      document.title = "About ThankU Cashless Tipping - no download or account creation required";
      document.getElementsByTagName('meta').namedItem('description').setAttribute('content',
      "About ThankU Cashless Tipping - ThankU Cashless Tipping is a web app (no download required) for giving tips with your phone when you don't have cash. ThankU is widely used in hair and beauty salons in the UK and will soon be rolling out to hotels and other hospitality sectors in the UK and USA. To use ThankU, simply scan the ThankU QR code where you see it displayed - as shown below - the process is incredibly simple and quick. The default payment method is Apple Pay or Google Pay depending on your phone, and if you don't use either, you can simply use your card which will be converted to a secure digital token by our payment processor Stripe Inc. for your future use. You have the option to quickly create a ThankU user account (display name to show others, email and password) at the end of the process, but it is not required. The payment goes directly to the person you are tipping, not via their employer.") ;
    
    }
    
  },
  created(){
    document.body.style.maxWidth = "100vw"; // for Google mobile crawler had to limit homepage width on body tag, this resets it
    this.doSetMetas();
    shared.saveToUserPath(this.devEnv, "Show about info " + new Date().toISOString(), this.globalPushForwardInterval);

    this.fromScreen = (this.$route.params !== undefined && this.$route.params.fromScreen !== undefined) ? this.$route.params.fromScreen : undefined; // so showHomePageFromGoogleAd is not passed as a prop, it is passed through this.$route.params AND NOTE: though this.showHomePageFromGoogleAd is passed as a boolean from Homecontent passing it through the router converts the value to a string

  }
}
</script>

