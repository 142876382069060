<template>
  <!-- See: `getFullVh()` -->
  <div style="overflow: hidden; height: 0">
      <div id="measure-vh" style="position: fixed; height: 100vh"></div>
  </div>
  <!-- showPopUpOk --><transition name="fade">
    <div v-show="showPopUpOk" class="modal-overlayfrombottomblur" style="z-index: 999;">
      <div class="modal" style="padding-top: 3vh; padding-bottom: 3vh; padding-top: 3vh; padding-bottom: 3vh; min-height: 40vh; width: 80%" > 
        <!-- put in table for dev speed need to learn more about flex -->
        <table border=0 width=80%> 
          <tr>
            <td>
              <div class="lt-blue txtc" :style="{ 'font-size': this.fontSizeTitle + 'vh', 'line-height': this.fontSizeTitleLineHeight + 'vh', }">                 
              {{ this.popUpMsgTitle }}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="lt-blue txtc" :style="{ 'font-size': this.fontSizePopUpMsg + 'vh', 'line-height': this.fontSizePopUpMsgLineHeight + 'vh', 'padding-top': '1vh', }">                 
              {{ this.popUpMsgBody }}
              </div>
            </td>
          </tr>
          <tr>
            <td style="height: 10vh">
              <div><button class="widebtn" style="width: 80%; height: 6vh; " @click="this.doPopUpOption1()"> {{ this.button1Text }} </button></div>
            </td>
          </tr>
          <tr v-show="this.showButton2===true">
            <td>
              <div><button class="widebtn" style="width: 80%; height: 6vh; " @click="this.doPopUpOption2()">{{ this.button2Text }}</button></div>
            </td>
          </tr>
          <tr><!-- spacer --> &nbsp;<td><div></div></td>
          </tr>
        </table>       
      </div>
    </div>
  </transition>
   <!-- showPopUpFiveOptions --><transition name="fade">
    <div  v-show="this.showPopUpFiveOptions===true" class="modal-overlayfrombottomblur" style="z-index: 997;">
      <div class="modal" style="padding-top: 2vh; padding-bottom: 2vh; width: 90%"> 
        <!-- put in table for dev speed need to learn more about flex -->
        <table border=0 width=85%> 
          <tr>
            <td>
              <table width="100%">
                <td width="25%">
                  &nbsp;
                </td>
                <td width="50%">
                  <div class="lt-blue txtc" :style="{ 'font-size': fontSizeNormalInPXToUse * 1.1 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">                 
                  {{ this.PopUpFiveOptionsTitle }}
                  </div>
                </td>
                <td width="25%">
                  <div class=divr>
                      <button @click="showPopUpFiveOptions = false;" class="text-only-button cancel"> <img class="tulogo" style="height: 4vh;" alt="close" src="../assets/greycross.png"></button>
                  </div>
                </td>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <div class="lt-blue txtc" style="padding-top: 1vh" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.9 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">                 
              {{ this.PopUpFiveOptionsMessage }}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div style="padding-top: 2vh; padding-bottom: 2vh;"><button class="widebtn" style="width: 80%; height: 5vh; " :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }" @click="this.showPopUpFiveOptions = false; this.doOption1();">{{ this.option1ButtonText }}</button></div>
            </td>
          </tr>
          <tr>
            <td>
              <div style="padding-bottom: 2vh;"><button class="widebtn" style="width: 80%; height: 5vh; " :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }" @click="this.doOption2();">{{ this.option2ButtonText }}</button></div>
            </td>
          </tr>
          <tr>
            <td>
              <div style="padding-bottom: 2vh;"><button class="widebtn" style="width: 80%; height: 5vh; " :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }" @click="this.doOption3();">{{ this.option3ButtonText }}</button></div>
            </td>
          </tr>
          <tr>
            <td>
              <div style="padding-bottom: 2vh;"><button class="widebtn" style="width: 80%; height: 5vh; " :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }" @click="this.doOption4();">{{ this.option4ButtonText }}</button></div>
            </td>
          </tr>
          <tr>
            <td>
              <div style="padding-bottom: 2vh;"><button class="widebtn" style="width: 80%; height: 5vh; " :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }" @click="this.doOption5();">{{ this.option5ButtonText }}</button></div>
            </td>
          </tr>
          <tr> <!-- spacer --> <td><div></div></td>
          </tr>
        </table>       
      </div>
    </div>
    </transition>
    <!-- showTipHistory -->
    <div id="topdiv" class="modal-overlay" style="width: 100%; ">
        <div class="modal" style="outline:0.0em solid blue; display: table-cell; padding-top: 0vh; padding-bottom: 0vh; " :style="{ 'height': 98 * this.vhMult + 'vh'}"> 

        <table width=100% border=0 style="padding-left: 2.5vh; padding-right: 2.5vh; ">
            <tr>
                <td>
                    <table width="100%" border=0 style="height: 6vh">
                        <tr>
                            <td width="30%">
                                <div class=divl><button class="text-only-button cancel" @click="this.goBack()" style="display: block;" :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }"><img class="tulogo" valign=middle style="height: 1.8vh; padding-bottom: 0.3vh" alt="back" src="../assets/chevron.png"> Back</button></div>
                            </td>
                            <td width="40%" ><span style="display: block;" :style="{ 'font-size': fontSizeNormalInPXToUse * 1.2 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse * 1.2 + 'px', }">Tip history</span>
                            </td>
                            <td width="30%">
                                <div class=divr>
                                
                                    <button @click="this.doShowExport()" class="text-only-button cancel divr"  :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">Export</button>
                                </div>
                            </td>

                        </tr>
                    </table>
                    
                </td>
            </tr>
            <tr v-if="this.paymentsArray.length !== 0 && this.giverOrRecipient !== undefined">
                <td >
                    <!-- <div style="overflow:auto;" :style="{ 'height': 80 * this.vhMult + 'vh'}"> -->
                        <table class="wrapper-todiv"  align=center cellpadding=0 border=0 width=100%>
            
                            <tr>

                                <td align=left width=60% class="lt-blue divl" style="height: 1vh; letter-spacing: -0.41px" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">
                                    <span style="color:#718096; padding-right:2vh" >Tip {{this.giverOrRecipient}} / date</span>
                                </td>
                                

                                <td align=right width=20% class="lt-blue divr" style="height: 1vh; letter-spacing: -0.41px" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">
                                    <span style="color:#718096; padding-right:0vh" >Amount</span>
                                </td>
                                                                <td align=center width=20%  :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">
                                    <span style="color:#718096; padding-right:2vh" >&nbsp;</span>
                                </td>
                               
                                
                            </tr>
                    
                        </table>
                    <!-- </div>               -->
                </td>
            </tr>
            <tr v-if="this.paymentsArray.length !== 0">
                <td >
                    <div style="overflow:auto;" :style="{ 'height': 85 * this.vhMult + 'vh'}">
                        <div :key="payment.objectId" v-for="payment in paymentsArray" >
                            <TipRecord :paymentObjectId=payment.objectId  :paymentOtherPartyName=payment.paymentOtherPartyName :paymentOperator=payment.paymentOperator :paymentAmountReceived=payment.paymentAmountReceived :paymentDate=payment.date @paymentSelected="paymentSelected"/>
                        </div>   
                    </div>              
                </td>
            </tr>
            <tr v-if="this.paymentsArray.length === 0">
                <td >
                    <div style="overflow:auto;" :style="{ 'height': 80 * this.vhMult + 'vh'}">
                        <br><br><br><br><br>No tips so far
                    </div>              
                </td>
            </tr>
        </table>       
      </div>
    </div>
</template>

<script>

const Parse = require('parse/node');

import TipRecord from './TipRecord.vue'
import shared from '../shared.js';

export default {
    name: 'TipHistoryScreen',
    inject: ['globalPushForwardInterval', 'devEnv', 'appId', 'appJSKey'] ,
    props:  {
        userData: shared.userDataStruct(),
    }, 
    components: {
        TipRecord,
    },
    data () {
        return {
            fontSizeNormal: 2.5, // vh
            fontSizeNormalInPXToUse: "",
            fontSizeNormalLineHeight: 3.3,// vh
            fontSizeNormalInPXLineHeightToUse: "",
            userObjectId: undefined,
            paymentsArray: [
              {
                objectId: "1",
                paymentOtherPartyName: undefined,
              },
              {
                objectId: "2",
                paymentOtherPartyName: undefined,
              },
              {
                objectId: "3",
                paymentOtherPartyName: undefined,
              },
              {
                objectId: "4",
                paymentOtherPartyName: undefined,
              },
              {
                objectId: "5",
                paymentOtherPartyName: undefined,
              },
               {
                objectId: "6",
                paymentOtherPartyName: undefined,
              },
               {
                objectId: "7",
                paymentOtherPartyName: undefined,
              },
               {
                objectId: "8",
                paymentOtherPartyName: undefined,
              },
               {
                objectId: "9",
                paymentOtherPartyName: undefined,
              },
               {
                objectId: "10",
                paymentOtherPartyName: undefined,
              },
               {
                objectId: "11",
                paymentOtherPartyName: undefined,
              },
               {
                objectId: "12",
                paymentOtherPartyName: undefined,
              },
               {
                objectId: "13",
                paymentOtherPartyName: undefined,
              },
               {
                objectId: "14",
                paymentOtherPartyName: undefined,
              },
               {
                objectId: "15",
                paymentOtherPartyName: undefined,
              },
               {
                objectId: "16",
                paymentOtherPartyName: undefined,
              },
               {
                objectId: "17",
                paymentOtherPartyName: undefined,
              },
            ],
            showPopUpFiveOptions: false,
            PopUpFiveOptionsTitle: "Reports",
            PopUpFiveOptionsMessage: "Select a report for ThankU to email you from the choices below",  
            option1ButtonText: "Export current month",
            option2ButtonText: "Export last month",
            option3ButtonText: "Export all time",
            option4ButtonText: "Export this tax year",
            option5ButtonText: "Export last tax year",
            showPopUpOk: false,
            popUpMsgTitle: "",
            popUpMsgBody: "",
            button1Text: "ok",
            button2Text: "",
            showButton2: false,
            fontSizeTitle: 2.7, // vh
            fontSizeNormal: 2.4, // vh, not currently used
            fontSizeTitleLineHeight: 5, //vh
            fontSizePopUpMsg: 2.3, // vh
            fontSizePopUpMsgLineHeight: 3.4,// vh
            tipperOrRecipientReport: "tipper",
            vhMult: 1,
            giverOrRecipient: undefined,
        }
    },
    methods: {
        doFontSizeComputed () {
            this.fontSizeNormalInPXToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormal, 16);
            this.fontSizeNormalInPXLineHeightToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormalLineHeight, 20);
        },
        paymentSelected(paymentObjectId){
            // console.log("object ID selected: " + paymentObjectId);
            this.userData.fromScreen = "showTipHistory";
            let tipperOrRecipient;
            if (this.userData.isRecipient === true){
                this.userData.nextScreen = "showTipInfoRecipient";
                tipperOrRecipient = "Recipient";
            } else {
                this.userData.nextScreen = "showTipInfoTipper";
                tipperOrRecipient = "Tipper"
            }
            this.userData.selectedPaymentObjectId = paymentObjectId;
            // Parse.Cloud.run("sendEmail", {
            //     toEmail: "appalert@thanku.app", 
            //     subject: "ALERT! " + tipperOrRecipient + " " + this.userData.displayname + " viewed tip info from " + this.userData.fromScreen,
            //     body: "",
            // });
            this.$emit("next-action", this.userData);
        },
        goBack(){
            if (this.userData.fromScreen === "showTipHistory" && this.userData.isRecipient === true){
                
                this.userData.nextScreen = "showRecipientDashboardScreen";
               
            } else {
                if (this.userData.isRecipient === true){
                    this.userData.nextScreen = this.userData.fromScreen;
                } else {
                    this.userData.nextScreen = "showAccountScreen";
                }
            }
            this.$emit("next-action", this.userData);
        },
        doShowExport(){
          var chooseTipperOrRecipient = false;
          var button1Text = "ok";
          var button2Text = "";
          if (this.userData.isRecipient === true){
            this.tipperOrRecipientReport = "recipient"; // this may be modified by below or later but this is the default
            if (this.userData.hasGivenTipsBefore === true){
              chooseTipperOrRecipient = true;
              button1Text = "Tips received";
              button2Text = "Tips given";
            }
          } else if (this.userData.hasReceivedTipsBefore === true){
              chooseTipperOrRecipient = true;
              button1Text = "Tips given";
              button2Text = "Tips received";
          }
          if (chooseTipperOrRecipient === true) {
            this.popUpMsgTitle = "Tips given or received?";
            this.popUpMsgBody = "Email reports show either tips given by you or tips received by you, not both. Please choose which you want this report to show";
            this.button1Text = button1Text;
            this.button2Text = button2Text;
            this.showPopUpFiveOptions = false;
            this.showButton2 = true;
            this.showPopUpOk = true;
          } else {
            this.showPopUpOk = false;
            this.showPopUpFiveOptions = true;
          }
        },
        
        async initPayments() {
            try {
                
                // MARK responsiveness end section START
                let sendEmailForResponsiveness = false;
                let parseDBResponsivenessArray = [];
                let preTimeInMillis;
                let postTimeInMillis;
                preTimeInMillis = new Date().getTime();
                // MARK responsiveness end section END

                const payments = await Parse.Cloud.run("initPayments", { userObjectId: this.userData.userObjectId, tipHistoryMode: this.userData.tipHistoryMode,
                  sendEmailForResponsiveness: sendEmailForResponsiveness   // at the moment this is just designed to comment in and out when testing in localhost which after all takes away domain latency
                }); 

                // MARK responsiveness end section START
                postTimeInMillis = new Date().getTime(); parseDBResponsivenessArray.push("initPayments on TipHistory time taken: ", preTimeInMillis, postTimeInMillis, postTimeInMillis - preTimeInMillis, "break");

                if (sendEmailForResponsiveness === true){
                  let arrayToString = "";
                  for (let item of parseDBResponsivenessArray){
                      if (item === "break"){
                          arrayToString += "<br>";
                      } else {
                          arrayToString += item + "&nbsp;&nbsp;&nbsp;&nbsp;";
                      }
                      
                  }

                  const params = {
                    toEmail: "appalert@thanku.app",
                    subject: "Back4App CLIENT SIDE responsiveness test",
                    body: arrayToString,
                  };

                  Parse.Cloud.run("sendEmail", params);

                }
                // MARK responsiveness end section END


                for (const payment of this.paymentsArray) {
                  payment.paymentOtherPartyName = "stopping"; // this passes the new values to the showProgress routine
                } 
                
                this.$nextTick(() => { 
                  // the key is we CANNOT update the array before the above for has re-rendered otherwise we get into an endless loop
                  this.paymentsArray = payments; 
                });

                return;
            } catch (e) {
                console.error(e);
                throw e;
            } 
        },
        doPopUpOption1(){
          
          if (this.button1Text === "Tips received"){
            // console.log("I'm a recipient 1");
            this.tipperOrRecipientReport = "recipient";
            this.showPopUpOk = false;
            this.showPopUpFiveOptions = true;
          } else if (this.button1Text === "Tips given") {
            // console.log("I'm a tipper 1");
            this.tipperOrRecipientReport = "tipper";
            this.showPopUpOk = false;
            this.showPopUpFiveOptions = true;
          } else {
            this.showButton2 = false;
            this.showPopUpFiveOptions = false;
            this.showPopUpOk = false;
          }
        },
        doPopUpOption2(){
          if (this.button2Text === "Tips received"){
            // console.log("I'm a recipient 2");
            this.tipperOrRecipientReport = "recipient";
            this.showPopUpOk = false;
            this.showPopUpFiveOptions = true;
          } else if (this.button2Text === "Tips given") {
             // console.log("I'm a tipper 2");
            this.tipperOrRecipientReport = "tipper";
            this.showPopUpOk = false;
            this.showPopUpFiveOptions = true;
          } else {
            this.showButton2 = false;
            this.showPopUpFiveOptions = false;
            this.showPopUpOk = false;
          }
        },
        doOption1()
        {
            this.doOrderReport(1);
        },
        doOption2()
        {
            this.doOrderReport(2);
        },
        doOption3()
        {
            this.doOrderReport(3);
        },
        doOption4()
        {
            this.doOrderReport(4);
        },
        doOption5()
        {
            this.doOrderReport(5);
        },
        async doOrderReport(reportNum){
            
            // console.log("userObjectId: " + this.userData.userObjectId);

            try {
                // const result = await - don't it takes too long; we have an alert if nec on the backend 
                Parse.Cloud.run("generateTransactionReport", { userObjectId: this.userData.userObjectId, reportNum: reportNum, tipperOrRecipientReport: this.tipperOrRecipientReport});  
                
                this.popUpMsgTitle = "Done!";
                this.popUpMsgBody = "your report has been emailed to you";
                this.button1Text = "ok";
                this.button2Text = "";
                this.showButton2 = false;
                this.showPopUpOk = true;  
                return;
            } catch (e) {
                console.error(e);
                throw e;
            } 
            
        },
    },
    async created(){
        Parse.serverURL = 'https://parseapi.back4app.com/'; Parse.initialize(this.appId, this.appJSKey); (this.appId, this.appJSKey);
        await this.initPayments();
        this.giverOrRecipient = this.userData.isRecipient === true ? "giver" : "recipient";
    },
    mounted(){
        this.doFontSizeComputed();
        this.vhMult = shared.setScreenHeightMultiplier();
        shared.saveToUserPath(this.devEnv, "Tip history " + new Date().toISOString(), this.globalPushForwardInterval);

    }
}
</script>

<style scoped>


</style>