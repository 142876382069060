<template>
  <div class="container">
    <div class="row tufooterpadding">
      <span
        class="col-8 col-md-2 tufont tutablepadding text-start tusmallerfontsize"
        style="padding-top: 10px"
      >
        <img
          alt="ThankU logo"
          @click="$emit('do-show-home')"
          style="height: 40px"
          src="../assets/tutxtlogosm.png"
        />
      </span>
      <span
        class="col-4 col-md-1 tufont tutablepadding text-start tusmallerfontsize"
        style="padding-top: 20px"
      >
        <img
          class="tuiconpadding"
          @click="this.doShowInsta"
          alt="ThankU logo"
          style="height: 20px; cursor: pointer;"
          src="../assets/instatransbluesm.webp"
        />
        <img
          class="tuiconpadding"
          @click="this.doShowFB"
          alt="ThankU logo"
          style="height: 20px; cursor: pointer;"
          src="../assets/facebooktransbluesm.webp"
        />
        <img
          class="tuiconpadding"
          @click="this.doShowLinkedIn"
          alt="ThankU logo"
          style="height: 20px; cursor: pointer;"
          src="../assets/linkedintransbluesm.webp"
        />
      </span>
      <span
        class="col-12 col-md-6 tufont tutablepadding text-start tusmallestfontsize"
        style="padding-top: 20px"
      >
        ThankU Cashless Tipping has been operating since 2019 and is the most
        highly rated tipping platform. ThankU services many top brands and
        specialises in hair & beauty salons.
      </span>
      <span
        class="col-12 col-md-3 tufont tutablepadding tusmallestfontsize float-end"
        style="padding-top: 14px; outline: 0em dashed orange; height: auto"
      >
        <img
          alt="applepay logo"
          style="height: 25px; padding-left: 5px; padding-right: 5px"
          src="../assets/applepay.webp"
        />
        <img
          alt="googlepay logo"
          style="height: 25px; padding-left: 5px; padding-right: 5px"
          src="../assets/googlepay.webp"
        />
        <img
          alt="visa logo"
          style="height: 35px; padding-left: 5px; padding-right: 5px"
          src="../assets/visa.webp"
        />
        <img
          alt="mastercard logo"
          style="height: 35px; padding-left: 5px; padding-right: 5px"
          src="../assets/mastercard.webp"
        />
        <img
          alt="amex logo"
          style="height: 35px; padding-left: 5px; padding-right: 5px"
          src="../assets/amex.webp"
        />
      </span>
    </div>
    <div class="row tufooterpadding">
      <span
        class="col-12 col-md-8 tufontlightgrey tutablepadding tusmallestfontsize text-md-start"
        style="padding-top: 10px"
      >
        © Copyright 2024 ThankU Cashless Tipping. All rights reserved.
      </span>
      <span
        @click="$emit('do-show-contact')"
        class="col-2 offset-2 col-md-1 offset-md-0 tufontlightgrey tutablepadding tusmallestfontsize"
        style="padding-top: 10px; cursor: pointer"
      >
        Contact
      </span>
      <span
        @click="$emit('do-show-about')"
        class="col-2 col-md-1 tufontlightgrey tutablepadding tusmallestfontsize"
        style="padding-top: 10px; cursor: pointer"
      >
        About
      </span>
      <span
        @click="$emit('do-show-privacy')"
        class="col-2 col-md-1 tufontlightgrey tutablepadding tusmallestfontsize"
        style="padding-top: 10px; cursor: pointer"
      >
        Privacy
      </span>
      <span
        @click="$emit('do-show-terms')"
        class="col-2 col-md-1 tufontlightgrey tutablepadding tusmallestfontsize"
        style="padding-top: 10px; cursor: pointer"
      >
        Terms
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeFooter",
  methods: {
    doShowInsta() {
      console.log("show Insta");
      const url = "https://www.instagram.com/thankuapp/";
      window.open(url, "_self");
    },
    doShowFB() {
      console.log("show FB");
      const url = "https://www.facebook.com/thethankuapp/";
      window.open(url, "_self");
    },
    doShowLinkedIn() {
      console.log("how LinkedIn");
      const url = "https://www.linkedin.com/company/thanku-cashless-tipping/?viewAsMember=true";
      window.open(url, "_self");
    },
  },
};
</script>
