<template>
  <div class="topcontent-div" style="outline:0.0em solid blue;">
    <div v-show="this.showHome" class="wrapper-todiv" style="overflow: visible; outline:0.0em solid red;">
      
      <table width="100%" border=0 cellpadding=0 cellspacing=0 style="overflow: visible;">
        <tr>
          <td >
            <table width="100%" border=0 style="height: 6vh;">
                <tr>
                    <td width="50%">
                        <div class=divl><button @click="this.goBack();" class="text-only-button cancel" style="display: block;" :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }"><img class="tulogo" valign=middle style="height: 1.8vh; padding-bottom: 0.3vh" alt="back" src="../assets/chevron.png"> Back</button></div>
                    </td>
                    <td width="50%">
                    <div class=divr>
                    
                        
                    </div>
                    </td>

                </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td colspan="3" style="height: 20vh; padding-top: 2vh; padding-bottom: 0.3vh;">
            <div class="userinfoframe" style="padding-top: 0.5vh; padding-bottom: 0.5vh;">
              <table width=100% border=0 cellpadding=0 cellspacing=0 style="overflow: visible; outline:0.0em solid green;" >
                <tr>
                  <td width="100%"  valign=center style="display: flex-center; font-size: 85%; color: #000; padding-bottom: 1vh"><b><img valign=center style="height: 11vh; padding-bottom: 1vh" alt="ThankU QR badge" src="../assets/bluetube.png"> <br>find us at..<br>
                  </b></td>

                </tr>
                <tr>
                  <td style="vertical-align: middle; justify-content: center; align-items: justified;">
                    <a href="https://joshwoodcolour.com/" target=blank><img style="vertical-align: middle; height: 7.2vh; padding-right: 1.5vh" alt="Josh Wood Colour" src="../assets/jwc.png"></a>
                    <a href="https://larryking.co.uk/salon/" target=blank><img style="vertical-align: middle; height: 6.5vh; padding-left: 1.5vh" alt="Larry King" src="../assets/larrykingbetter.png"></a>
                    
                  </td>
                </tr>
                <tr>
                  <td style="display: flex; vertical-align: middle; justify-content: center; align-items: justified; padding-top: 2vh">
                    <a href="https://duckanddry.com/" target=blank><img style="height: 3vh; padding-right: 1vh; padding-top: 2vh" alt="Duck & Dry" src="../assets/duckanddry.png"></a>
                    <a href="https://www.edwardjameslondon.com/" target=blank><img style="vertical-align: middle; height: 2.8vh; padding-left: 1vh; padding-top: 2vh" alt="Edward James London" src="../assets/edwardjames.png"></a>
                                  
                                
                  </td>
                </tr>
                 <tr>
                  <td style="display: flex; vertical-align: middle; justify-content: center; align-items: justified; padding-top: 2vh">
                    <a href="https://clayhairsalon.com/" target=blank><img style="height: 11vh; padding-right: 1vh; padding-top: 0vh" alt="Clay Hair" src="../assets/clayhair.png"></a>
                    <a href="https://www.cloandflo.com/" target=blank><img style="height: 6vh; padding-left: 2vh; padding-top: 1vh" alt="Clo&Flo" src="../assets/cloandflo.png"></a>
                    <a href="https://so.me/" target=blank><img style="height: 9vh; padding-left: 4vh" alt="So Me" src="../assets/some.png"></a>
                                  
                  </td>
                </tr>
                <tr>
                  <td style="vertical-align: middle; justify-content: center; align-items: justified; padding-top: 0.5vh">
                    <a href="https://toniandguy.com/" target=blank><img style="vertical-align: middle; height: 4vh; padding-right: 0.5vh; padding-top: 0vh" alt="TONI&GUY" src="../assets/toninguy.png"></a>
                    <a href="https://limozlogli.com/" target=blank><img style="vertical-align: middle; height: 7vh; padding-left: 0vh" alt="Limoz Logli" src="../assets/limozloglicropped.png"></a>
                  </td>
                </tr>
                <tr>
                  <td style="vertical-align: middle; justify-content: center; align-items: justified; padding-top: 0.5vh">
                    <a href="https://www.tomoyukiandco.com/" target=blank><img style="vertical-align: middle; height: 5vh; padding-right: 1vh; padding-top: 1vh" alt="Tomoyuki" src="../assets/tomoyuki.png"></a>
                    <a href="https://www.collectionshairclub.com/" target=blank><img style="vertical-align: middle; height: 9vh; padding-left: 1vh" alt="Collections Hair Club" src="../assets/collections.jpg"></a>          
                  </td>
                </tr>
                <tr>
                  <td style="vertical-align: middle; justify-content: center; align-items: justified; padding-top: 2vh">
                    <a href="https://aquavie.co.uk/" target=blank><img style="vertical-align: middle; height: 4.6vh; padding-right: 2vh" alt="Aquavie" src="../assets/aquavie.png"></a>
                     <a href="https://www.emmahallhair.com/" target=blank><img style="vertical-align: middle; height: 5vh; padding-left: 2vh" alt="Emma Hall Hair" src="../assets/emmahallhair.jpg"></a>
                    
                  
                  </td>
                </tr>
                <tr>
                  <td style="vertical-align: middle; justify-content: center; align-items: justified; padding-top: 3vh">
                    <a href="https://www.bellifuori.co.uk/about" target=blank><img style="vertical-align: middle; height: 3.2vh; padding-right: 0vh" alt="Belli Fuori" src="../assets/bellifuori.png"></a>
                     <a href="https://www.stagedoorsalon.co.uk/" target=blank><img style="vertical-align: middle; height: 3.4vh; padding-left: 0vh" alt="Stage Door Hair now ORU London" src="../assets/stagedoor.jpg"></a>
                    
                  
                  </td>
                </tr>
                <tr>
                  <td style="display: flex; vertical-align: middle; justify-content: center; align-items: justified; padding-top: 3vh">
                    <a href="https://coolcreative.net/" target=blank><img style="height: 8vh; padding-right: 1vh;" alt="Cool Creative" src="../assets/coolcreative.jpg"></a>
                    <a href="https://www.acquadiparma.com/en/gb/esperienze-special-pages/barbiere/" target=blank><img style="height: 6vh; padding-left: 0vh" alt="ACQUA di PARMA" src="../assets/acquadiparma1.jpg"></a>
                    <a href="https://natashahodgeshair.com/" target=blank><img style="height: 7vh; padding-left: 1vh" alt="Natasha Hodges Hair" src="../assets/natashahodgeshair.png"></a>
                                  
                  </td>
                </tr>
                <tr>
                  <td style="vertical-align: middle; justify-content: center; align-items: justified; padding-top: 2vh">
                    <a href="http://www.nailuxury.co.uk/" target=blank><img style="vertical-align: middle; height: 6.5vh; padding-right: 0.5vh" alt="Nailuxury" src="../assets/nailuxury.jpg"></a>
                    <a href="https://pfeffersal.com/" target=blank><img style="vertical-align: middle; height: 4.2vh; padding-left: 0.5vh" alt="Pfeffer Sal" src="../assets/pfeffersal.png"></a>
                   
                  </td>
                </tr>
                 <tr>
                  <td style="display: flex; vertical-align: middle; justify-content: center; align-items: justified; padding-top: 2vh">
                    <a href="http://www.woo.uk.com/" target=blank><img style="height: 4.3vh; padding-right: 0.5vh; padding-top: 1vh" alt="Woo" src="../assets/woolarge.jpg"></a>
                    <a href="https://www.njuk-hair.uk/" target=blank><img style="height: 6vh; padding-left: 1vh; padding-top: 1vh" alt="NJUK" src="../assets/njukblack.png"></a>
                    <a href="https://www.feaxhair.com/" target=blank><img style="height: 7.5vh; padding-left: 1vh" alt="Feax Hair Studio" src="../assets/feax.png"></a>
                                  
                  </td>
                </tr>
                 <tr>
                  <td style="vertical-align: middle; justify-content: center; align-items: justified; padding-top: 2vh">
                    <a href="https://www.blackheathhair.co.uk/" target=blank><img style="vertical-align: middle; height: 9vh; padding-right: 1vh" alt="Black Heath Hair" src="../assets/blackheathhair.jpg"></a>
                    <a href="https://www.melissajanes.co.uk/" target=blank><img style="vertical-align: middle; height: 9vh; padding-left: 1vh" alt="Melissa Janes Hair" src="../assets/melissajaneshair.png"></a>
                   
                  </td>
                </tr>
                <tr>
                  <td style="vertical-align: middle; justify-content: center; align-items: justified;  padding-top: 2vh">
                    <a href="https://www.perfectionwaxboutique.co.uk/" target=blank><img style="vertical-align: middle; height: 8vh; padding-right: 2vh" alt="Perfection Wax Boutique" src="../assets/perfectionwax.jpg"></a>
                    <a href="https://rubyb.co.uk/" target=blank><img style="vertical-align: middle; height: 6vh; padding-left: 1vh" alt="Ruby B Organic" src="../assets/rubyb.jpg"></a>
                    <a href="https://saravictoriabeauty.co.uk/" target=blank><img style="vertical-align: middle; height: 8vh; padding-left: 2vh" alt="Sara Victoria" src="../assets/saravictoria.webp"></a>
                                  
                  </td>
                </tr>
                
                <tr>
                  <td style="vertical-align: middle; justify-content: center; align-items: justified; padding-top: 2vh">
                    <a href="https://ilovekalon.com/" target=blank><img style="vertical-align: middle; height: 4vh; padding-right: 2vh" alt="Ka.Lon" src="../assets/kalon.png"></a>
                    <a href="https://www.thepamperlounge.co.uk/" target=blank><img style="vertical-align: middle; height: 11vh; padding-left: 1vh" alt="The Pamper Lounge" src="../assets/pamperlounge.jpg"></a>
                    <a href="https://gustohair.com/" target=blank><img style="vertical-align: middle; height: 4vh; padding-left: 3vh" alt="Gusto Hairdressing" src="../assets/gusto.webp"></a>
                                  
                  </td>
                </tr>
                 <tr>
                  <td style="vertical-align: middle; justify-content: center; align-items: justified; padding-top: 2vh">
                    <a href="https://www.mybareskin.co.uk/" target=blank><img style="vertical-align: middle; height: 8.5vh; padding-right: 0.5vh" alt="My Bare Skin" src="../assets/mybareskin.webp"></a>
                    <a href="https://phase4hairdressing.co.uk/" target=blank><img style="vertical-align: middle; height: 5vh; padding-left: 0.5vh" alt="Phase IV Hair" src="../assets/phaseiv.png"></a>
                   
                  </td>
                </tr>
                <tr>
                  <td style="vertical-align: middle; justify-content: center; align-items: justified; padding-top: 2vh">
                    <a href="https://www.no45hairdressing.co.uk/" target=blank><img style="vertical-align: middle; height: 7vh; padding-right: 0vh" alt="Savills by Luisa" src="../assets/savillsbyluisa.jpg"></a>
                    <a href="https://www.savillsbyluisa.com/" target=blank><img style="vertical-align: middle; height: 9vh; padding-left: 1vh" alt="No 45 Hairdressing" src="../assets/no45.jpg"></a>
                    <a href="https://www.richardward.com/" target=blank><img style="vertical-align: middle; height: 3.5vh; padding-left: 1vh" alt="Richard Ward" src="../assets/richardward.jpg"></a>
                                  
                  </td>
                </tr>
                <tr>
                  <td style="vertical-align: middle; justify-content: center; align-items: justified; padding-top: 2vh">
                    <a href="https://salon-41.co.uk/" target=blank><img style="vertical-align: middle; height: 9vh; padding-right: 1vh" alt="Salon 41" src="../assets/salon41.png"></a>
                    <a href="http://www.thehairshop.co.uk/" target=blank><img style="vertical-align: middle; height: 3vh; padding-left: 1vh" alt="The Hair Shop" src="../assets/thehairshop.jpg"></a>
                    <a href="https://www.rootcube.info/" target=blank><img style="vertical-align: middle; height: 7vh; padding-left: 1vh" alt="Root Cube" src="../assets/rootcube.webp"></a>
                                  
                  </td>
                </tr>
                <tr>
                  <td style="vertical-align: middle; justify-content: center; align-items: justified; padding-top: 2vh">
                    <a href="https://www.twentunosalon.co.uk/" target=blank><img style="vertical-align: middle; height: 4.2vh; padding-right: 0vh" alt="Twentuno" src="../assets/twentuno.jpg"></a>
                    <a href="https://dkyhair.com/" target=blank><img style="vertical-align: middle; height: 6vh; padding-left: 0.5vh" alt="DKY Hair" src="../assets/dky.png"></a>
                    <a href="https://www.conroyhair.com/" target=blank><img style="vertical-align: middle; height: 3.5vh; padding-left: 0.3vh" alt="Conroy Hair" src="../assets/conroy.jpg"></a>
                                  
                  </td>
                </tr>
                <tr>
                  <td style="vertical-align: middle; justify-content: center; align-items: justified; padding-top: 2vh">
                    <a href="https://thehairdressersnewcastle.co.uk/" target=blank><img style="vertical-align: middle; height: 5vh; padding-right: 2vh" alt="The Hairdressers" src="../assets/thehairdressers.jpg"></a>
                    <a href="https://www.facebook.com/thebeautyspotiow/" target=blank><img style="vertical-align: middle; height: 7vh; padding-left: 2vh" alt="The Beauty Spot" src="../assets/thebeautyspot.jpg"></a>
                    
                  </td>
                </tr>
                <tr>
                  <td style="vertical-align: middle; justify-content: center; align-items: justified; padding-top: 2vh">
                    <a href="https://jdcreativehair.co.uk/" target=blank><img style="vertical-align: middle; height: 8vh; padding-right: 1vh" alt="JD Creative" src="../assets/jdcreative.jpg"></a>
                     <a href="https://pinkysbeauty.co.uk/" target=blank><img style="vertical-align: middle; height: 6vh; padding-left: 1vh; padding-top: 0vh" alt="Pinky's" src="../assets/pinkys.png"></a>
                   
                                  
                  </td>
                </tr>
                <tr>
                  <td style="display: flex; vertical-align: middle; justify-content: center; align-items: justified; padding-top: 2vh">
                    <a href="https://www.nicholasanthonyhairdressers.co.uk/" target=blank><img style="height: 3.5vh; padding-right: 3vh; padding-top: 2vh; vertical-align: middle; justify-content: center; align-items: justified; " alt="Nicholas Anthony" src="../assets/nicholasanthony.png"></a>
                    <a href="http://www.enzo.co.uk/" target=blank><img style="vertical-align: middle; height: 9vh; padding-left: 2vh" alt="Enzo, the Art of Hair" src="../assets/enzo.jpg"></a>
                  </td>
                </tr>
                <tr>
                  <td style="display: flex; vertical-align: middle; justify-content: center; align-items: justified; padding-top: 2vh">
                    <a href="https://www.headofficehairstudios.co.uk/" target=blank><img style="height: 6vh; padding-right: 2vh;" alt="Head Office" src="../assets/headofficecropped.jpg"></a>
                    <a href="https://www.facebook.com/hevanssalon/" target=blank><img style="height: 10vh; padding-left: 2vh; padding-right: 4vh" alt="Hevans" src="../assets/hevans.png"></a>
                  </td>
                </tr>
                <tr>
                  <td style="display: flex; vertical-align: middle; justify-content: center; align-items: justified; padding-top: 2vh">
                    <a href="https://www.hairgallery.co.uk/" target=blank><img style="height: 4.5vh; padding-right: 2vh;" alt="Hair Gallery" src="../assets/hairgallery.png"></a>
                    <a href="https://www.facebook.com/ritualbeauty30/" target=blank><img style="height: 8vh; padding-left: 1vh; padding-right: 2vh" alt="Ritual Beauty & Spa" src="../assets/ritualcropped.jpg"></a>
                  </td>
                </tr>
                <tr>
                  <td style="display: flex; vertical-align: middle; justify-content: center; align-items: justified; padding-top: 2vh">
                    <a href="https://themenzroom.nearcut.com/" target=blank><img style="display: flex; vertical-align: middle; height: 6vh; padding-right: 3vh; padding-top: 1vh" alt="The Menz Room" src="../assets/menzroom.jpg"></a>
                    <a href="https://www.bellisbybluediamond.co.uk/" target=blank><img style="height: 10vh; padding-left: 2vh; padding-right: 1vh" alt="Bellis by Blue Diamond" src="../assets/bellis.jpg"></a>
                  </td>
                </tr>
                                <tr>
                  <td style="display: flex; vertical-align: middle; justify-content: center; align-items: justified; padding-top: 2vh">
                    <a href="https://oraclehairandbeauty.co.uk/" target=blank><img style="height: 10vh; padding-right: 1vh;" alt="Oracle Hair & Beauty" src="../assets/oracle.png"></a>
                    <a href="https://www.sheensalon.com/" target=blank><img style="height: 6vh; padding-left: 2vh; padding-right: 1vh; padding-top: 1.5vh" alt="Sheen Hair" src="../assets/sheenhaironblack.png"></a>
                  </td>
                </tr>
                <tr>
                  <td style="display: flex; vertical-align: middle; justify-content: center; align-items: justified; padding-top: 2vh">
                    <a href="https://www.forbicilondon.com/" target=blank><img style="height: 5vh; padding-right: 1vh;" alt="Forbici" src="../assets/forbicicropped.png"></a>
                    <a href="https://www.sharp-owen.com/" target=blank><img style="height: 6vh; padding-left: 1vh; padding-right: 1vh" alt="Sharp & Owen" src="../assets/sharpandowen.jpg"></a>
                  </td>
                </tr>
                <tr>
                  <td style="display: flex; vertical-align: middle; justify-content: center; align-items: justified; padding-top: 2vh">
                    <a href="https://www.thesalonletchworth.co.uk/" target=blank><img style="height: 4.2vh; padding-right: 0vh; padding-top: 1vh" alt="The Salon" src="../assets/thesalon.png"></a>
                    <a href="https://marcelas.co.uk/" target=blank><img style="height: 7vh; padding-left: 1vh; padding-right: 1vh" alt="Marcelas" src="../assets/marcelas.png"></a>
                  </td>
                </tr>

                <tr>
                  <td style="display: flex; vertical-align: middle; justify-content: center; align-items: justified;">

                    <a href="https://www.lordsandladiesbedford.co.uk/" target=blank><img style="height: 10vh; padding-right: 4vh;" alt="The Salon" src="../assets/lordsandladies.jpg"></a>
                  </td>
                </tr>
              </table>
            </div>  
          </td>
        </tr>
        
        <tr>
          <td colspan="3" style="padding-top: 1.5vh">
            <table width="100%">
    
              <tr>
                <td colspan="4" style="padding-top: 0.2vh">
                  <span style="font-size: 60%; color: #000012">Copyright 2022 Appsanely Limited</span>
                </td>
              </tr>
            </table>
          </td>
                         
        </tr>
      </table>
    </div>
    <!-- <router-link to="givetips"> Give Tips</router-link> -->
    
  </div>
</template>

<script>

import shared from '../shared.js';
const Parse = require('parse/node');

export default {
  inject: ['globalPushForwardInterval', 'devEnv', 'appId', 'appJSKey'] ,
  data() {
    return {
      showHome: true,
      loggedInUserDisplayname: "",
    }
  },

  methods: {
    goBack(){
      
      let returnJSON = { showHome: true };
      // console.log("retruning...");
      this.$emit('return-json', returnJSON); // this is activating the router so no need to go back
      
    },

    doSetMetas(){

      document.title = "Giving tips with ThankU couldn't be simpler - QR-based, tips go directly to the person you are tipping, used at top salons such as Larry King and Josh Wood Colour";
      document.getElementsByTagName('meta').namedItem('description').setAttribute('content',
      "Giving tips with ThankU couldn't be simpler - QR-based, tips go directly to the person you are tipping, used at top salons such as Larry King and Josh Wood Colour. Just scan the ThankU Cashless Tipping QR badge or tap 'Tip without scanning' below and tip in 10 seconds or less. No account creation required, and tips go directly to the person you are tipping. Secure transaction with Stripe Inc. 1. Scan a ThankU QR code 2. Select your tip 3. Pay with Apple Pay, Google Pay, or your card. ") ;
    
    }

  },
  
  mounted(){
    document.body.style.maxWidth = "100vw"; // for Google mobile crawler had to limit homepage width on body tag, this resets it
    shared.saveToUserPath(this.devEnv, "Salon more info " + new Date().toISOString(), this.globalPushForwardInterval);
    var tudis = "";
    if (window.localStorage.getItem("tudis") !== null){
      tudis = " by user " + window.localStorage.getItem("tudis");
    }

    Parse.serverURL = 'https://parseapi.back4app.com/'; Parse.initialize(this.appId, this.appJSKey); (this.appId, this.appJSKey);
      // const params = {
      //   toEmail: "appnotif@thanku.app",
      //   subject: "SALON page visited" + tudis + "!" + " " + new Date().toISOString(),
      //   body: "SALON page visited" + tudis + "!" + " " + new Date().toISOString(),
      // };
      // Parse.Cloud.run("sendEmail", params);
    this.doSetMetas();
  }

}
</script>