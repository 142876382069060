<template>
  <!-- Bootstrap, though loaded first, was overriding some unknown style element, hard to solve out, which was sending the login page to top right. Rather than waste a long time trying to find out which, I am only applying Bootstrap to pages which use it -->
  <link v-if="showOld === false" rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css" integrity="sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH" crossorigin="anonymous">
  <!-- See: `getFullVh()` -->
  <div style="overflow: hidden; height: 0">
      <div id="measure-vh" style="position: fixed; height: 100vh"></div>
  </div>

  <div v-if="this.showOld===false"> <!-- how or dont show whole new home page -->

    <div class="container text-center" style="padding: 0vw; outline:0.0em dashed orange;">
      
      <NavigationBar @do-show-home="doShowHome" @do-show-gettipsstart="doShowGetTipsStartNew"   @do-show-givetips="doGiveTipsShowMoreInfoNew"  @do-show-gettips="doGetTipsShowMoreInfoNew"  @do-show-tipwithoutscanning="doShowTipWithoutScanning" @do-show-account="doShowAccount" @do-download-brochure="doDownloadBrochure" @do-show-contact="doShowContactNew"/>

      <div class="jumbotron text-center tugradient1" style="outline:0.0em dashed red;">
        <div class="mediaSelectDesktop">
          <div class="container " style="padding: 3vw; outline:0.0em dashed blue;">
            <div class="row" style="outline:0.0em dashed pink;" >
              
              <div class="col-md-8 text-md-start highestratedpadding tufont highest-rated-animation" style="padding-left: 3vw; padding-right: 6vw; outline:0.em dashed orange; height: auto">
                <h3 class=" tutitlefontsize">The <span class="tublue">highest rated</span> cashless tipping solution</h3>
              </div>
            
              <div class="col-md-4 mainimageheight hero-image-animation" style="outline:0.0em dashed green;">
                <a v-bind:href="frontdeskfull"><img class="mainimagewidth" style="height: auto" alt="Front Desk Stand" src="../assets/frontdeskrounded.webp"></a>
              </div>
              <div>&nbsp;</div>
             
              <div class="col-md-8 text-md-start highestratedpadding tufont tumediumtitlebold tublue transparent-simple-animation" style="padding-left: 3vw; padding-right: 6vw; padding-top: 2vw; padding-bottom: 1vw; outline:0.0em dashed orange; height: auto; max-height: 0;">
                Simple, transparent and secure
              </div>
             
              <div>&nbsp;</div>
              <div class="col-md-7 text-start tufont tusmallerfontsize maintextpadding appear-text-animation" style="outline:0.0em dashed blue;">With a quick scan of the ThankU QR code, your clients can leave a tip in 10 seconds or less - no app download or tipper registration needed. We specialise in hair &amp; beauty salons. </div>
              <div class="col-md-7 text-start tufont turegularfontsize maintextpadding"></div>
              <div class="col-md-7 text-start tufont turegularfontsize maintextpadding"></div>
              <div class="col-md-7 text-start tufont turegularfontsize maintextpadding"></div>
              <div class="col-md-7 text-start tufont turegularfontsize maintextpadding"><button class="tuhomepagesignupbutton" style="width: 40%;"  @click="this.doShowGetTipsStartNew();" >Get set up for free!</button></div>
              <div class="col-md-7 text-start tufont turegularfontsize maintextpadding"></div>
            </div>
          </div>
          <div class="row" style="height:1vw">
            <div class="col-sm-4 col-md-1" style="outline:0.0em dashed green;">
                <img class="smile1" alt="ThankU smile right" src="../assets/tusmileonly.png">
              </div>
            <div class="col-sm-4 col-md-1" style="outline:0.0em dashed green;">
              <img class="smile2" alt="ThankU smile right" src="../assets/tusmileonly.png">
            </div>
          </div>
        </div>  
        <div class="mediaSelectPhone container">
            <div class="row" style="outline:0.0em dashed pink;" >
              <div class="col-10 offset-1 text-md-start highestratedpadding tufont highest-rated-animation" style="padding-left: 3vw; padding-right: 6vw; padding-top: 7vw; outline:0.em dashed orange; height: auto">
                <h3 class="tutitlefontsize">The <span class="tublue">highest rated</span> cashless tipping solution</h3>
              </div>
              <div class="col-10 offset-1 text-md-start highestratedpadding tufont tumediumtitlebold tublue transparent-simple-animation" style="padding-left: 3vw; padding-right: 6vw; outline:0.0em dashed orange; height: auto">
                Simple, transparent and secure
              </div>
              
              <div class="offset-0 col-3" style="height: 0px; outline:0.0em dashed green;">
                <img class="smile1" alt="ThankU smile" src="../assets/tusmileonly.png">
              </div>
              
              <div>&nbsp;</div>
              <div class="col-12 col-sm-12 mainimageheight hero-image-animation" style="outline:0.0em dashed green;">
                <a v-bind:href="frontdeskfull"><img class="mainimagewidth" style="height: auto" alt="Front Desk Stand" src="../assets/frontdeskrounded.webp"></a>
              </div>
              <div class="offset-0 col-12 float-right"  style="height: 0px; outline:0.0em dashed green;">
                <img class="smile2" alt="ThankU smile" src="../assets/tusmileonly.png">
              </div>
              <div>&nbsp;</div>
              <div class="col-10 offset-1 tufont turegularfontsize maintextpadding appear-text-animation" style="outline:0.0em dashed blue;">With a quick scan of the ThankU QR code, your clients can leave a tip in 10 seconds or less - no app download or tipper registration needed. We specialise in hair &amp; beauty salons. </div>
              <div class="col-10 offset-1 text-start tufont turegularfontsize maintextpadding"></div>
               <div class="col-10 offset-1 tufont turegularfontsize" style="padding-bottom: 4vw; "><button class="tuhomepagesignupbutton" @click="this.doShowGetTipsStartNew();" >Get set up for free!</button></div>
              <div class="col-10 offset-1 text-start tufont turegularfontsize maintextpadding"></div>
            </div>
          
            
        
        </div>  
        <div class="container" style="padding: 0vw; outline:0.0em dashed blue;">
          <div class="col-sm-12 col-md-12 tufont tumediumtitlebold" style="padding-left: 3vw; padding-right: 6vw; outline:0.0em dashed orange; height: auto">
            Relied upon by top salons
          </div>
          <div class="col-sm-12 col-md-12 tufont tumediumtitlebold tucarouselpadding" style="outline:0.0em dashed orange; height: auto">
            <!-- carousel came from here https://ismail9k.github.io/vue3-carousel/ as an npm install, with this site very helpful so I could work out how to operate it: https://stackblitz.com/edit/vue3-script-setup-with-vite-xgsbqz?file=src%2FApp.vue,src%2Fmain.js -->
            <Carousel :wrap-around="true" :breakpoints="this.breakpoints" :autoplay="6000">
              <Slide v-for="slide in this.slides" :key="slide.id">
                <div class="tucarousel__itembrands">
                  <!-- actually not doing anything with first two, but just showing how woudl work -->
                  <h3>{{ slide.title }}</h3> 
                  <p>{{ slide.content }}</p>
                  <img class="img-fluid" :src="slide.slideimage">
                    <!-- :style="{ 'width' : slide.slidewidth }" /> -->
                </div>
              </Slide>

              <template #addons>
                <Navigation />
                <Pagination />
              </template>
            </Carousel>
          </div>
        </div>
        <div class="container " style="padding: 3vw; outline:0.0em dashed blue;">
          <div class="row" style="outline:0.0em dashed pink; " >
            <div class="col-sm-4 col-md-4 mainimageheight" style="outline:0.0em dashed green;">
              <img class="mainimagewidth" style="height: auto" alt="Hairdresser" src="../assets/hairdresser1rounded.webp">
            </div>
            <!-- <div class="col-sm-8 col-md-8 text-start" style="padding-left: 3vw; padding-right: 6vw; outline:0.0em dashed orange; height: auto"> -->
              
            <div class="col-sm-8 col-md-8 tufont tumediumtitlebold tubestsolutiontitle" style="outline:0.0em dashed purple; height: auto">
              The best tipping solution for salons
            </div>
            <HomePageTickedListItem ListItemString="Tips direct to staff"/>
            <HomePageTickedListItem ListItemString="Bypasses the business, HMRC compliant"/>
            <HomePageTickedListItem ListItemString="No app download or tipper registration needed"/>
            <HomePageTickedListItem ListItemString="Zero management"/>
            <HomePageTickedListItem ListItemString="Easy, secure and fast"/>
            <HomePageTickedListItem ListItemString="Superior and fully transparent reporting"/>
            <HomePageTickedListItem ListItemString="Great support by email <b>and</b> phone"/>
            <HomePageTickedListItem ListItemString="Happy clients, happy staff, happy management"/>

            <div class="mediaSelectDesktop row col-sm-12 row-md col-md-8 offset-md-4 tufont tutablepadding text-start tusmallerfontsize" style="outline:0.00em dashed red; height: auto; float: left; padding-top: 1vh">
                <span class="col-1 offset-2 col-md-1 offset-md-2 tufont;" style="outline:0.00em dashed green;"></span>
                <span class="col-md-9 mediaSelectDesktop" style="outline:0.00em dashed blue;"> <button class="tuhomepagebutton turegularfontsize"  @click="this.doDownloadBrochure()" >Download our brochure</button></span>
            </div>

            <div class="mediaSelectPhone col-12 tufont tutablepadding tusmallerfontsize" style="outline:0.00em dashed red; height: auto; float: left; padding-top: 1vh">
                <button class="tuhomepagebutton turegularfontsize"  @click="this.doDownloadBrochure()" >Download our brochure</button>
            </div>

            <div class="col-sm-8 col-md-8 tufont tumediumtitlebold tubestsolutiontitle" style="outline:0.0em dashed purple; height: auto">
              
            </div>
            
          </div>
           
        </div>
       
        <div class="tuwhitebox container col-10 offset-1 col-md-6 offset-md-3 " >
          <div style="height:0px; outline:0.0em dashed green;">
                <img class="smile3" alt="ThankU smile right" src="../assets/tusmileonly.png">
              </div>
          <div style="height:0px; outline:0.0em dashed green;">
              <img class="smile4" alt="ThankU smile right" src="../assets/tusmileonly.png">
            </div>
          <div class="tufont tumediumtitlebold" style="padding-left: 3vw; padding-right: 3vw; padding-bottom: 0.5vw; outline:0.0em dashed orange; height: auto">
                Testimonial of the month
          </div>
          <div class="tufont tusmallestfontsize" style="padding-left: 3vw; padding-right: 3vw; padding-top: 0.3vw; padding-bottom: 0.3vw; outline:0.0em dashed orange; ">
            "We introduced the ThankU app into our two branches of Ace Hair and Beauty Salon, in Finchley and in Golders Green. We find ThankU to be an excellent and user-friendly solution for managing tips and the ability for clients to pay tips directly to staff has transformed our service experience."
          </div>
          <div class="tufont tusmallestfontsize" style="padding-left: 3vw; padding-right: 3vw; padding-top: 0.3vw; padding-bottom: 0.3vw; outline:0.0em dashed orange; height: auto">
            "Our staff appreciate the transparency this system provides, allowing them to see who tipped and the exact amounts received and paid. This not only boosts their morale but also encourages them to continue delivering outstanding service."
          </div>  
          <div class="tufont tusmallestfontsize" style="padding-left: 3vw; padding-right: 3vw; padding-top: 0.3vw; padding-bottom: 0.3vw; outline:0.0em dashed orange; height: auto">
              "Clients are also enjoying the ThankU app, as it gives them the satisfaction of directly rewarding the team members who made their experience special."
          </div>
          <div class="tufont tusmallestfontsize" style="padding-left: 3vw; padding-right: 3vw; padding-top: 0.3vw; padding-bottom: 0.3vw; outline:0.0em dashed orange; height: auto">
            "Overall, the ThankU app has enhanced our tipping process, making it more efficient and enjoyable for everyone involved. We highly recommend it!"
          </div>

          <div style="height:0px; outline:0.0em dashed green;">
            <img class="smile5" alt="ThankU smile right" src="../assets/tusmileonly.png">
          </div>
          <div class="col-12 tusmallerfontsize" style="line-height: 18px"><br/><b>Rebwar Hazhar, Owner</b><br/>Ace Hair & Beauty, Finchley<br/>Ace Hair & Beauty, Golders Green</div>

        </div>

        <div class="col-sm-12 col-md-12 tufont tumediumtitlebold" style="padding-left: 3vw; padding-right: 6vw; padding-top: 5vw; outline:0.0em dashed orange; height: auto">
              What our clients say
        </div>
        <div class="col-sm-12 col-md-12 tufont" style="padding-left: 3vw; padding-right: 6vw; outline:0.0em dashed orange; height: auto">
          <!-- carousel came from here https://ismail9k.github.io/vue3-carousel/ as an npm install, with this site very helpful so I could work out how to operate it: https://stackblitz.com/edit/vue3-script-setup-with-vite-xgsbqz?file=src%2FApp.vue,src%2Fmain.js -->
            <Carousel :wrap-around="true" :breakpoints="this.breakpoints" :autoplay="11500">
              <Slide v-for="slide in this.testimonialslides" :key="slide.id">
                <div class="tucarousel__itemtestimonials" style="outline:0.0em dashed orange;">
                  <img class="tucarousel__itemtestimonialsimgsettingsstars" style="outline:0.0em dashed blue;" src="../assets/5starssm.png">
                  <div v-html="slide.slidetestimonial" class="tucarousel__itemtestimonialsparatestimonial" style="outline:0.0em dashed blue;"></div>
                  <div v-html="slide.slideattributed" class="tucarousel__itemtestimonialsparaattributed" style="outline:0.0em dashed blue;"></div>
                  <img class="tucarousel__itemtestimonialsimgsettings" style="box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.2); border-radius:50%; outline:0.0em dashed blue;" :src="slide.slideimage">
                    <!-- :style="{ 'width' : slide.slidewidth }" /> -->
                </div>
                <!-- <div class="tucarousel__itemtestimonials">
                  
                </div> -->
              </Slide>

              <template #addons>
                <Navigation />
                <Pagination />
              </template>
            </Carousel>
        </div>
        <div class="container" style="padding: 3vw; outline:0.0em dashed blue;">
          <div class="row" style="outline:0.0em dashed pink; " >
            <div class="col-sm-4 col-md-4 mainimageheight" style="outline:0.0em dashed green;">
              <a v-bind:href="stickerfull"><img class="mainimagewidth" style="height: auto; padding-top: 5vw" alt="Window sticker" src="../assets/stickerrounded.webp"></a>
            </div>
            <!-- <div class="col-sm-8 col-md-8 text-start" style="padding-left: 3vw; padding-right: 6vw; outline:0.0em dashed orange; height: auto"> -->
              
            <div class="col-8 col-md-8 tufont tumediumtitlebold tubestsolutiontitle" style="outline:0.0em dashed purple; height: auto">
              Features
            </div>
            <HomePageTickedListItem ListItemString="Dashboard and Account screens for detailed info on all your tips"/>
            <HomePageTickedListItem ListItemString="Tipper not present? You can give them a tipping link (in your automated emails, website, however you want), or they can use the ‘Tip without scanning’ button above, here on the website"/>
            <HomePageTickedListItem ListItemString="Tipper option to pay transaction fees, which they normally do"/>
            <HomePageTickedListItem ListItemString="Add your photo, tippers love to see you when they tip"/>
            <HomePageTickedListItem ListItemString="Suggested tipping amounts also shown in tipper’s own currency, if different"/>
            <HomePageTickedListItem ListItemString="Automatic emails for tip receipts, for payments to bank accounts, and for tip recipient’s annual report"/>
            <HomePageTickedListItem ListItemString="Works with Apple Pay and Google Pay, no need for tippers to set up"/>
            <HomePageTickedListItem ListItemString="Payment can also be made by card, simple one time input, then ThankU securely remembers for next time (user option)"/>
          </div>

          <div class="row col-sm-12 col-md-8 offset-md-4 tufont tutablepadding text-start tusmallerfontsize" style="outline:0.00em dashed red; height: auto; float: left; padding-top: 1vh">
              <span class="col-9 offset-2 col-md-10 offset-md-2 tufont;" style="outline:0.00em dashed green;">ThankU is available at <b>no cost to businesses</b> - fees are payable on tip transactions, most often paid by the tipper, but when not, fees are deducted from the tip. For FAQ on receiving tips, please see <span class="text-only-button-new cancel" @click="doGetTipsShowMoreInfoNew()" style="pointer: cursor"><b>here</b></span>, and specifically on fees, please see <span class="text-only-button-new cancel" @click="doGetTipsShowMoreInfoFeesNew()" style="pointer: cursor"><b>here</b></span>. Alternatively, <span class="text-only-button-new cancel lt-blue" style="pointer: cursor"><b><u><a href="https://www.thanku.app/introtothanku.pdf" target="_self">download our brochure</a></u></b></span> and full fee information is set out on page 12. </span>
          </div>

          <div class="row col-sm-12 col-md-8 offset-md-4 tufont tutablepadding text-start tusmallerfontsize" style="outline:0.00em dashed red; height: auto; padding-top: 1vh">
              <span class="col-9 offset-2 col-md-10 offset-md-2 tufont;" style="outline:0.00em dashed green;">
              <div class="bottombuttonwidth" style="padding-top: 1vh; margin: auto;">
                <span></span><button class="tuhomepagesignupbutton turegularfontsize" style="width: 100%;"  @click="this.doShowGetTipsStartNew();" >Get set up for free!</button>
              </div>
             
              </span>
          </div>

     
        </div>
      </div>
      <br>
      <HomeFooter @do-show-home="doShowHome" @do-show-contact="doShowContactNew" @do-show-about="doShowAboutNew" @do-show-privacy="doShowPrivacyNew" @do-show-terms="doShowTermsNew" />
    </div>
  </div>
  <div class="topcontent-div" v-show="this.showOld === true">
    <div v-show="this.showHome" class="wrapper-todiv" ref="masterDiv" >

      <table width="100%" cellpadding=0 cellspacing=0 border=0>
        <tr v-if="this.showHomePageFromGoogleAd === false">
          <td width="30%" @click="this.doShowTestimonials()" > 
            <img v-if="showImages === true" style="width: 70%; border-radius:10%" alt="Testimonials" src="../assets/5starssm.png"><span class="text-only-button cancel" style="overflow-wrap: break-word;"><br>Testimonials</span>
          </td>
           <td width="40%" >
              <div class="wrapper-todiv" style="height: 10vh">
                <div style="" >
                  <img class="tulogo"  style="height: 7vh;" alt="ThankU logo" src="../assets/tutxtlogo.png">
                </div>
                
              </div>
            </td>
          <td width="30%" @click="this.doShowAccount()" style="cursor:pointer;">
            <img v-if="showImages === true" style="width:45%; border-radius:50%"  alt="Account settings" :src="userImageDataComputed"><span class="notesInfoText" style="font-size: clamp(2px, 1.5vh, 12px); overflow-wrap: break-word; "><br>{{ this.loggedInUserDisplayname }}</span>
          </td>
        </tr>
        <!-- <tr v-if="this.showHomePageFromGoogleAd === false">
          <td colspan="3" style="padding-top: clamp(2px, 1.5vh, 12px)">
            
            <span style="font-size: 90%">cashless tipping, <b>no download required!</b></span>
          </td>
        </tr> -->
        <tr v-if="this.showHomePageFromGoogleAd === false" @click="this.doShowSalons();">
          <td colspan="3" style="padding-top: clamp(2px, 2vh, 18px); padding-bottom: clamp(1px, 0.3vh, 7px);">
            <div class="userinfoframe" style="height: clamp(2px, 18vh, 140px); padding-top: clamp(1px, 0.5vh, 14px); padding-bottom: clamp(1px, 0.5vh, 14px);">
              <table border=0 cellspacing=0 cellpadding=0 >
                <tr>
                  <td style="font-size: clamp(8px, 2vh, 14px); color: #000; padding-bottom: 0px; padding-left: clamp(1px, 1vh, 8px); padding-right: clamp(1px, 1vh, 8px)"><b>Find the ThankU <h1 style="font-size: 100%; display: inline; color: #000; padding-bottom: 0px; padding-left: 0px; padding-right: 0px"><b>Cashless Tipping app</b></h1> at</b>
                  </td>
                </tr>
                <tr>
                  <td width=100% style="display: flex; align-items: center; justify-content: space-evenly; padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(1px, 1vh, 8px)">
                    <img v-if="showImages === true" style="width: 29%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px)" alt="Larry King" src="../assets/larrykingbettervert.webp">
                    <img v-if="showImages === true" style="width: 34%; padding-left: clamp(2px, 3vh, 24px)" alt="Josh Wood Colour" src="../assets/jwcsm.webp">
                    <table border=0 cellpadding=0 cellspacing=0 style="display: column; align-items: center;">
                      <tr>
                        <td>
                          <img v-if="showImages === true" style="width: 80%; padding-left: clamp(1px, 1vh, 8px); padding-top: clamp(1px, 1vh, 8px)" alt="Edward James" src="../assets/edwardjamesvertsm.webp">
                        </td>
                      </tr>
                      <tr>
                        <td style="text-align: top; justify-content: top; cursor: pointer">
                          <span @click="this.doShowSalons();" class="cancel" >more...</span>
                        </td>
                      </tr>
                    </table>              
                  </td>
                </tr>
              </table>
            </div>  
          </td>
        </tr>
        
        <tr v-if="this.showHomePageFromGoogleAd === false">
          <td colspan="3">
            
            <!-- <div class="actionbtn-div">
              <button @click="this.doShowGive" class="actionbtn" style="width: 100%; height: clamp(2px, 8vh, 70px); " ref="give-tips">Give Tips</button>
            </div>
             <div class="actionbtn-div">
              <button @click="this.doShowGet" class="actionbtn" style="width: 100%; height: clamp(2px, 8vh, 70px); " ref="give-tips">Get Tips</button>
            </div> -->
            <br>
            <b>Left the salon?</b> <br>You can still tip with the button below
            <div class="actionbtn-div" style="padding-top: 2vh; padding-bottom: 2vh">
              <button @click="this.doShowTipWithoutScanning" class="widebtn" style="width: 80%; height: clamp(2px, 7vh, 60px); ">Tip without scanning</button>
            </div>

          </td>
        </tr>
        
         <tr><td colspan="3" style="padding-top: clamp(2px, 2vh, 18px)">
          <div class="userinfoframe actionbtn-div notesInfoTextLarger" style="padding-bottom: clamp(1px, 1vh, 8px); padding-top: clamp(1px, 1vh, 8px)" > 
            <table>
              <tr>
                <td colspan="3" style="padding-top: clamp(1px, 1vh, 8px);">
                <img v-if="this.showHomePageFromGoogleAd === true" class="tulogo" style="height: 10vh; padding-bottom: 1vh" alt="ThankU Cashless Tipping App" src="../assets/tutxtlogo.png"><br v-if="this.showHomePageFromGoogleAd === true" >
                  <b><span style="font-size: clamp(12px, 5vh, 38px); font-family: &quot;Arial&quot;,sans-serif; color: rgb(94, 144, 227);">Tipping solved</span></b>
              </td>
              </tr>
              <tr>
                <td style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 1vh, 2px);">Introducing the most seamless and efficient cashless tipping solution. No app download or tipper registration needed. With a quick scan of the ThankU QR code, your clients can leave a tip in 10 seconds or less.
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <div class="actionbtn-div" style="padding-top: 1vh; padding-bottom: 2vh">
                    <button @click="this.doDownloadBrochure" class="widebtn" style="width: 80%; height: clamp(2px, 7vh, 60px); ">Download our brochure to learn more</button>
                  </div>

                </td>
              </tr>

              <tr style="cursor:pointer">
                <td @click="doDownloadBrochure();" style="cursor: pointer;">           
                  
                  <img style="width: 90%; cursor:pointer" alt="cashless tipping" src="../assets/introcover.webp">
                
              </td>
              </tr>
                            <tr>
                <td style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);">After reviewing the badge media options below, simply click the 'Get Set Up for FREE' button, let us know the quantities of each format you need, and provide the shipping address – and you're all set!
                </td>
              </tr>
              <tr>
                <td style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);">To get you started, we provide a complimentary Front Desk Stand that introduces ThankU to your clients. You can then choose between two additional free options listed below.
                </td>
              </tr>
              <tr>
                <td colspan="3" style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: 0vh"><a v-bind:href="pos2cropped"><img ref="pos2croppedImg" height=47 width=48 style="cursor:pointer" alt="ThankU Cashless Tipping App" v-bind:src="pos2croppedsm"></a>                 
                </td>
              </tr>
              <tr>
                <td style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(2px, 3vh, 24px); padding-bottom: clamp(2px, 3vh, 24px);">In hair and beauty salons, clients often wish to tip multiple people at once—sometimes 2 or even 3. No problem! The 'Tip Again' button is designed specifically for that, making the process incredibly fast and effortless.
                </td>
              </tr>
              <tr v-if="this.showHomePageFromGoogleAd === true" @click="this.doShowSalons();">
                <td colspan="3" style="padding-top: clamp(2px, 2vh, 18px); padding-bottom: clamp(1px, 0.3vh, 7px);">
                  <div class="userinfoframe" style="height: clamp(2px, 15vh, 120px); padding-top: clamp(1px, 0.5vh, 14px); padding-bottom: clamp(1px, 0.5vh, 14px);">
                    <table border=0 cellspacing=0 cellpadding=0 >
                      <tr>
                        <td style="font-size: clamp(8px, 2vh, 14px); color: #000; padding-bottom: 0px; padding-left: clamp(1px, 1vh, 8px); padding-right: clamp(1px, 1vh, 8px); padding-top: 0px"><b>Find the ThankU <h1 style="font-size: 100%; display: inline; color: #000; padding-bottom: 0px; padding-left: 0px; padding-right: 0px">Cashless Tipping app</h1> at</b>
                        </td>
                      </tr>
                      <tr>
                        <td width=100% style="display: flex; align-items: center; justify-content: space-evenly; padding-top: clamp(1px, 1vh, 8px)">
                          <img v-if="showImages === true" style="width: 22%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px)" alt="Larry King" src="../assets/larrykingbettervert.webp">
                          <img v-if="showImages === true" style="width: 28%; padding-left: clamp(2px, 3vh, 24px)" alt="Josh Wood Colour" src="../assets/jwcsm.webp">
                          <table border=0 cellpadding=0 cellspacing=0 style="display: column; align-items: center;">
                            <tr>
                              <td>
                                <img v-if="showImages === true" style="width: 80%; padding-left: clamp(1px, 1vh, 8px); padding-top: clamp(1px, 1vh, 8px)" alt="Edward James" src="../assets/edwardjamesvertsm.webp">
                              </td>
                            </tr>
                            <tr>
                              <td style="text-align: top; justify-content: top; cursor: pointer">
                                <span @click="this.doShowSalons();" class="cancel" >more...</span>
                              </td>
                            </tr>
                          </table>              
                        </td>
                      </tr>
                    </table>
                  </div>  
                </td>

              </tr>
              <tr v-if="this.showHomePageFromGoogleAd === true">
                <td style="height: 10vh;" @click="this.doShowTestimonials()" > 
                  <img v-if="showImages === true" style="width: 30%; border-radius:10%" alt="Testimonials" src="../assets/5starssm.png"><span class="text-only-button cancel" style="overflow-wrap: break-word;"><br>Check out our Testimonials</span>
                </td>
                </tr>
            </table>
          </div>
        </td></tr>
         <tr><td colspan="3" >
          <div class="actionbtn-div notesInfoText" style="">
          </div>
        </td></tr>

        <tr id="howtogetthanku"><td colspan="3" style="padding-top: clamp(2px, 2vh, 18px)">
          <div class="userinfoframe actionbtn-div notesInfoTextLarger" style="padding-bottom: clamp(1px, 1vh, 8px); padding-top: clamp(1px, 1vh, 8px)"> 
            <table>
              <tr>
                <td colspan="3" style="padding-top: clamp(1px, 1vh, 8px);">
                <b><span style="font-size: 13.5pt; font-family: &quot;Arial&quot;,sans-serif; color: rgb(94, 144, 227);">How to get thankU</span></b>
              </td>
              </tr>
              <tr>
                <td style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 1vh, 24px);">Simply contact us, and we'll provide your ThankU QR badge media—currently free of charge.<br><br>We'll also send you an email to forward to your staff, explaining how they can connect to the ThankU QR badge in under a minute.<br><br>It's that easy! You can be fully set up by tomorrow, depending on Royal Mail delivery.
                </td>
              </tr>
              <!-- <tr> -->
                <!-- <td style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: 0px; padding-bottom: clamp(2px, 3vh, 24px);"> -->
                  <!-- <div class="actionbtn-div"> -->
                    <!-- <button @click="this.doShowGetTipsStart" class="widebtn" style="width: 80%; height: clamp(2px, 7vh, 60px); ">Get started</button> -->
                  <!-- </div> -->
                <!-- </td> -->
              <!-- </tr> -->
              <tr>
                <td>
                  <div class="actionbtn-div" style="padding-top: 1vh; width: 95%; margin: auto;">
                    <span class="lt-blue" ></span><button class="widebtn" style="width: 100%; height: 7vh;"  @click="this.doOrderBadges();" >Get set up for FREE</button>
                  </div>
                </td>
              </tr>
              
              <tr>
                <td style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);"><br><b>Option 1: Tabletop Stand</b> - Our Tabletop Stands are designed to be placed directly on the tabletop in front of the client. This discreet option allows clients to see the stand and inquire, "What’s that?"—to which your stylist can respond, "We have clients requesting cashless tipping, and this is our solution." The stand measures 6 x 4.3 cm.
                </td>
              </tr>
              <CountersquareDiv v-if="this.hasScrolled !== false" :VW='this.VW' />

              <tr>
                <td style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(2px, 2vh, 24px); padding-bottom: clamp(2px, 0vh, 24px);">Clients can effortlessly scan your ThankU QR code, select the person they wish to tip from the list, choose an amount, and pay using Apple Pay, Google Pay (if already set up on their phone), or a credit/debit card. The initial setup may take a few seconds longer, but subsequent transactions are quick and straightforward.</td>
              </tr>
              <tr>
                <td style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 1vh, 24px);"><br><b>Option 2: 'No Glue' Mirror Sticker</b> - easily mount this on a mirror facing the client for greater visibility and impact. No glue is required, and it can be easily removed. The dimensions are 12 cm x 8 cm.
                </td>
              </tr>
              <StickerDiv v-if="this.hasScrolled !== false" :VW='this.VW' />
              <tr>
                <td>
                  <div class="actionbtn-div" style="padding-top: 1vh; width: 95%; margin: auto;">
                    <span class="lt-blue" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.9 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse * 0.9 + 'px', }"></span><button class="widebtn" style="width: 100%; height: 7vh;"  @click="this.doOrderBadges();" >Get set up for FREE</button>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </td></tr>
        
         <tr><td colspan="3" >
          <p class="MsoNormal" style="margin-bottom: 0.0001pt; line-height: normal;"><b><span style="font-size: 13.5pt; font-family: &quot;Arial&quot;,sans-serif; color: rgb(94, 144, 227);"></span></b></p>
        </td></tr>

        <tr id="benefits"><td colspan="3" style="padding-top: clamp(2px, 2vh, 18px)">
          <div class="userinfoframe actionbtn-div notesInfoTextLarger" style="padding-bottom: clamp(1px, 1vh, 8px); padding-top: clamp(1px, 1vh, 8px)"> 
            <table width=100% border=0>
              <tr>
                <td colspan="3" style="padding-top: clamp(1px, 1vh, 8px);">
                <b><span style="font-size: 13.5pt; font-family: &quot;Arial&quot;,sans-serif; color: rgb(94, 144, 227);">Benefits</span></b>
              </td>
              </tr>
              <tr>
                <td colspan="1" style="background: #FFFFFF; width: 25%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divr"><img style="width: clamp(20px, 45%, 35px); cursor:none" alt="yes" src="../assets/tick1sm.webp"></td>
                <td colspan="2" style="width: 75%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divl">Tips direct to staff
                </td>
              </tr>
              <tr>
                <td colspan="1" style="background: #FFFFFF; width: 25%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divr"><img style="width: clamp(20px, 45%, 35px); cursor:none" alt="yes" src="../assets/tick1sm.webp"></td>
                <td colspan="2" style="width: 75%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divl">Bypasses the business, HMRC compliant
                </td>
              </tr>
              <tr>
                <td colspan="1" style="background: #FFFFFF; width: 25%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divr"><img style="width: clamp(20px, 45%, 35px); cursor:none" alt="yes" src="../assets/tick1sm.webp"></td>
                <td colspan="2" style="width: 75%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divl">Zero management
                </td>
              </tr>
              <tr>
                <td colspan="1" style="background: #FFFFFF; width: 25%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divr"><img style="width: clamp(20px, 45%, 35px); cursor:none" alt="yes" src="../assets/tick1sm.webp"></td>
                <td colspan="2" style="width: 75%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divl">Easy, secure and fast
                </td>
              </tr>
              <tr>
                <td colspan="1" style="background: #FFFFFF; width: 25%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divr"><img style="width: clamp(20px, 45%, 35px); cursor:none" alt="yes" src="../assets/tick1sm.webp"></td>
                <td colspan="2" style="width: 75%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divl">Superior and fully transparent reporting
                </td>
              </tr>
              <tr>
                <td colspan="1" style="background: #FFFFFF; width: 25%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divr"><img style="width: clamp(20px, 45%, 35px); cursor:none" alt="yes" src="../assets/tick1sm.webp"></td>
                <td colspan="2" style="width: 75%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divl">Great support (very rarely needed) – by email and phone
                </td>
              </tr>
              <tr>
                <td colspan="1" style="background: #FFFFFF; width: 25%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divr"><img style="width: clamp(20px, 45%, 35px); cursor:none" alt="yes" src="../assets/tick1sm.webp"></td>
                <td colspan="2" style="width: 75%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divl">Happy clients, happy staff, happy management
                </td>
              </tr>
              <!-- <tr> -->
                <!-- <td colspan="3" style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: 0px; padding-bottom: clamp(2px, 3vh, 24px);"> -->
                  <!-- <div class="actionbtn-div"> -->
                    <!-- <button @click="this.doShowGetTipsStart" class="widebtn" style="width: 80%; height: clamp(2px, 7vh, 60px); ">Get started</button> -->
                  <!-- </div> -->
                <!-- </td> -->
              <!-- </tr> -->
              <tr>
                <td colspan=2>
                  <div class="actionbtn-div" style="padding-top: 1vh; width: 95%; margin: auto;">
                    <span class="lt-blue" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.9 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse * 0.9 + 'px', }"></span><button class="widebtn" style="width: 100%; height: 7vh;"  @click="this.doOrderBadges();" >Get set up for FREE</button>
                  </div>
                </td>
              </tr>
              
            </table>
          </div>
        </td></tr>
        
         <tr><td colspan="3" >
          <p class="MsoNormal" style="margin-bottom: 0.0001pt; line-height: normal;"><b><span style="font-size: 13.5pt; font-family: &quot;Arial&quot;,sans-serif; color: rgb(94, 144, 227);"></span></b></p>
        </td></tr>

               
         <tr id="testimonials"><td colspan="3" style="padding-top: clamp(2px, 2vh, 18px)">
          <div class="userinfoframe actionbtn-div notesInfoTextLarger" style="padding-bottom: clamp(1px, 1vh, 8px); padding-top: clamp(1px, 1vh, 8px)"> 
            <table>
              <tr>
                <td colspan="3" style="padding-top: clamp(1px, 1vh, 8px);">
                <b><span style="font-size: 13.5pt; font-family: &quot;Arial&quot;,sans-serif; color: rgb(94, 144, 227);">What our clients say about thankU:</span></b>
              </td>
              </tr>
              <tr>
                <td style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);">“Our experience has been excellent and we get great feedback from clients and staff” Bobby Collier, Manager, Larry King, South Kensington, London
                </td>
              </tr>
              <tr>
                <td style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);">“Amazing app. I use this in my spa in Kent. Both our clients and my team love it. It's simple to use and enables the staff to receive tips direct to their bank accounts, cutting out all the technical faff businesses face when taking tips through their normal payment system. Adrian is also super quick to help if you have any queries!” Elizabeth Dadson, owner, Aquavie Boutique Spa, Kent
                </td>
              </tr>
              <tr>
                <td style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);">“I got this sorted for my salon in West London… definitely recommend, really good at getting back to you when setting it up as well, all very fast! HIGHLY RECOMMEND!” Ruby Byrne, owner, Ruby B Organic, London
                </td>
              </tr>
              <tr>
                <td style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(2px, 3vh, 24px); padding-bottom: clamp(2px, 3vh, 24px);">“Fantastic way for our team to not miss out on tips in a world where less people are carrying cash. Our clients absolutely love how easy it is too!” Marcello Ferri, Manager, Collections Hair Club, Weybridge
                </td>
              </tr>
              <tr>
                <td style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(2px, 3vh, 24px); padding-bottom: clamp(2px, 3vh, 24px);">“I love the ThankU app ! I’m so glad I found it for my hairdressing salon business. I was always struggling with clients wanting to add a tip for the stylist onto to their bill when paying by card … so to find a solution where the money completely bypassed me as a salon owner was such a relief! I’d 100% recommend the ThankU app- it’s so easy to use and the customer service and direct help you get is amazing. Every salon business should get the ThankU app on their reception desks !” – Victoria Williams, owner, Head Office, Wrexham
                </td>
              </tr>
              <tr>
                <td style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(2px, 3vh, 24px); padding-bottom: clamp(2px, 3vh, 24px);">“Our staff and clients love ThankU Cashless Tipping, it’s going really well thank you ThankU team!” – Aneeqa, salon manager at TONI&GUY Westfield, Stratford City
                </td>
              </tr>
              <tr>
                <td style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(2px, 3vh, 24px); padding-bottom: clamp(2px, 3vh, 24px);">“All of the staff and clients are very much enjoying having the ‘thankU’ service 😊” – Tash Bishop, salon manager,  Salon41, Bristol
                </td>
              </tr>
              <tr>
                <td style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(2px, 3vh, 24px); padding-bottom: clamp(2px, 3vh, 24px);">“Duck & Dry has ThankU at our Chelsea, Mayfair and Soho sites – clients and staff are very happy, the whole tipping ‘issue’ has just gone away with ThankU – and it requires zero management!”  - Rosie Rowley, Salon Group Manager, Duck & Dry, London
                </td>
              </tr>
              <tr>
                <td @click="this.doShowTestimonials()" style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(2px, 3vh, 24px); padding-bottom: clamp(2px, 3vh, 24px);">
                  
                  <img v-if="showImages === true" style="width: 30%; border-radius:10%" alt="Testimonials" src="../assets/5starssm.png"><span class="text-only-button cancel" style="overflow-wrap: break-word;"><br><b>see our testimonials and reviews page</b></span>
                </td>
              </tr>
            </table>
          </div>
        </td></tr>
         <tr><td colspan="3" >
          <div class="actionbtn-div notesInfoText" style="">
          </div>
        </td></tr>

        <tr id="features"><td colspan="3" style="padding-top: clamp(2px, 2vh, 18px)">
          <div class="userinfoframe actionbtn-div notesInfoTextLarger" style="padding-bottom: clamp(1px, 1vh, 8px); padding-top: clamp(1px, 1vh, 8px)"> 
            <table width=100% border=0>
              <tr>
                <td colspan="3" style="padding-top: clamp(1px, 1vh, 8px);">
                <b><span style="font-size: 13.5pt; font-family: &quot;Arial&quot;,sans-serif; color: rgb(94, 144, 227);">Features</span></b>
              </td>
              </tr>
              <tr>
                <td colspan="1" style="background: #FFFFFF; width: 25%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divr"><img style="width: clamp(20px, 45%, 35px); cursor:none" alt="yes" src="../assets/tick1sm.webp"></td>
                <td colspan="2" style="width: 75%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divl">Dashboard and Account screens for detailed info on all your tips
                </td>
              </tr>
              <tr>
                <td colspan="1" style="background: #FFFFFF; width: 25%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divr"><img style="width: clamp(20px, 45%, 35px); cursor:none" alt="yes" src="../assets/tick1sm.webp"></td>
                <td colspan="2" style="width: 75%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divl">Tipper not present? You can give them a tipping link (in your automated emails, website, however you want), or they can use the ‘Tip without scanning’ button above, here on the website
                </td>
              </tr>
              <tr>
                <td colspan="1" style="background: #FFFFFF; width: 25%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divr"><img style="width: clamp(20px, 45%, 35px); cursor:none" alt="yes" src="../assets/tick1sm.webp"></td>
                <td colspan="2" style="width: 75%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divl">Tipper option to pay transaction fees, which they normally do
                </td>
              </tr>
              <tr>
                <td colspan="1" style="background: #FFFFFF; width: 25%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divr"><img style="width: clamp(20px, 45%, 35px); cursor:none" alt="yes" src="../assets/tick1sm.webp"></td>
                <td colspan="2" style="width: 75%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divl">Add your photo, tippers love to see you when they tip
                </td>
              </tr>
              <tr>
                <td colspan="1" style="background: #FFFFFF; width: 25%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divr"><img style="width: clamp(20px, 45%, 35px); cursor:none" alt="yes" src="../assets/tick1sm.webp"></td>
                <td colspan="2" style="width: 75%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divl">Suggested tipping amounts also shown in tipper’s own currency, if different
                </td>
              </tr>
              <tr>
                <td colspan="1" style="background: #FFFFFF; width: 25%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divr"><img style="width: clamp(20px, 45%, 35px); cursor:none" alt="yes" src="../assets/tick1sm.webp"></td>
                <td colspan="2" style="width: 75%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divl">Automatic emails for tip receipts, for payments to bank accounts, and for tip recipient’s annual report
                </td>
              </tr>
              <tr>
                <td colspan="1" style="background: #FFFFFF; width: 25%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divr"><img style="width: clamp(20px, 45%, 35px); cursor:none" alt="yes" src="../assets/tick1sm.webp"></td>
                <td colspan="2" style="width: 75%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divl">Works with Apple Pay and Google Pay, no need for tippers to set up
                </td>
              </tr>
              <tr>
                <td colspan="1" style="background: #FFFFFF; width: 25%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divr"><img style="width: clamp(20px, 45%, 35px); cursor:none" alt="yes" src="../assets/tick1sm.webp"></td>
                <td colspan="2" style="width: 75%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divl">Payment can also be made by card, simple one time input, then ThankU securely remembers for next time (user option)
                </td>
              </tr>
              <!-- <tr> -->
                <!-- <td colspan="3" style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: 0px; padding-bottom: clamp(2px, 3vh, 24px);"> -->
                  <!-- <div class="actionbtn-div"> -->
                    <!-- <button @click="this.doShowGetTipsStart" class="widebtn" style="width: 80%; height: clamp(2px, 7vh, 60px); ">Get started</button> -->
                  <!-- </div> -->
                <!-- </td> -->
              <!-- </tr> -->
              <tr>
                <td colspan=2 style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);">ThankU is available at <b>no cost to businesses</b> - fees are payable on tip transactions, most often paid by the tipper, but when not, fees are deducted from the tip. For FAQ on receiving tips, please see <span class="text-only-button cancel" @click="doGetTipsShowMoreInfoNew()" style="pointer: cursor"><b>here</b></span>, and specifically on fees, please see <span class="text-only-button cancel" @click="doGetTipsShowMoreInfoFeesNew()" style="pointer: cursor"><b>here</b></span>. Alternatively, <span class="text-only-button cancel lt-blue" style="pointer: cursor"><b><u><a href="https://www.thanku.app/introtothanku.pdf" target="_self">download our brochure</a></u></b></span> and full fee information is set out on page 12. 
                </td>
              </tr><tr>
                <td colspan=2>
                  <div class="actionbtn-div" style="padding-top: 1vh; width: 95%; margin: auto;">
                    <span class="lt-blue" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.9 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse * 0.9 + 'px', }"></span><button class="widebtn" style="width: 100%; height: 7vh;"  @click="this.doOrderBadges();" >Get set up for FREE</button>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </td></tr>
        
        <tr id="features"><td colspan="3" style="padding-top: clamp(2px, 2vh, 18px)">
          <div class="userinfoframe actionbtn-div notesInfoTextLarger" style="padding-bottom: clamp(1px, 1vh, 8px); padding-top: clamp(1px, 1vh, 8px)"> 
            <table width=100% border=0>
              <tr>
                <td colspan="2" style="padding-top: clamp(1px, 1vh, 8px);">
                <b><span style="font-size: 13.5pt; font-family: &quot;Arial&quot;,sans-serif; color: rgb(94, 144, 227);">Frequently Asked Questions (FAQ)</span></b>
              </td>
              </tr>
              <br>
              <tr>
                <td colspan="1" style="background: #FFFFFF; width: 50%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divc"><span class="text-only-button cancel" @click="doGiveTipsShowMoreInfoNew()" style="pointer: cursor"><b>FAQ Tippers</b></span></td>
                <td colspan="1" style="width: 50%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divc"><span class="text-only-button cancel" @click="doGetTipsShowMoreInfoNew()" style="pointer: cursor"><b>FAQ Recipients</b></span></td>
              </tr>

            </table>
          </div>
        </td></tr>

         <tr><td colspan="3" >
          <p class="MsoNormal" style="margin-bottom: 0.0001pt; line-height: normal;"><b><span style="font-size: 13.5pt; font-family: &quot;Arial&quot;,sans-serif; color: rgb(94, 144, 227);"></span></b></p>
        </td></tr>

        <tr>
          <td colspan="3" style="padding-top: clamp(2px, 1.5vh, 12px)">
            <table width="100%">
              <tr>
                <td width="25%">
                  <button class="text-only-button cancel" @click="this.doShowContact();">Contact</button>
                </td>
                <td width="25%">
                  <button class="text-only-button cancel" @click="this.doShowAbout();">About</button>
                </td>
                <td width="25%">
                  <button class="text-only-button cancel" @click="this.doShowTerms();">Terms</button>
                </td>
                <td width="25%">
                  <button class="text-only-button cancel" @click="this.doShowPrivacy();">Privacy</button>
                </td>      
              </tr>
              <tr>
                <td colspan="4" style="padding-top: 0.clamp(2px, 2vh, 18px)">
                  <span style="font-size: 60%; color: #000012">Copyright 2022 Appsanely Limited trading as ThankU Cashless Tipping</span>
                </td>
              </tr>
            </table>
          </td>
                         
        </tr>
      </table>
      <LoadParseFromHome :key="loadParseToggle" v-if="this.loadParseToggle !== false" :functionName=functionName :paramsObject=paramsObject @return-parent-json="returnParseJSON"/>
      <!-- <LoadLogRocketFromHome :key="loadLogRocketToggle" v-if="this.loadLogRocketToggle !== false" :functionName=functionName :paramsObject=paramsObject @return-parent-json="returnParseJSON"/> -->
    </div>
    <!-- <router-link to="givetips"> Give Tips</router-link> -->
    
  </div>
</template>

<script>

import { defineAsyncComponent } from 'vue'

import pos2croppedsm from "../assets/pos2croppedsm2.webp";
// import pos2croppedsm from "../assets/pos2croppedsm2.webp";
import pos2cropped from "../assets/pos2cropped.webp";
// import pos2cropped from "../assets/pos2cropped.webp";

import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

const NavigationBar = defineAsyncComponent({loader: () =>
  import('../components/NavigationBar.vue')}
)

const CountersquareDiv = defineAsyncComponent({loader: () =>
  import('../components/CountersquareDiv.vue')}
)

const StickerDiv = defineAsyncComponent({loader: () =>
  import('../components/StickerDiv.vue')}
)

const HomePageTickedListItem = defineAsyncComponent({loader: () =>
  import('../components/HomePageTickedListItem.vue')}
)

const HomeFooter = defineAsyncComponent({loader: () =>
  import('../components/HomeFooter.vue')}
)



export default {
  inject: ['showOld', 'globalPushForwardInterval', 'devEnv', 'appId', 'appJSKey'] ,
  components: {
    CountersquareDiv,
    StickerDiv,
    LoadParseFromHome: defineAsyncComponent(() =>
      import('../components/LoadParseFromHome.vue')
    ),
    NavigationBar,
    Carousel,
    Slide, 
    Pagination, 
    Navigation,
    HomePageTickedListItem,
    HomeFooter,
    // LoadLogRocketFromHome: defineAsyncComponent(() =>
    //   import('../components/LoadLogRocketFromHome.vue')
    // )
  },
  data() {
    return {
      showHome: true,
      loggedInUserDisplayname: "",
      showImages: false,
      pos2croppedsm: pos2croppedsm,
      // pos2croppedsm: pos2croppedsm,
      pos2cropped: pos2cropped,
      // pos2cropped: pos2cropped,
      hasScrolled: false,
      componentKeyTip: false,
      showHomePageFromGoogleAd: false,
      VW: 0,
      loadParseToggle: false,
      // loadLogRocketToggle: false,
      vhMult: 1,
      vhMultFont: 1,
      fontSizeNormal: 2.5, // vh
      fontSizeNormalInPXToUse: "",
      fontSizeNormalLineHeight: 3.3,// vh
      fontSizeNormalInPXLineHeightToUse: "",
      slides: [],
      testimonialslides: [],
      breakpoints: {
        // 700px and up
        700: {
          itemsToShow: 2,
          snapAlign: 'center',
        },
        // 1024 and up
        1024: {
          itemsToShow: 3.5,
          snapAlign: 'start',
        },
      },
    };
  },
  computed: {
    userImageDataComputed() {
        if (window.localStorage.getItem("userPhotoFileData") !== null) {
            // so the is we want to ensure we have the correct photo, and if the user switches devices (edge case) that won't be the case. We could easily sort this to check if the filename in the user's record was the same as in the localStorage but that is an expensive call. So compromise is to assume that the user is going to do something - either go to account or make a tip and we will refresh local storage at that point
            // ////console.log("userPhotoFileData::: " + window.localStorage.getItem("userPhotoFileData") );
            // /alert("COMPUTED from localStorage userPhotoFileData: " + window.localStorage.getItem("userPhotoFileData"));
            return window.localStorage.getItem("userPhotoFileData");
        } else {
            // /alert("simply returned accountperson.png");
            return require('@/assets/accountperson.png'); 
        }
    },
    frontdeskfull(){
     
      return require('@/assets/frontdeskfull.jpg'); 
    }, 
    stickerfull(){
     
      return require('@/assets/stickerfull.jpg'); 
    } 
  },
  methods: {
    doOrderBadges(origin){

      if (origin === undefined){
        origin = "HOME";
      }
      
      let url = "mailto:mailorders@thanku.app?subject=get ThankU enquiry&body=Please fill out below - we will email you back to confirm your order and let you know if we need any further information before dispatching it - please respond below:%0D%0A%0D%0AWhat is your business name?%0D%0A%0D%0AAre you a salon? If not, what type of business please?                  %0D%0A%0D%0AWould you like our 'No Glue' Mirror Stickers option in addition to your Front Desk Stand? If so, how many stickers would you like? (if a salon, we suggest one for every client seat mirror)    %0D%0A%0D%0AOr - instead - would like to receive just our super discreet Tabletop Stand? and if yes, how many would you like?                  %0D%0A%0D%0AIf you are not a salon and would like different QR media (e.g. wearables), let us know and we will email you to ask a bit more about your business..                  %0D%0A%0D%0AWhich address should send we send your ThankU QR media to?                  %0D%0A%0D%0A%0D%0AThank you! best wishes, the ThankU Team";
      
      // console.log("tapped get free ThankU badges button on HOME");

      let LRObjectId = undefined;

      if (window.localStorage.getItem("tuob") !== null) {
        LRObjectId = window.localStorage.getItem("tuob");
      } else if (window.localStorage.getItem("logRocketUserTimeStampId") !== null) {
        LRObjectId = window.localStorage.getItem("logRocketUserTimeStampId");
      } else {
        window.localStorage.setItem("logRocketUserTimeStampId", new Date().getTime());
        LRObjectId = window.localStorage.getItem("logRocketUserTimeStampId");
      }
      
      let googleAdsOriginalReferrerURL = window.localStorage.getItem("googleAdsOriginalReferrerURL") !== undefined ? window.localStorage.getItem("googleAdsOriginalReferrerURL") : "n/a";
      let params = {
        toEmail: "appalert@thanku.app",
        subject: "GREAT! Someone tapped get free ThankU badges button on " + origin ,
        body: {
          type: "TappedOrderThankUBadges",
          googleAdsOriginalReferrerURL: googleAdsOriginalReferrerURL,
          referrerURL: window.localStorage.getItem("referrerURL"),
          LRObjectId: LRObjectId
        },
      };

      this.loadParse = false;
      this.loadParseToggle = !this.loadParseToggle;
      this.functionName = "sendEmail";
      this.paramsObject = params;
      this.loadParse = true;

      window.open(url);


    },
    doDownloadBrochure(){
      const url = "https://www.thanku.app/introtothanku.pdf"
      // console.log("opening pdf");

      // this.$refs["brochureLink"].style.color = "purple";
      // window.localStorage.setItem("downloadBrochureLinkColor", "purple");

      let LRObjectId = undefined;

      if (window.localStorage.getItem("tuob") !== null) {
        LRObjectId = window.localStorage.getItem("tuob");
      } else if (window.localStorage.getItem("logRocketUserTimeStampId") !== null) {
        LRObjectId = window.localStorage.getItem("logRocketUserTimeStampId");
      } else {
        window.localStorage.setItem("logRocketUserTimeStampId", new Date().getTime());
        LRObjectId = window.localStorage.getItem("logRocketUserTimeStampId");
      }


      let googleAdsOriginalReferrerURL = window.localStorage.getItem("googleAdsOriginalReferrerURL") !== undefined ? window.localStorage.getItem("googleAdsOriginalReferrerURL") : "n/a";
      const params = {
        toEmail: "appalert@thanku.app",
        subject: "GREAT! Someone downloaded ThankU pdf brochure FROM HOME PAGE" ,
        body: {
          type: "TappedDownloadThankUBrochure",
          googleAdsOriginalReferrerURL: googleAdsOriginalReferrerURL,
          referrerURL: window.localStorage.getItem("referrerURL"),
          LRObjectId: LRObjectId
        },
      };
      this.loadParse = false;
      this.loadParseToggle = !this.loadParseToggle;
      this.functionName = "sendEmail";
      this.paramsObject = params;
      this.loadParse = true;

      // let params2 = (window.localStorage.getItem("referrerURL") !== null && window.localStorage.getItem("referrerURL") !== undefined && window.localStorage.getItem("referrerURL") !== "") ? window.localStorage.getItem("referrerURL") : "n/a home";
      // this.loadLogRocket = false;
      // this.loadLogRocketToggle = !this.loadLogRocketToggle;
      // this.functionName = "doLoadLogRocket";
      // this.paramsObject = params2;
      // this.loadLogRocket = true;

      setTimeout(() =>  {
        // this gives it time to fire the Parse
        window.open(url, "_self");
      } , 500);

    },
    doGiveTipsShowMoreInfoNew(){
    
      console.log("show more info NEW! .. on doGiveTipsShowMoreInfoNew");

      let returnJSON = { showMoreGiveInfoNew: true, fromScreen: "showHomeNew" }; 
      this.$emit('return-json', returnJSON);
      
    },
    doGiveTipsShowMoreInfo(){
    
      // /console.log("show more info!");

      let returnJSON = { showMoreGiveInfo: true, fromScreen: "showHomeNew"  }; 
      this.$emit('return-json', returnJSON);
      
    },
    doGetTipsShowMoreInfoNew(){
    
      console.log("show more info NEW! .. on doGetTipsShowMoreInfoNew");

      let returnJSON = { showMoreGetInfoNew: true, fromScreen: "showHomeNew"  }; 
      this.$emit('return-json', returnJSON);
      
    },
    doGetTipsShowMoreInfo(){
    
      // /console.log("show more info!");

      let returnJSON = { showMoreGetInfo: true, fromScreen: "showHomeNew"  }; 
      this.$emit('return-json', returnJSON);
      
    },
    doGetTipsShowMoreInfoFeesNew(){
    
      console.log("show more info plus fees NEW!");

      let returnJSON = { showMoreGetInfoNew: true, fromScreen: "showHomeNew" , feesShow: true }; 
      this.$emit('return-json', returnJSON);
      
    },
    doGetTipsShowMoreInfoFees(){
    
      // /console.log("show more info!");

      let returnJSON = { showMoreGetInfo: true, fromScreen: "showHomeNew" , feesShow: true }; 
      this.$emit('return-json', returnJSON);
      
    },
    resizeImg() {
      // the reason we have done it this way is because 1) we want to have img width and height attributes for Google pagespeed ranking and 2) the approach with using clamp() for the css didn't work even in proportion to img dims because the clamp could let the img expand to different points within the clamp, it was aspect locked - this makes sure we are always aspect locked
    
        // 477 483
        let pos2croppedImg = this.$refs.pos2croppedImg;
        pos2croppedImg.width = this.VW * 0.8;
        pos2croppedImg.height = this.VW * 0.8 / 477 * 483; // the dims of the image 
        // console.log("pos2croppedImg image height is now " + pos2croppedImg.height);
        // this.$forceUpdate(); 
    },

    doShowHome(){
      console.log("ok let's doShowHome NEW");
      window.scrollTo({ top: 0, behavior: "smooth" }); // in case we are already here but lower down the page
      console.log("should have scrolled to top");
      let returnJSON = { showHomeNew: true, fromScreen: "showHomeNew"  };
      this.$emit('return-json', returnJSON); // this is activating the router so no need to go back
      
    },
    doShowAccount(){
      console.log("ok let's doShowAccount");
      let returnJSON = { showAccount: true, fromScreen: "showHomeNew"  };
      this.$emit('return-json', returnJSON); // this is activating the router so no need to go back
      
    },
    doShowSalons(){
      ////console.log("YAY!");
      let returnJSON = { showSalons: true, fromScreen: "showHomeNew"  };
      this.$emit('return-json', returnJSON); // this is activating the router so no need to go back
      
    },
    doShowGive(){
      
      let returnJSON = { showGive: true, fromScreen: "showHomeNew"  };
      this.$emit('return-json', returnJSON); // this is activating the router so no need to go back
      
    },
    doShowGet(){
      
      let returnJSON = { showGet: true, fromScreen: "showHomeNew"  };
      this.$emit('return-json', returnJSON); // this is activating the router so no need to go back
      
    },
    doShowTipWithoutScanning(){
      // console.log("initiating showTipWithoutScanning on Home");
      let returnJSON = { showTipWithoutScanning: true, fromScreen: "showHomeNew" };
      this.$emit('return-json', returnJSON); // this is activating the router so no need to go back
    },
    doShowGetTipsStartNew(){
  
      let returnJSON = { getTipsStartNew: true, fromScreen: "showHomeNew" };
      this.$emit('return-json', returnJSON); // this is activating the router so no need to go back

    },
    doShowGetTipsStart(){
  
      let returnJSON = { showGetTipsStart: true, fromScreen: "showHomeNew" };
      this.$emit('return-json', returnJSON); // this is activating the router so no need to go back

    },
    doShowContactNew(){
      
      let returnJSON = { showContactNew: true, fromScreen: "showHomeNew"  };
      this.$emit('return-json', returnJSON); // this is activating the router so no need to go back
      
    },
    doShowAboutNew(){
      
      let returnJSON = { showAboutNew: true, fromScreen: "showHomeNew"  };
      this.$emit('return-json', returnJSON); // this is activating the router so no need to go back
      
    },
    doShowTermsNew(){
      
      let returnJSON = { showTermsNew: true, fromScreen: "showHomeNew"  };
      this.$emit('return-json', returnJSON); // this is activating the router so no need to go back
      
    },
    doShowPrivacyNew(){
      
      let returnJSON = { showPrivacyNew: true, fromScreen: "showHomeNew"  };
      this.$emit('return-json', returnJSON); // this is activating the router so no need to go back
      
    },
    doShowContact(){
      
      let returnJSON = { showContact: true, fromScreen: "showHomeNew"  };
      this.$emit('return-json', returnJSON); // this is activating the router so no need to go back
      
    },
    doShowAbout(){
      
      let returnJSON = { showAbout: true, fromScreen: "showHomeNew"  };
      this.$emit('return-json', returnJSON); // this is activating the router so no need to go back
      
    },
    doShowTerms(){
      
      let returnJSON = { showTerms: true, fromScreen: "showHomeNew"  };
      this.$emit('return-json', returnJSON); // this is activating the router so no need to go back
      
    },
    doShowPrivacy(){
      
      let returnJSON = { showPrivacy: true, fromScreen: "showHomeNew"  };
      this.$emit('return-json', returnJSON); // this is activating the router so no need to go back
      
    },
    doShowTestimonials(){
      
      let returnJSON = { showTestimonials: true, fromScreen: "showHomeNew"  };
      this.$emit('return-json', returnJSON); // this is activating the router so no need to go back
      
    },
    setDis(){
      
      if (window.localStorage.getItem("tudis") !== null){ // for some reason it's null not undefined
        // /////console.log("setDis tudis::: " + window.localStorage.getItem("tudis"));
        this.loggedInUserDisplayname = window.localStorage.getItem("tudis");
      }
    },

    doSetMetas(){

      document.title = "ThankU Cashless tipping - giving tips with ThankU couldn't be simpler - QR-based cashless tipping app, tips go directly to the person you are tipping, used at top salons such as Larry King and Josh Wood Colour - no download required";
      document.getElementsByTagName('meta').namedItem('description').setAttribute('content',
      "Giving tips with ThankU Cashless Tipping couldn't be simpler - QR-based cashless tipping app, tips go directly to the person you are tipping, used at top salons such as Larry King and Josh Wood Colour. Just scan the ThankU Cashless Tipping QR badge or tap 'Tip without scanning' below and tip in 10 seconds or less. No app download and No account creation required, and tips go directly to the person you are tipping. Secure transaction with Stripe Inc. 1. Scan a ThankU QR code 2. Select your tip 3. Pay with Apple Pay, Google Pay, or your card. ") ;

      
    
    },
    setDelayedTitle(){
      document.title = "thankU"; // this is so if you save it to your home screen the proposed title is just 'ThankU'
    },
    showImagesNow(){
      this.showImages = true;
    },
    doScrollIsTrue(){
      this.hasScrolled = true;
    },
    setScreenHeightMultiplier(){

      var vhMult;
      let elem = document.getElementById("measure-vh");
      var elemHeight = elem.clientHeight ;
      // window.alert("viewport: " + document.documentElement.clientHeight + " real height: " + window.innerHeight + " or is it " + elemHeight + "?");

      if (document.documentElement.clientHeight >= elemHeight) {
          vhMult = 1;
      } else {
          vhMult = document.documentElement.clientHeight / elemHeight; // will be <1 to multiply with
      }

      // console.log("this.vhMult : " + this.vhMult );
      // window.alert("this.vhMult * elemHeight: " + this.vhMult * elemHeight);

      return vhMult;
    },
    doFontSizeComputed () {
        this.fontSizeNormalInPXToUse = this.convertVHSizeToMaxPixelsSize(this.fontSizeNormal, 16);
        this.fontSizeNormalInPXLineHeightToUse = this.convertVHSizeToMaxPixelsSize(this.fontSizeNormalLineHeight, 20);

    },
    convertVHSizeToMaxPixelsSize(vhSize, maxSizeInPixels){ // local version because can't use shared on home page ref Parse
      const pixelConversionOfVHSize = Math.round(window.innerHeight / (100 / vhSize));
      // we are assuming 100% font size is 16px
      const sizeInPX = maxSizeInPixels;
      var sizeInPXToUse = "";
      if (pixelConversionOfVHSize > sizeInPX) {
        sizeInPXToUse = sizeInPX; // shouldn't be larger than this   
      } else {
        sizeInPXToUse = pixelConversionOfVHSize;
      }
      // /console.log("calc'd size: " + sizeInPXToUse + 'px');
      
      return sizeInPXToUse;
    },
    doTopSalonsPrep(){
      this.slides = [
        { id: '1', slideimage: require('@/assets/greyjwc.webp'), slidewidth: '5vw' },
        { id: '2', slideimage: require('@/assets/greylarrykingbettervert.webp'), slidewidth: '5vw' },
        { id: '3', slideimage: require('@/assets/greyedwardjames.webp'), slidewidth: '8vw' },
        { id: '4', slideimage: require('@/assets/greyduckdry.webp'), slidewidth: '10vw' },
        { id: '5', slideimage: require('@/assets/greylimozlogli.webp'), slidewidth: '7vw' },
        { id: '6', slideimage: require('@/assets/greynailuxurygoodsm.webp'), slidewidth: '9vw' },
        { id: '7', slideimage: require('@/assets/greyclayhairsm.webp'), slidewidth: '9vw' },
        { id: '8', slideimage: require('@/assets/greypfeffersal.webp'), slidewidth: '9vw' },
        { id: '9', slideimage: require('@/assets/greytomoyukism.webp'), slidewidth: '9vw' },
        { id: '10', slideimage: require('@/assets/greyacquadiparma.webp'), slidewidth: '9vw' },
        { id: '11', slideimage: require('@/assets/greycoolcreativetranssm.webp'), slidewidth: '9vw' },
      ];
    },
    doTestimonialsPrep(){
      this.testimonialslides = [
        { id: '1', slideimage: require('@/assets/larrykingroundtrans.webp'), slidetestimonial: "Our experience has been</br>excellent and we get great </br>feedback from clients </br>and staff.", slideattributed: "Bobby Collier, Manager </br>Larry King, South Kensington, London", makeLine: false},
        { id: '2', slideimage: require('@/assets/duckroundtrans.png'), slidetestimonial: "Clients and staff are very happy, the </br>whole tipping ‘issue’ has just gone</br> away with ThankU – and it </br>requires zero management!", slideattributed: "Rosie Rowley, Salon Group Manager </br>Duck & Dry, London", makeLine: false},
        { id: '3', slideimage: require('@/assets/rubybroundtrans.png'), slidetestimonial: "Highly recommend, really </br>good at getting back</br> to you when setting it </br>up as well, all very fast!", slideattributed: "Ruby Byrne, Owner</br>Ruby B Organic, London", makeLine: false},
        { id: '4', slideimage: require('@/assets/aquavieroundtrans.png'), slidetestimonial: "Amazing app. Both our clients and my </br>team love it. It's simple to use and</br> they are also super quick to help </br>if you have any queries!", slideattributed: "Elizabeth Dadson, Owner</br>Aquavie Boutique Spa, Kent", makeLine: false},
        { id: '5', slideimage: require('@/assets/collectionsroundtrans.png'), slidetestimonial: "Fantastic way for our team to not miss out on tips in a world where less people are carrying cash. Our clients absolutely love how easy it is too!", slideattributed: "Marcello Ferri, Manager</br>Collections Hair Club, Weybridge", makeLine: false},
        { id: '6', slideimage: require('@/assets/headofficeroundtrans.png'), slidetestimonial: "I love the ThankU app! I 100% recommend it - so easy to use & the customer service is amazing. Every salon should get the ThankU app on their reception desks!", slideattributed: "Victoria Williams, Owner<br/>Head Office, Wrexham", makeLine: false},
        { id: '7', slideimage: require('@/assets/toniguyroundtrans.png'), slidetestimonial: "Our staff and clients love <br/>ThankU Cashless Tipping, it’s <br/>going really well <br/>thank you ThankU team!", slideattributed: "Aneeqa, Salon Manager<br/> TONI&GUY Westfield, Stratford City", makeLine: false},
        { id: '8', slideimage: require('@/assets/salon41roundtrans.png'), slidetestimonial: "All of the staff and clients <br/>are very much enjoying having <br/>the ‘thankU’ service 😊", slideattributed: "Tash Bishop, Salon Manager<br/>Salon41, Bristol", makeLine: false},
        { id: '9', slideimage: require('@/assets/marcelasroundtrans.png'), slidetestimonial: "Our customers are so happy <br/>with the ThankU app - they<br/> have found it very <br/>clever and efficient.", slideattributed: "Marcela Robinson, Owner<br/>Marcelas, Kensington, London", makeLine: false},
        { id: '10', slideimage: require('@/assets/joshwoodroundtrans.png'), slidetestimonial: "Instant digital tipping!<br/>safe, secure, <br/>fool proof!", slideattributed: "Matilda Lansdown, Operations Director<br/>Josh Wood, Holland Park, London", makeLine: false},
               
      ]
    },

  },
  beforeCreate(){
    const image = new Image();    
    image.src = require('@/assets/bluecirclesm.webp'); 
    // designed to have the same effect as prefetch

    function doResponsiveHomeSetup(){
      // document.head.innerHTML += '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css" integrity="sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH" crossorigin="anonymous">';

      console.log("doing JS set up");
      document.head.innerHTML += '<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.bundle.min.js" integrity="sha384-YvpcrYf0tY3lHB60NNkmXc5s9fDVZLESaAA55NDzOxhy9GkcIdslK1eN7N6jIeHz" crossorigin="anonymous"><\/script>';

      
    }
    
    document.body.style.maxWidth = "100vw"; // for Google mobile crawler had to limit homepage width on body tag, this resets it


    doResponsiveHomeSetup(); // we can experiment later as to whether this needs to be in beforeCreate
    
  },

  created(){
    console.log("Home has been created!" + this.redirect);

    this.showHomePageFromGoogleAd = this.$route.params !== undefined ? this.$route.params.showHomePageFromGoogleAd === 'true': false; // so showHomePageFromGoogleAd is not passed as a prop, it is passed through this.$route.params AND NOTE: though this.showHomePageFromGoogleAd aws passed as a boolean from Homecontent passing it through the router converts the value to a string
    
    console.log("showHomePageFromGoogleAd ON HOME::::" + this.showHomePageFromGoogleAd);
    this.doTopSalonsPrep();
    this.doTestimonialsPrep();

    

  },
  mounted(){

    ////console.log("Home mounted!");
    // if (window.localStorage.getItem("downloadBrochureLinkColor") !== null) {
    //   this.$refs["brochureLink"].style.color = window.localStorage.getItem("downloadBrochureLinkColor");
    // }

    let elem = this.$refs.masterDiv;
    var rect = elem.getBoundingClientRect();
    this.VW = rect.width;
    // console.log("elemWdith@:: " + this.VW);
    this.resizeImg();


    this.setDis();
    this.doSetMetas();
    this.doFontSizeComputed();
    
    this.vhMult = this.setScreenHeightMultiplier(); // local version because can't access shared on Home without pulling in Parse
    this.vhMultFont = 16 * this.vhMult; // font size normal

    window.setTimeout(this.setDis, 10); // hacky way of picking it up if it was set through an existing user link on app

    window.setTimeout(this.setDelayedTitle, 5000); 

    window.setTimeout(this.showImagesNow, 10);
    
    window.addEventListener("scroll", this.doScrollIsTrue);

    let doGetSetUp = this.$route.params !== undefined ? this.$route.params.doGetSetup === 'true': false; // passing as a boolean from Homecontent passing it through the router converts the value to a string
    let doGetSetUpOrigin = (this.$route.params !== undefined && this.$route.params.doGetSetupOrigin !== undefined) ? this.$route.params.doGetSetupOrigin : "no origin"; 

    if (doGetSetUp === true){
      this.doOrderBadges(doGetSetUpOrigin);
    }


  }, 
  beforeUnmount(){
    window.scrollTo({ top: 0 });
    // console.log("UNMOUNTING");
  }

}
</script>

<style>

html, body{
  overflow-x: hidden !important;
  max-width: 100vw !important;
  touch-action: pan-y; /** Disable horizontal scrolling */
  /* all ofhte above is to stop horizontal scrolling on safari mobile see https://stackoverflow.com/questions/15879710/disabling-horizontal-scroll-on-an-iphone-website */
}

</style>

