<template>
    <div class="topcontent-div" style="width: 100%; height: 100%; outline:0.00em solid black; ">  <!-- not sure why but the height: 100% allows modal to center in the page otherwise it goes to top -->
      <div class="wrapper-todiv" style="padding-top: 5vh; padding-bottom: 5vh;"> 

        <table width=100% border=0 style="padding-left: 2.5vh; padding-right: 2.5vh">
          <tr>
            <td>
              <table width="100%" border=0 s>
                <tr>
                  <td width="30%">
                        <span class="primary-general-text divl" style="display: block; line-height: 15px;"></span> 
                  </td>
                  <td width="40%">
                    <img class="tulogo" style="height: 6vh; padding-bottom: 1vh" alt="ThankU logo" src="../assets/tutxtlogo.png">
                  </td>
                  <td width="30%">
                    <div class=divr>
                 
                      <button @click="this.goBack();" class="text-only-button cancel"> <img class="tulogo" style="height: 4vh;" alt="close" src="../assets/greycross.png"></button>
                    </div>
                  </td>

                </tr>
              </table>
              
            </td>
          </tr>
         
          <tr>
            <td style="padding-top: 1vh; padding-bottom: 1vh; line-height: 0.1px">   
              <span class="lt-blue" :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }" v-if="this.nhbfmembers === true">Welcome NHBF members!<p></p></span>           
              <span class="lt-blue" :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }" ><span style="font-size: 13.5pt; font-family: &quot;Arial&quot;,sans-serif; color: rgb(94, 144, 227);"><b>Get your FREE ThankU badges</b></span></span> 
           </td>
          </tr>
         
         <tr style="cursor:pointer">
            <td @click="doDownloadBrochure();" style="cursor: pointer;">           
              
              <a href='https://www.thanku.app/introtothanku.pdf'><img style="width: 100%; cursor:pointer" alt="cashless tipping" src="../assets/introcover.jpg"></a>
            
           </td>
          </tr>
          <tr>
            <td @click="doDownloadBrochure();" style="cursor: pointer; padding-top: 1vh; padding-bottom: 1vh; line-height: 0.1px">           
          
              <span class="lt-blue" :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }" ><span style="padding-top: 25vh; font-size: 13.5pt; font-family: &quot;Arial&quot;,sans-serif; color: rgb(94, 144, 227);"><b><u><a href="https://www.thanku.app/introtothanku.pdf" target="_self">Download our brochure</a></u></b></span></span> 
           </td>
          </tr>

          <tr><td colspan="3" style="padding-top: clamp(2px, 2vh, 18px)">
          <div class="userinfoframe actionbtn-div notesInfoTextLarger" style="padding-bottom: clamp(1px, 1vh, 8px); padding-top: clamp(1px, 1vh, 8px)"> 
            <table>
              <tr>
                <td style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);">ThankU is currently providing its ThankU QR badge media to you free of charge for a limited time only.<p></p>Simply tap the 'Order ThankU badges' button below after reviewing the following badge media options and tell us how many of each format you would like, and the address to send them to - that's it!
                </td>
              </tr>
              <tr>
                <td style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);">ThankU is available at <b>no cost to businesses</b> - fees are payable on tip transactions, most often paid by the tipper, but when not, fees are deducted from the tip. For FAQ on recieving tips, please see <span class="text-only-button cancel" @click="doGetTipsShowMoreInfo()" style="pointer: cursor"><b>here</b></span>, and specifically on fees, please see <span class="text-only-button cancel" @click="doGetTipsShowMoreInfoFees()" style="pointer: cursor"><b>here</b></span>. 
                </td>
              </tr>
              <tr>
                <td colspan="3" style="padding-top: clamp(1px, 1vh, 8px);">
                <b><span style="font-size: 13.5pt; font-family: &quot;Arial&quot;,sans-serif; color: rgb(94, 144, 227);">Front Desk Stand</span></b>
              </td>
              </tr>
              <tr>
                <td style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);">As a starting point, we supply you with one of our Front Desk Stands which introduces ThankU to your clients and then you have a choice between the two options further below - 7.4 x 10.5cm.
                </td>
              </tr>
              <tr>
                <td colspan="3" style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: 0vh"><img style="width: 85%; cursor:pointer" alt="ThankU Cashless Tipping App" src="../assets/pos2croppedsm.webp">
                </td>
              </tr>
              <br>
              <tr>
                <td colspan="3" style="padding-top: clamp(1px, 1vh, 8px);">
                <b><span style="font-size: 13.5pt; font-family: &quot;Arial&quot;,sans-serif; color: rgb(94, 144, 227);">Option 1: Tabletop Stand</span></b>
              </td>
              </tr>
              <tr>
                <td style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);">..goes on the tabletop in front of the client at their seat - a discreet choice: the client might ask "what's that?" and your stylist replies "We have clients who asked us for cashless tipping and this is it".. 6 x 4.3cm.
                </td>
              </tr>
              <tr>
                <td colspan="3" style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: 0vh"><img style="width: 85%; cursor:pointer" alt="ThankU Cashless Tipping App" src="../assets/countersquaresm.webp">
                </td>
              </tr>
              <br>
              <tr>
                <td colspan="3" style="padding-top: clamp(1px, 1vh, 8px);">
                <b><span style="font-size: 13.5pt; font-family: &quot;Arial&quot;,sans-serif; color: rgb(94, 144, 227);">Option 2: ‘No Glue’ Mirror Sticker</span></b>
              </td>
              </tr>
              <tr>
                <td style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);">simply mount on mirror facing client for a little more impact - no glue, can be easily removed - 12cm x 8cm.
                </td>
              </tr>
              <tr>
                <td colspan="3" style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: 0vh"><img style="width: 85%; cursor:pointer" alt="ThankU Cashless Tipping App" src="../assets/staticcling.png">
                </td>
              </tr>
              <tr>
                <td style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(2px, 3vh, 24px); padding-bottom: clamp(2px, 3vh, 24px);">We have ThankU QR media in other formats, such as wristworn for businesses such as hotels - please enquire with the 'Order ThankU badges' button below.
                </td>
              </tr>
              <tr>
                <td>
                  <div class="actionbtn-div" style="padding-top: 1vh">
                    <span class="lt-blue" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.9 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse * 0.9 + 'px', }"></span><button class="widebtn" style="width: 100%; height: 7vh;"  @click="this.doOrderBadges();" >Order ThankU badges</button>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </td></tr>
        </table>       
      </div>
    </div>
</template>

<script>

const Parse = require('parse/node');
import shared from '../shared.js';
import LogRocket from 'logrocket';

export default {
  inject: ['globalPushForwardInterval', 'devEnv', 'appId', 'appJSKey'] ,
  data () {
    return {
      popUpMsgTitle: "",
      popUpMsgBody: "",
      showPopUpOk: false,
      fontSizeTitle: 2.7, // vh
      fontSizeTitleLineHeight: 5, //vh
      fontSizePopUpMsg: 2.3, // vh
      fontSizePopUpMsgLineHeight: 3.4,// vh

      fontSizeNormal: 3.0, // vh
      fontSizeNormalInPXToUse: "",
      fontSizeNormalLineHeight: 4.0,// vh
      fontSizeNormalInPXLineHeightToUse: "",
      nhbfmembers: false,
    }
  },
  methods: {
    doFontSizeComputed () {
      
      this.fontSizeNormalInPXToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormal, 16);
      this.fontSizeNormalInPXLineHeightToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormalLineHeight, 20);
    
    },
    doOrderBadges(){
      
      // const url = "mailto:mailorders@thanku.app?subject=get ThankU enquiry&body=Please tell us a little about your business so we can send you the right quantity and best suited ThankU QR badge media for you - for example, if you own or manage a hair salon, please tell us how many stylist seats you have.. Please also supply the address we should dispatch your ThankU media to. If you manage a hotel or a car valet business, we supply the ThankU QR badge media mounted on wristbands - let us know and we will send you images and agree staff quantities. All ThankU QR badge media is supplied free of charge."

      let url = "mailto:mailorders@thanku.app?subject=get ThankU enquiry&body=Please fill out below - we will email you back to confirm your order and let you know if we need any further information before dispatching it - please respond below:%0D%0A%0D%0AWhat is your business name?%0D%0A%0D%0AAre you a salon? If not, what type of business please?                  %0D%0A%0D%0AWould you like our our 'No Glue' Mirror Stickers option in addition to your Front Desk Stand? If so, how many stickers would you like? (if a salon, we suggest one for every client seat mirror)    %0D%0A%0D%0AOr - instead - woudl like to receive just our super discreet Tabletop Stand? and if yes, how many would you like?                  %0D%0A%0D%0AIf you are not a salon and would like different QR media (e.g. wearables), let us know and we will email you to ask a bit more about your business..                  %0D%0A%0D%0AWhich address should send we send your ThankU QR media to?                  %0D%0A%0D%0A%0D%0AThank you! best wishes, the ThankU Team";
      
      // console.log("tapped get free ThankU badges button on GET TIPS START");
      const params = {
        toEmail: "appalert@thanku.app",
        subject: "GREAT! Someone tapped get free ThankU badges button on GET TIPS START" ,
        body: "",
      };

      Parse.Cloud.run("sendEmail", params);
      window.open(url, "_self");

      this.goBack();

    },
    doDownloadBrochure(){
      const url = "https://www.thanku.app/introtothanku.pdf"
      // console.log("opening pdf");
      const params = {
        toEmail: "appalert@thanku.app",
        subject: "GREAT! Someone downloaded ThankU pdf brochure" ,
        body: "",
      };

      Parse.Cloud.run("sendEmail", params);
      window.open(url, "_self");

    },
    doShowHolding(){

      shared.saveToUserPath(this.devEnv, "User wanted to start get tips process " + new Date().toISOString(), this.globalPushForwardInterval);
      this.popUpMsgTitle = "Sorry";         
      this.popUpMsgBody = "This bit is not ready yet - for the time being please just email us at mediaorders@thanku.app and let us know what type of business you have and how many people will receive tips at it";
      this.showPopUpOk = true;

    },
    doRecipientSelected(){
      
      //this.$router.back();

      let returnJSON = {
        selectedTUID: '00000000002'
      };

      this.$emit('return-json', returnJSON);
    },
    doGetTipsShowMoreInfo(){
    
      // /console.log("show more info!");

      let returnJSON = { showMoreGetInfo: true }; 
      this.$emit('return-json', returnJSON);
      
    },
    doGetTipsShowMoreInfoFees(){
    
      // /console.log("show more info!");

      let returnJSON = { showMoreGetInfo: true, feesShow: true }; 
      this.$emit('return-json', returnJSON);
      
    },
    goBack(){
      // console.log("we're off! Next screen is " + window.localStorage.getItem("nextScreen"));
      if (window.localStorage.getItem("nextScreen") !== null ){
        let returnJSON = { nextScreen: window.localStorage.getItem("nextScreen")};
        this.$emit('return-json', returnJSON);    
        window.localStorage.removeItem("nextScreen");
      } else {
        let returnJSON = { showHome: true }; 
        this.$emit('return-json', returnJSON);
      }
    },
    doSetMetas(){

      document.title = "Get free ThankU Cashless Tipping QR badges";
      document.getElementsByTagName('meta').namedItem('description').setAttribute('content',
      "Get free ThankU Cashless Tipping QR badges - our stand-mounted ThankU QR badges are just 6x4x2cm. They sit at the front desk and at your client seats (at your option). Simply tap the 'Order ThankU badges' button below, tell us which design/colour you like (1, 2, or 3), how many you need, and the address to send them to - that's it!") ;
    
    },
    async deployLogRocket(){
      
     

      var LRObjectId = new Date().getTime();

      if (window.localStorage.getItem("tuob") !== null) {
        LRObjectId = window.localStorage.getItem("tuob");
      }
      
      if (LRObjectId === "4g278vWUb1" || LRObjectId === "2fBkimFcIw" ){
        return; // don't record myself
      }

      var LRDisplayname = "LR Name not given";

      if (window.localStorage.getItem("tudis") !== null) {
        LRDisplayname = window.localStorage.getItem("tudis");
      }

      try {
          LogRocket.init('thanku-alt-5/thanku-alt-5'); //LogRocket.init('thanku-alt-4/thanku-alt-4'); //LogRocket.init('thanku-alt-3/thanku-alt-3');//LogRocket.init('thanku-alt-2/thanku-alt-2');//LogRocket.init('thanku-alt/tualt'); //LogRocket.init('fpi3vr/thanku');  //LogRocket.init('thanku-alt-10/thanku-alt-10'); //LogRocket.init('thanku-alt-9/thanku-alt-9'); //LogRocket.init('thanku-alt-8/thanku-alt-8');//LogRocket.init("thanku-alt-7/thanku-alt-7"); //LogRocket.init('thanku-alt-6/thanku-alt-6'); //
        LogRocket.identify(LRObjectId, {
          name: LRDisplayname + ": get started",
          email: undefined,
          // Add your own custom user variables here, ie:

        });
      } catch (e) {
        // console.log("error starting LogRocket tipper id: " + this.tipper.objectId);
        
        const params = {
          toEmail: "appalert@thanku.app",
          subject: "ALERT! LogRocket initialisation error: " + e.message,
          body: "ALERT! LogRocket initialisation error: " + e.message,
        };

        Parse.Cloud.run("sendEmail", params);
        // fail silently do nothing
      }
    },
  },
  created(){

    this.deployLogRocket();
    document.body.style.maxWidth = "100vw"; // for Google mobile crawler had to limit homepage width on body tag, this resets it
    this.doSetMetas();
    // console.log("Get Tips Start created");
    // console.log("window query: " + JSON.stringify(this.$route.params, null, 2));

    const fromScreen = this.$route.params !== undefined ? " from " + this.$route.params.fromScreen : this.fromScreen !== undefined ? this.fromScreen : "";

    let referrerURL = window.localStorage.getItem("referrerURL") !== null ? window.localStorage.getItem("referrerURL") : "";

    // console.log("MY REFERRERURL on GetTipsStart IS: " + referrerURL);
    
    shared.sendAppAlert("Great! Someone has tapped the get tips button" + fromScreen + "!", referrerURL, this.appId, this.appJSKey);

    var extraMsg = "";

    if (window.localStorage.getItem("isnhbfmember") !== null){

      // /console.log("this IS an NHBF member");
      window.localStorage.removeItem("isnhbfmember");
      this.nhbfmembers = true;
      extraMsg = "FOR NHBF MEMBER ";
    }
    shared.saveToUserPath(this.devEnv, "Get tips start process " + extraMsg + new Date().toISOString(), this.globalPushForwardInterval);
    this.doFontSizeComputed();
    window.addEventListener('resize', this.doFontSizeComputed);
  },
  mounted(){
    // console.log("MOUNTME!");
    window.scrollTo(0, 0);
  },
}
</script>

