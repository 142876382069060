<template>

</template>

<script>

import sharedhome from '../sharedhome.js';

export default {
    inject: ['globalPushForwardInterval', 'devEnv', 'appId', 'appJSKey'],
    props: {
        functionName: String,
        paramsObject: Object,
    },
    data() {
        return {
        
        }
    },
    created(){
        // console.log("Behold! THIS LAND IS MINE!!");
        // console.log("functionName on LoadParseFromHome is: " + this.functionName);
    },
    async mounted(){

        // about this component - in order to maximise Google PageSpeed needed to reduce the size of initially loading vendorchunks.js, meaning I needed not to load Parse if it wasn't required. So now when it is required, loadParse is toggled on App.vue which means the Parse module is loaded. It is triggered on mounting and emits to return a JSON object if required. Note the use of remoting out to sharedhome.doParseServer. This was as a result of a failed prior attempt to loadParse conditionally, and I couldnt be bothered to incorporate it into this component, so it is an unnecessary extra leg.

        // const selectUserData = await sharedhome.doParseServer('validateUserLinkDetails', params, this.appId, this.appJSKey);

        console.log("I shall now ACT!!");

        let returnParams = await sharedhome.doParseServer(this.functionName, this.paramsObject, this.appId, this.appJSKey);

        // console.log("did Load Parse with this result:: " + JSON.stringify(returnParams, null, 2));

        if (this.functionName === "validateUserLinkDetails"){
            if (returnParams === "no match") {
                returnParams = {
                    return: "no match",
                    functionName: this.functionName,
                }
            } else {
                returnParams.functionName = this.functionName;
            }
            let returnParentJSON = returnParams;
            this.$emit('return-parent-json', returnParentJSON);
        } else if (this.functionName === "fetchUserDetails"){
            returnParams.functionName = this.functionName;
            let returnParentJSON = returnParams;
            console.log("fetchUserDetails this is what we returned: " + JSON.stringify(returnParentJSON, null, 2));
            this.$emit('return-parent-json', returnParentJSON);
        } else {
            if (returnParams === true) {
                returnParams = {
                    return: returnParams,
                    functionName: this.functionName,
                }
            } else {
                returnParams.functionName = this.functionName;
            }

            let returnParentJSON = returnParams;
            this.$emit('return-parent-json', returnParentJSON);
        }


        clearTimeout(window.localStorage.getItem("networkMsgBoxTimeoutId"));
        document.getElementById('networkMsgBox').style.display = 'none';
        console.log("networkMsgBox display cancelled");
    },
}
</script>