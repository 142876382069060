<template>
  <!-- <router-view v-if="this.showRouter===true" @return-json="doReturnJSON" v-slot="{ Component }">
    <transition name="screenslideup">
      <component :is="Component" />
    </transition>
  </router-view> -->
  <!-- See: `getFullVh()` -->
  <div style="overflow: hidden; height: 0">
      <div id="measure-vh" style="position: fixed; height: 100vh"></div>
  </div>
  <!-- showGiveTips -->
    <div style="display: flex; width: 100%; position: absolute; position: fixed; left: 0; top: 0; margin: auto; justify-content:top; align-items:top">  <!-- not sure why but the height: 100% allows modal to center in the page otherwise it goes to top -->
      <div class="modal" style="outline:0.0em solid blue; padding-top: 3vh; padding-bottom: 3vh; " > 

        <!-- :style="{ 'height': 98 * this.vhMult + 'vh'}" -->

        <table width=100% border=0 style="padding-left: 2.5vh; padding-right: 2.5vh">
          <tr>
            <td>
              <table width="100%" border=0 s>
                <tr>
                  <td width="30%">
                        <span class="primary-general-text divl" style="display: block; line-height: 15px;">Giving Tips</span> 
                  </td>
                  <td width="40%">
                    <img class="tulogo" style="height: 6vh; padding-bottom: 1vh" alt="ThankU logo" src="../assets/tutxtlogo.png">
                  </td>
                  <td width="30%">
                    <div class=divr>
                 
                      <button @click="doShowHome" class="text-only-button cancel"> <img class="tulogo" style="height: 4vh;" alt="close" src="../assets/greycross.png"></button>
                    </div>
                  </td>

                </tr>
              </table>
              
            </td>
          </tr>
         
           <tr>
            <td style="padding-top: 1vh; padding-bottom: 1vh">              
              <span class="lt-blue" style="font-size: 90%; ">Giving tips with ThankU couldn't be simpler  &#38; tips go <u>directly</u> to the person you are tipping</span> 
           </td>
          </tr>
          <tr>
            <td style="padding-top: 0vh">
              <table width="100%">
                <tr>
                  <td width="80%" class="divl" style="padding-top: 1vh; padding-left: 2vh"><span class="lt-blue" style="font-size:90%">1. Scan a ThankU QR code *</span>
                  </td>
                  <td width="20%" rowspan="3">
                    <img style="height: 8.5vh" alt="ThankU QR badge" src="../assets/bluecirclesm.png">
                  </td>
                </tr>
                <tr>
                  <td class="divl" style="padding-top: 1vh; padding-left: 2vh">              
                    <span class="lt-blue" style="font-size:90%">2. Select your tip</span>  
                  </td>
                </tr>
                <tr>
                  <td class="divl" style="padding-top: 1vh; padding-left: 2vh">              
                    <span class="lt-blue" style="font-size:90%">3. Pay with Apple Pay, Google Pay, or your card</span>  
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td class="divc" style="padding-top: 1vh;">
              <span class="lt-blue" style="font-size:85%; letter-spacing: 0.1px; display: block; line-height: 2.5vh;"><b>no sign up or account creation required, secure transaction with Stripe Inc.</b></span>
            </td>
          </tr>
          <tr>
            <td class="divl" style="padding-top: 1vh">
              <table border=0>
                <tr>
                  <td valign="top" align="center" style="width: 2vh; padding-top: 1vh">
                    *
                  </td>
                  <td>
                    <span class="primary-general-text" style="display: block; font-size:12px; line-height: 15px;">you'll find ThankU QR codes at places you are used to tipping at, e.g. your hair salon. Already left? you can still…</span>
                  </td>
                </tr>
              </table>
              
            </td>
          </tr>
          <tr>
            <td>
              <div class="actionbtn-div" style="padding-top: 1vh">
                <button class="widebtn" style="width: 100%; height: 7vh;"  @click="this.doShowTipWithoutScanning" >Tip without scanning...</button>
              </div>
            </td>
          </tr>
          <tr>
            <td style="padding-top: 2vh">
              <div class=divc><button class="text-only-button cancel" @click="doShowMoreInfo" >more info</button></div>
            </td>
          </tr>
        </table>       
      </div>
    </div>
</template>

<script>

import shared from '../shared.js';


export default {
  inject: ['globalPushForwardInterval', 'devEnv', 'appId', 'appJSKey'] ,
  data() {
    return {
      vhMult: 1,
    }
  },
  methods: {
    doShowTipWithoutScanning(){
      
      let returnJSON = { showTipWithoutScanning: true };
      this.$emit('return-json', returnJSON); // this is activating the router so no need to go back

    },
    doShowHome(){
      // /console.log("we're off!");

      let returnJSON = { showHome: true }; 
      this.$emit('return-json', returnJSON);

    },
    doShowMoreInfo(){
    
      // /console.log("show more info!");

      let returnJSON = { showMoreGiveInfo: true }; 
      this.$emit('return-json', returnJSON);
      
    },    
    doSetMetas(){

      document.title = "Giving tips with ThankU couldn't be simpler - tips go directly to the person you are tipping";
      document.getElementsByTagName('meta').namedItem('description').setAttribute('content',
      "Giving tips with ThankU couldn't be simpler - tips go directly to the person you are tipping. no sign up or account creation required, secure transaction with Stripe Inc. 1. Scan a ThankU QR code 2. Select your tip 3. Pay with Apple Pay, Google Pay, or your card") ;
    
    }
  },
  created(){
    document.body.style.maxWidth = "100vw"; // for Google mobile crawler had to limit homepage width on body tag, this resets it
    this.doSetMetas();
    shared.saveToUserPath(this.devEnv, "Give tips " + new Date().toISOString(), this.globalPushForwardInterval);
  },
  mounted(){
    this.vhMult = shared.setScreenHeightMultiplier();
  }
}
</script>

